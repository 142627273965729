import { StoreType } from "screens/designStudio/designStudioV2/types";

export const useStoreHistory = (store: StoreType | null) => {
  return {
    canUndo: store?.history.canUndo,
    canRedo: store?.history.canRedo,
    undo: () => {
      if (!store?.history.canUndo) return;
      store?.history.undo();
    },
    redo: () => {
      if (!store?.history.canRedo) return;
      store?.history.redo();
    },
  };
};
