import { StoreType } from "screens/designStudio/designStudioV2/types";
import { useTextTemplates } from "./text/useTextTemplates";
import {
  CircularProgress,
  ImageList,
  ImageListItem,
  Stack,
  Link,
} from "@mui/material";
import { useElements } from "./useElements";

type TextProps = {
  store: StoreType;
};

export const Text = ({ store }: TextProps) => {
  const { data, isFetching, insertTemplate, refetch, isError } =
    useTextTemplates(store);

  const { addText } = useElements(store);
  if (isFetching)
    return (
      <Stack>
        <CircularProgress />
      </Stack>
    );

  if (!data?.items || isError) {
    return (
      <Stack>
        <div>
          Error fetching text templates,{" "}
          <Link onClick={() => refetch()} component="button">
            click to try again
          </Link>
        </div>
      </Stack>
    );
  }

  return (
    <Stack spacing={1}>
      <Stack alignItems="center" spacing={1}>
        <Link
          component="button"
          variant="body1"
          fontSize={34}
          underline="none"
          onClick={() => addText({ text: "Header text", fontSize: 34 })}
        >
          Create header
        </Link>
        <Link
          component="button"
          variant="body1"
          fontSize={20}
          underline="none"
          onClick={() => addText({ text: "Subheader text", fontSize: 20 })}
        >
          Create subheader
        </Link>
        <Link
          component="button"
          variant="body1"
          underline="none"
          onClick={() => addText({ text: "Body text", fontSize: 16 })}
        >
          Create body text
        </Link>
      </Stack>
      <ImageList variant="masonry" cols={2} gap={8}>
        {data?.items?.map(item => (
          <ImageListItem
            key={item.preview}
            onClick={() => insertTemplate(item.json)}
            sx={{ border: 1, borderColor: "divider" }}
          >
            <img srcSet={item.preview} src={item.preview} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>
    </Stack>
  );
};
