import {
  Checkbox,
  Image,
  Typography,
  Badge,
  BadgeProps,
  Space,
  Divider,
  Button,
} from "antd";
import styles from "./CardV2.module.scss";
import classNames from "classnames";
import EllipsisText from "./card/EllipsisText";
import { TooltipTag } from "screens/designStudio/designStudioLayout/shared/TooltipTag";
import ErrorImage from "statics/images/missing-thumbnail.png";
import { Fragment } from "react";

export type Action<T = any> = {
  type:
    | "edit"
    | "preview"
    | "properties"
    | "duplicate"
    | "publish"
    | "archive"
    | "delete";
  icon: React.ReactNode;
  handler?: (item?: T) => () => void;
};

export type Props = {
  statusInfo?: {
    status: BadgeProps["status"];
    title: string;
  };
  tags: string[];
  actions?: Action[];
  isSelected?: boolean;
  onSelect?: (selected: boolean) => void;
  thumbnail?: string;
  title: string;
  infos?: [string?, string?, string?];
};
const Card = <T = any,>(props: Props & { item: T }) => {
  const {
    statusInfo = {
      status: "default",
      title: "Draft",
    },
    tags,
    actions,
    isSelected = false,
    onSelect,
    thumbnail,
    title,
    infos,
  } = props;
  const hasTags = (tags?.length ?? 0) > 0;
  const [first, second, ...rest] = tags ?? [];

  return (
    <div
      className={styles.container}
      onClick={() => {
        if (window.getSelection()?.toString()) return;
        onSelect?.(!isSelected);
      }}
    >
      <Checkbox
        className={classNames(styles.checkbox)}
        checked={isSelected}
        onChange={e => {
          e.stopPropagation();
          onSelect?.(e.target.checked);
        }}
      />
      <div className={styles.status}>
        <Badge
          status={statusInfo.status}
          className={styles.badge}
          text={statusInfo.title}
        />
      </div>
      <div className={styles.imageContainer}>
        <Image
          width={"100%"}
          src={thumbnail ?? ErrorImage}
          fallback={ErrorImage}
          preview={false}
        />
      </div>
      <div className={styles.infoContainer}>
        <Typography.Text className={styles.name}>
          <EllipsisText text={title} charDisplayLimit={40} />
        </Typography.Text>
        <Space className={styles.details} align="baseline">
          {infos?.map((info, idx) => (
            <Fragment key={info}>
              {info && (
                <EllipsisText
                  className={styles.details}
                  text={info}
                  charDisplayLimit={8}
                />
              )}
              {idx < infos.length - 1 && (
                <Divider type="vertical" className={styles.divider} />
              )}
            </Fragment>
          ))}
        </Space>
        {hasTags && (
          <div>
            <TooltipTag title={first}>{first}</TooltipTag>
            {second && <TooltipTag title={second}>{second}</TooltipTag>}
            {rest.length > 0 && (
              <TooltipTag title={rest.join(", ")}>+{rest.length}</TooltipTag>
            )}
          </div>
        )}
      </div>

      {actions && (
        <div className={styles.actions}>
          {actions.map(action => (
            <Button
              key={action.type}
              icon={action.icon}
              size="middle"
              onClick={action.handler?.(props.item)}
              className={styles.actionButton}
            />
          ))}
        </div>
      )}

      <div className={classNames(styles.hoverBorder)} />
    </div>
  );
};
export default Card;
