import { UploadFile } from "antd/lib/upload/interface";

export type TemplateStatus = "DRAFT" | "PUBLISHED";
export type FileType = "indesign" | "html";
export type TemplateType = FileType;
export type Tag = {
  id: string;
  name: string;
};
export type EnrollmentCycle = "prospecting";
export type Language = "en" | "es";
export type DeliveryMethod = "download" | "email" | "print";

export type Template = {
  id: string;
  name: string;
  enrollmentCycle: EnrollmentCycle[];
  audience: Audience[];
  deliveryMethod: DeliveryMethod[];
  status: TemplateStatus;
  type: TemplateType;
  tags?: Tag[];
  createdAt?: number;
  createdBy: string;
  thumbnail?: string;
  language: Language;
  lastUpdatedBy: string;
  lastUpdatedAt: number;
  file?: TemplateFile;
};

export type TemplateFile = Pick<
  UploadFile,
  "uid" | "name" | "url" | "status" | "thumbUrl" | "originFileObj"
> & {
  pages?: Page[];
  type: FileType;
};

export type FileDrawerMode = "new" | "edit";

export type Field<T = any> = {
  label: string;
  key: Extract<
    FieldKey,
    "name" | "audience" | "language" | "tags" | "deliveryMethod"
  >;
  required: boolean;
  options?: Array<{
    key: T;
    value: T;
    label: string;
  }>;
};

export type FieldKey =
  | "name"
  | "deliveryMethod"
  | "client"
  | "audience"
  | "type"
  | "status"
  | "tags"
  | "thumbnail"
  | "language";

export type FieldOption = {
  label: string;
  value: FieldOptionValue;
  key: string;
};
export type FieldOptionValue = any;

export type Audience =
  | "benefitDecisionMaker"
  | "broker"
  | "businessDecisionMaker"
  | "consumer"
  | "employee"
  | "producer"
  | "publicSector";

export type TemplateFilterKey = Extract<
  Template,
  "name" | "status" | "type" | "createdAt" | "lastUpdatedAt"
>;

export const TemplateKeyToLabel: Record<keyof Template, string> = {
  id: "ID",
  name: "Name",
  audience: "Audience",
  type: "Type",
  status: "Status",
  tags: "Tags",
  createdAt: "Created",
  createdBy: "Created by",
  lastUpdatedBy: "Last edited by",
  lastUpdatedAt: "Last edited",
  thumbnail: "Thumbnail",
  language: "Language",
  file: "File",
  enrollmentCycle: "Enrollment Cycle",
  deliveryMethod: "Delivery Method",
};

export type Page = {
  id: string;
  thumbnail: string;
  layers?: Layer[];
};

interface LayerBase {
  id: string;
  name: string;
  page: number;
  isLocked: boolean;
}

type Option = {
  id: string;
  value: string;
};

export interface ConditionalGroupLayer extends LayerBase {
  type: "group";
  subLayers: TextLayer[];
  orgLayer: TextLayer;
  smartVariable: "conditional";
  conditionalSet: ConditionalSet;
}
export interface OptionGroupLayer extends LayerBase {
  type: "group";
  subLayers: TextLayer[];
  orgLayer: TextLayer;
  smartVariable: "options";
  options: Option[];
}

export interface OtherGroupLayer extends LayerBase {
  type: "group";
  subLayers: TextLayer[];
  orgLayer: TextLayer;
  smartVariable?: SmartVariable;
}

export type GroupLayer =
  | ConditionalGroupLayer
  | OptionGroupLayer
  | OtherGroupLayer;

export interface ImageLayer extends LayerBase {
  type: "image";
  imageSrc?: string;
}

export interface TextLayer extends LayerBase {
  type: "text";
}

export type LayerValue = SmartVariable | string;
export type LayerType = "image" | "text" | "group";
export type Layer = ImageLayer | TextLayer | GroupLayer;

export type SmartVariable =
  | "disclosure"
  | "product"
  | "client"
  | "cta"
  | "conditional"
  | "options"
  | "agent";
export const SmartVariableOptions: Record<SmartVariable, string> = {
  disclosure: "Disclosure",
  product: "Product",
  client: "Client",
  cta: "CTA",
  conditional: "Conditional",
  options: "Options",
  agent: "Agent",
};
type ConditionOption = "state" | "product";
export type Op = "is" | "is not" | "contains" | "does not contain";
export type Condition = {
  id: string;
  if?: ConditionOption;
  op?: Op;
  value?: string;
};
export type ConditionalField = {
  id: string;
  conditions: Condition[];

  then?: string;
};

export type ConditionalSet = {
  fields: ConditionalField[];
  else?: string;
};
