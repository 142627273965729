import { createTheme, ThemeOptions } from "@mui/material";

const baseTheme: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
        },
        body: {
          height: "100%",
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  zIndex: {
    drawer: 1000,
  },
};

const light: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#32475d",
    },
    layout: {
      greyBg: "rgba(0, 0, 0, 0.04)",
    },
    background: {
      default: "#fff",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          color: "inherit",
        },
      },
    },
  },
};

const dark: ThemeOptions = {
  palette: {
    mode: "dark",
    layout: {
      greyBg: "rgba(255, 255, 255, 0.04)",
    },
    background: {
      default: "#0e1217",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#0e1217",
          color: "inherit",
        },
      },
    },
  },
};

export const lightTheme = createTheme(light, baseTheme);

export const darkTheme = createTheme(dark, baseTheme);

declare module "@mui/material/styles" {
  interface Palette {
    layout: {
      greyBg: string;
    };
  }
  interface PaletteOptions {
    layout?: {
      greyBg?: string;
    };
  }
}
