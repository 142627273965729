import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import Table from "./templates/Table";
import Cards from "./templates/Cards";
import { useSalesEnablementContext } from "./hooks/SalesEnablementDataProvider";
import { Template, TemplateKeyToLabel } from "shared/types/salesEnablement";
import { useDataListURLSorter } from "shared/components/dataListURL/useDataListURLSorter";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import FileDrawer from "./templates/FileDrawer";
import { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Action } from "shared/components/CardV2";
import styles from "./Templates.module.scss";

const Templates = () => {
  const {
    onLayoutChange,
    layout,
    sortableKeys,
    templates,
    loading,
    setSelectedTemplate,
    setSelectedFile,
    setFileDrawerMode,
    uploadForm,
  } = useSalesEnablementContext();
  const { data, originalData, isLoading, selectedItems } = useDataListURLData<
    keyof Template,
    Template
  >({
    isError: false,
    isLoading: loading,
    data: templates,
  });

  const { sortKey, sortOrder, sortItems } = useDataListURLSorter<
    keyof Template,
    Template
  >();
  const [open, setOpen] = useState<boolean>(false);

  const setupForNew = () => {
    setFileDrawerMode("new");
    setOpen(true);
  };

  const setupForEdit = (template: Template) => {
    // we need to prepare the form
    uploadForm?.setFieldsValue({
      name: template.name,
      audience: template.audience,
      language: template.language,
      tags: template.tags?.map(t => t.name),
      enrollmentCycle: template.enrollmentCycle,
    });

    setFileDrawerMode("edit");
    setSelectedTemplate(template);
    setSelectedFile?.(template.file);
    setOpen(true);
  };

  const toolbarContents: ToolbarButton = {
    New: {
      onClick: () => setupForNew(),
      extraInfo: {
        text: "Import file",
      },
    },
    Edit: {
      disabled: selectedItems.length !== 1,
      onClick: () => {
        const [item] = selectedItems;
        if (!item) return;
        setupForEdit(item);
      },
    },
    Delete: {
      onClick: () => {
        // fill
      },
    },
    Duplicate: {
      onClick: () => {
        // fill
      },
    },
    SelectWithSort: {
      onClick: () => null,

      extraInfo: sortableKeys.map(key => ({
        label: TemplateKeyToLabel[key],
        value: key,
      })),
    },
  };

  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();
  return (
    <div className={styles.container}>
      <ToolbarTable
        layout={layout}
        titleTooltip="Search"
        searchPlaceholder="Search"
        toolbarContents={toolbarContents}
        onSearch={setGlobalFilter}
        searchValue={globalFilter ?? undefined}
        disableSearch={false}
        onClickLayout={onLayoutChange}
        previewFormat={false}
        sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
        onSortChange={([columnKey, order]) => {
          sortItems(columnKey, order);
        }}
        includeFilterOnTableLayout={true}
      />
      <DataListTagsURL<keyof Template, Template>
        data={data}
        originalData={originalData}
      />
      <div>
        {layout === "table" && <Table templates={data} loading={isLoading} />}
        {layout === "card" && (
          <Cards
            templates={data}
            actions={[
              {
                type: "edit",
                icon: <EditOutlined />,
                handler: item => () => {
                  if (!item) return;

                  setupForEdit(item);
                },
              } as Action<Template>,
            ]}
          />
        )}
      </div>

      <FileDrawer
        onClose={() => {
          uploadForm?.resetFields();

          setFileDrawerMode(undefined);
          setSelectedTemplate(undefined);
          setSelectedFile?.(undefined);
          setOpen(false);
        }}
        open={open}
      />
    </div>
  );
};

export default Templates;
