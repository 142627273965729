import { DragIndicatorRounded } from "@mui/icons-material";
import { Box } from "@mui/material";
import { LayerElement } from "screens/designStudio/designStudioV2/types";
import { ToggleLayerVisibility } from "./layerItem/ToggleLayerVisibility";
import { ToggleLayerLock } from "./layerItem/ToggleLayerLock";
import { DeleteLayer } from "./layerItem/DeleteLayer";
import { observer } from "mobx-react-lite";
import { useRef, useMemo } from "react";
import { useRefFocus } from "shared/hooks/useRefFocus";
import useOutsideClick from "shared/hooks/useOutsideClick";
import { LayerIcon } from "./layerItem/LayerIcon";
import { LayerName } from "./layerItem/LayerName";
import { DraggableProvided } from "react-beautiful-dnd";
import { useMergeRefs } from "shared/hooks/useMergeRefs";

type LayerItemProps = {
  layer: LayerElement;
  isSelected: boolean;
  draggableProvided: DraggableProvided;
  onDoubleClick: (id: string) => void;
  onClick: (id: string) => void;
  onKeyDown: (
    layer: LayerElement,
    cleanUp: () => void,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  onClickOutside: (layer: LayerElement) => void;
};

export const LayerItem = observer(
  ({
    layer,
    isSelected,
    draggableProvided,
    onDoubleClick,
    onClick,
    onClickOutside,
    onKeyDown,
  }: LayerItemProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const containerRef = useOutsideClick(() => onClickOutside(layer));
    const mergedRefs = useMergeRefs(draggableProvided?.innerRef, containerRef);

    const isHighlighted = !!layer.store.selectedElements.find(
      e => e.id === layer.id,
    );

    const draggableProps = useMemo(
      () => ({
        ...draggableProvided.draggableProps,
        ...draggableProvided.dragHandleProps,
        style: draggableProvided.draggableProps.style,
      }),
      [draggableProvided.draggableProps, draggableProvided.dragHandleProps],
    );

    useRefFocus(inputRef, isSelected);

    return (
      <Box
        ref={mergedRefs}
        sx={{
          border: 1,
          borderRadius: 1,
          p: 1,
          py: 0.5,
          gap: 1,
          borderColor: "divider",
          backgroundColor: isHighlighted ? "action.hover" : "background.paper",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "action.hover",
            "& .layerActions": {
              visibility: "visible",
            },
          },
        }}
        onDoubleClick={() => onDoubleClick(layer.id)}
        onClick={() => onClick(layer.id)}
        {...draggableProps}
      >
        <DragIndicatorRounded sx={{ color: "divider", fontSize: "medium" }} />
        <LayerIcon layer={layer} />
        <LayerName
          layer={layer}
          onKeyDown={onKeyDown}
          isSelected={isSelected}
        />
        <Box
          sx={{ display: "flex", gap: 0.5, visibility: "hidden" }}
          className="layerActions"
        >
          <ToggleLayerVisibility layer={layer} />
          <ToggleLayerLock layer={layer} />
          <DeleteLayer layer={layer} />
        </Box>
      </Box>
    );
  },
);
