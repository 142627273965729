import { useEffect, useRef } from "react";
import { TMediaComposition } from "shared/types/assetExporter";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import styles from "./MediaPreview.module.scss";

type MediaPreviewProps = {
  row: Record<string, string>;
  editingComposition: TMediaComposition;
};

export const MediaPreview = ({
  row,
  editingComposition,
}: MediaPreviewProps) => {
  const value = editingComposition.column
    ? row[editingComposition.column]
    : editingComposition?.url;
  const isVideo = value?.includes(".mp4");

  const { currentCompositionId } = useAssetBatchesContext();
  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!previewRef.current?.id.includes(currentCompositionId ?? "")) return;
    previewRef.current?.scrollIntoView();
  }, [currentCompositionId]);

  return (
    <div className={styles.previewContainer}>
      <div
        className={styles.preview}
        id={editingComposition.compositionId}
        ref={previewRef}
      >
        {value && (
          <div>
            {isVideo ? (
              <video className={styles.media} autoPlay loop>
                <source src={value} type="video/mp4" />
              </video>
            ) : (
              <img src={value} className={styles.media} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
