import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Tooltip } from "antd";
import { Key, useCallback, useMemo, useRef, useState } from "react";
import { useFeedContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/FeedContext";
import {
  CompositionType,
  MediaSubtype,
  TMediaComposition,
} from "shared/types/assetExporter";
import uuid from "uuid";
import { useAssetBatchesContext } from "../../../shared/contexts/AssetBatchesContext";
import styles from "./AddMediaDropdown.module.scss";
import { CAMOption } from "./CAMOption";
import { getVideoDurationForAdEngine } from "screens/assetExporter/feedConfigurationV2/assetBatchDrawer/utils";

export const AddMediaDropdown = () => {
  const camElement = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const {
    setOpenFileManager,
    addTemplateBlocked,
    compositions,
    setCompositions,
    feedId,
    setCurrentCompositionId,
  } = useAssetBatchesContext();
  const { editingAssetBatch } = useFeedContext();

  const createNewMediaComposition = useCallback(
    async (subtype: MediaSubtype, url: string, name?: string) => {
      const duration = url.includes(".mp4")
        ? Math.floor(await getVideoDurationForAdEngine(url))
        : 0;
      const newMediaComposition: TMediaComposition = {
        compositionId: `composition_${uuid()}_${Date.now()}`,
        feedId,
        ...(editingAssetBatch?.assetBatchId && {
          assetBatchId: editingAssetBatch.assetBatchId,
        }),
        type: CompositionType.Media,
        url,
        subtype,
        variables: {},
        template: "",
        duration,
        ...(name && { name }),
      };
      setCompositions(oldCompositions => [
        ...oldCompositions,
        newMediaComposition,
      ]);
      setCurrentCompositionId(newMediaComposition.compositionId);
    },
    [
      editingAssetBatch?.assetBatchId,
      feedId,
      setCurrentCompositionId,
      setCompositions,
    ],
  );

  const options = useMemo(
    () => [
      {
        key: "template",
        element: "Add template",
        value: "template",
      },
      {
        key: "feed",
        element: "Add media from feed column or URL",
        value: "feed",
      },
      {
        key: "cam",
        element: (
          <CAMOption
            ref={camElement}
            onSelectAsset={(url: string, name: string) => {
              createNewMediaComposition(MediaSubtype.CAM, url, name);
            }}
          />
        ),
        value: "cam",
      },
    ],
    [camElement, createNewMediaComposition],
  );

  const [optionSelected, setOptionSelected] = useState(options[0]);

  const onSelectClick = useCallback(
    (key: Key) => {
      if (key === "cam") return camElement.current?.click();
      if (key === "template") return setOpenFileManager(true);
      if (key === "feed")
        return createNewMediaComposition(MediaSubtype.Feed, "");
    },
    [setOpenFileManager, createNewMediaComposition, camElement],
  );

  const onOptionSelected = useCallback(
    (key: Key) => {
      const option = options.find(({ key: optionKey }) => optionKey === key);
      if (option) {
        setOptionSelected(option);
        onSelectClick(option.key);
      }
    },
    [onSelectClick, setOptionSelected, options],
  );

  const menu = (
    <Menu onClick={e => onOptionSelected(e.key)}>
      {options.map(({ key, element }) => (
        <Menu.Item key={key}>{element}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Tooltip
      title="You've hit the maximum clip limit of 3"
      visible={(addTemplateBlocked || compositions.length > 2) && showTooltip}
    >
      <div
        className={styles.tooltip}
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
      >
        <Dropdown.Button
          disabled={addTemplateBlocked || compositions.length > 2}
          className={styles.dropdown}
          onClick={() => onSelectClick(optionSelected.key)}
          overlay={menu}
          data-cy="cta-button-select"
          icon={<DownOutlined />}
        >
          {optionSelected.element}
        </Dropdown.Button>
      </div>
    </Tooltip>
  );
};
