import { Button, Drawer, Tabs } from "antd";
import styles from "./FileDrawer.module.scss";
import { useMemo, useState } from "react";
import Properties from "./fileDrawer/Properties";
import Layers from "./fileDrawer/Layers";
import {
  ConditionalGroupLayer,
  GroupLayer,
  Layer,
  TextLayer,
  Template,
} from "shared/types/salesEnablement";
import Preview from "../shared/Preview";
import {
  isConditionalGroupLayer,
  isGroupLayer,
  isTextLayer,
} from "./fileDrawer/utils";
import { useSalesEnablementContext } from "../hooks/SalesEnablementDataProvider";
import { useMutation } from "react-query";
import API from "services";
import { useUser } from "shared/hooks/useUser";

enum Step {
  Tags = "tags",
  Components = "components",
}
type Props = {
  onClose: () => void;
  open: boolean;
};
const FileDrawer = (props: Props) => {
  const { onClose, open } = props;
  const {
    uploadForm,
    layers,
    setLayers,
    onSearchLayersBy,
    searchLayersBy,
    selectedFile,
    filteredLayers,
    setFilteredLayers,
  } = useSalesEnablementContext();
  const tabs = useMemo(
    () => [
      {
        label: "Tags",
        key: Step.Tags,
      },
      {
        label: "Components",
        key: Step.Components,
        disabled: !selectedFile || selectedFile.status !== "done",
      },
    ],
    [selectedFile],
  );

  const [, setHoveredLayer] = useState<Layer>();

  const mutation = useMutation(
    (form: Partial<Record<keyof Template, any>>) => {
      API.services.salesEnablement.saveTemplate(form);
      return Promise.resolve({});
    },
    {
      onSuccess: () => {
        onClose();
      },
    },
  );
  const user = useUser();
  return (
    <Drawer
      title="Import File"
      placement="right"
      onClose={onClose}
      visible={open}
      width={"95%"}
      bodyStyle={{ padding: 0 }}
      footer={
        <div className={styles.footer}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={!selectedFile || selectedFile.status !== "done"}
            type="primary"
            onClick={() => {
              const orgForm = uploadForm?.getFieldsValue();
              const formToSubmit = Object.keys(orgForm).reduce<
                Partial<Record<keyof Template, any>>
              >((acc, key) => {
                const val = orgForm[key];
                if (!val) return acc; // skip empty values
                return {
                  ...acc,
                  [key]: val,
                };
              }, {});

              mutation.mutate({
                ...formToSubmit,
                createdBy: user.email,
                lastUpdatedBy: user.email,
              });
            }}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.tabsContainer}>
          <Tabs
            className={styles.tabs}
            tabPosition={"left"}
            defaultActiveKey={Step.Tags}
          >
            {tabs.map(tab => (
              <Tabs.TabPane
                className={styles.tabPane}
                tab={tab.label}
                key={tab.key}
                disabled={tab.disabled}
              >
                <div className={styles.content}>
                  {tab.key === Step.Tags && <Properties />}
                  {tab.key === Step.Components && (
                    <Layers
                      layers={filteredLayers ?? []}
                      onChange={(layer, action) => {
                        const { type, data } = action || {};
                        if (
                          type === "convert_to_group" ||
                          type === "convert_to_text"
                        ) {
                          setFilteredLayers?.(prevLayers => {
                            return prevLayers.map(l => {
                              if (l.id !== layer.id) return l;
                              if (type === "convert_to_group") {
                                if (!isTextLayer(layer))
                                  throw new Error("Must be text layer");

                                const textlayer = { ...layer };
                                return {
                                  ...textlayer,
                                  type: "group",
                                  subLayers: [],
                                  orgLayer: { ...textlayer },
                                };
                              } else {
                                if (!isGroupLayer(layer))
                                  throw new Error("Must be group later");
                                const groupLyaer = layer;

                                return {
                                  id: groupLyaer.orgLayer.id,
                                  name: groupLyaer.orgLayer.name,
                                  page: groupLyaer.orgLayer.page,
                                  isLocked: groupLyaer.orgLayer.isLocked,
                                  type: "text",
                                };
                              }
                            });
                          });
                        } else if (type === "group_add") {
                          const { layerId, replace, value } = data || {};
                          const targetLayer = layers.find(
                            l => l.id === layerId,
                          );

                          if (!targetLayer || !isTextLayer(targetLayer))
                            throw new Error("Cannot find target layer");

                          setFilteredLayers?.(prevLayers => {
                            return prevLayers
                              .filter(l =>
                                replace ? l.id !== targetLayer.id : true,
                              )
                              .map(l => {
                                if (l.id !== layer.id) return l;

                                const groupLayer = layer as GroupLayer;

                                const layerExists = groupLayer.subLayers.some(
                                  sl => sl.id === targetLayer.id,
                                );
                                const subLayers = layerExists
                                  ? groupLayer.subLayers
                                  : [...groupLayer.subLayers, targetLayer];

                                return {
                                  ...groupLayer,
                                  value,
                                  subLayers,
                                };
                              });
                          });
                        } else if (type === "group_remove") {
                          // NOTE: the "layer" here is a child layer of one of the group layer.
                          //  hence, this "layer" is not currently displayed in the table.
                          //  We need to do few things here.
                          //  1. remove the layer from the group layer
                          //  2. add the "layer" back into the "filteredLayers" with same order that was listed originally.

                          // find group layer
                          let parentLayer = filteredLayers
                            ?.filter(isGroupLayer)
                            .find(fl =>
                              fl.subLayers.map(sl => sl.id).includes(layer.id),
                            );
                          if (parentLayer?.id === layer.id) {
                            if (parentLayer.subLayers.length === 1) {
                              parentLayer = {
                                ...parentLayer,
                                subLayers: [],
                              };
                            } else {
                              // removing first sub-layer
                              const [, second, ...rest] = parentLayer.subLayers;
                              parentLayer = {
                                ...second,
                                type: "group",
                                id: second.id,
                                name: parentLayer.name,
                                orgLayer: second as TextLayer,
                                subLayers: [second, ...rest],
                              };
                            }
                          }

                          const layersToDisplay = [];
                          for (const l of layers) {
                            if (layer.id === l.id) {
                              layersToDisplay.push(l);

                              continue;
                            }

                            if (parentLayer?.id === l.id) {
                              layersToDisplay.push({
                                ...parentLayer,
                                subLayers: parentLayer.subLayers.filter(
                                  sl => sl.id !== layer.id,
                                ),
                              });
                              continue;
                            }

                            const filtered: GroupLayer | undefined =
                              filteredLayers?.find(
                                fl => fl.id === l.id,
                              ) as GroupLayer;
                            if (filtered) {
                              layersToDisplay.push({
                                ...filtered,
                                ...(isGroupLayer(filtered)
                                  ? {
                                      subLayers: filtered.subLayers.filter(
                                        sl => sl.id !== layer.id,
                                      ),
                                    }
                                  : {}),
                              });
                            }
                          }
                          setFilteredLayers?.(layersToDisplay);
                        } else if (type === "add_conditional_set") {
                          setFilteredLayers?.(prevLayers => {
                            return prevLayers.map(l => {
                              if (l.id !== layer.id) return l;
                              if (!isConditionalGroupLayer(l))
                                throw new Error(
                                  "Must be conditional group layer",
                                );

                              if (!data)
                                throw new Error("Conditional set is missing");

                              const conditionalGroupLayer: ConditionalGroupLayer =
                                {
                                  ...l,
                                  conditionalSet: data,
                                };
                              return conditionalGroupLayer;
                            });
                          });
                        }
                      }}
                      onLayerHover={layer => setHoveredLayer(layer)}
                      onLayerLock={(layer, lock) =>
                        setLayers(prevLayers =>
                          prevLayers.map(l =>
                            l.id !== layer.id ? l : { ...l, isLocked: lock },
                          ),
                        )
                      }
                      onSearch={onSearchLayersBy}
                      value={searchLayersBy}
                    />
                  )}
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>

        <div className={styles.previewContainer}>
          <Preview />
        </div>
      </div>
    </Drawer>
  );
};

export default FileDrawer;
