import { MutableRefObject, ReactNode } from "react";
import { CAMSelect } from "shared/components/media";

interface IProps {
  onSelectAsset: (url: string, name: string) => void;
  ref?: MutableRefObject<any>;
  children?: ReactNode;
}

export const CAMOption = ({ ref, onSelectAsset, children }: IProps) => (
  <CAMSelect
    width="95vw"
    layout="grid"
    title="Select media from CAM"
    maxSelected={1}
    fileTypes={["png", "jpeg", "jpg", "mp4"]}
    onClose={async assets => {
      onSelectAsset(assets[0].hiResURLRaw, assets[0].name);
    }}
  >
    <div ref={ref}>{children ?? "Add media from CAM"}</div>
  </CAMSelect>
);
