import { isTemplateComposition } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext.utils";
import {
  CompositionType,
  MediaSubtype,
  TComposition,
} from "shared/types/assetExporter";
import { ITemplate } from "shared/types/designStudio";

export const getVariableCollapseHeaderTitle = (
  templates: ITemplate[],
  templateId: string,
  compositionType: CompositionType,
  mediaSubType: MediaSubtype,
) => {
  if (compositionType === CompositionType.Template)
    return templates.find(compTemplate => compTemplate.id === templateId)?.name;
  if (mediaSubType === MediaSubtype.CAM) return "Media from CAM";
  return "Media from feed column or URL";
};

export const getDurationText = (composition: TComposition) => {
  if (
    isTemplateComposition(composition) ||
    composition.column ||
    composition.url
  )
    return `${composition.duration}`;
  return `0`;
};

export const getCurrentTemplate = (
  composition: TComposition,
  templates: ITemplate[],
) => {
  if (isTemplateComposition(composition))
    return templates.find(
      compTemplate => compTemplate.id === composition.template,
    );
};

export const updateDurationWithCompositionId = (
  compositions: TComposition[],
  compositionId: string,
  duration: number,
) => {
  return compositions.map(comp =>
    comp.compositionId === compositionId ? { ...comp, duration } : comp,
  );
};
