import { UploadFile, UploadFileStatus } from "antd/lib/upload/interface";
import { FileType, TemplateFile } from "shared/types/salesEnablement";

export const toUploadFile = (
  file?: TemplateFile,
  status?: UploadFileStatus,
): UploadFile | undefined =>
  file
    ? {
        uid: file.uid,
        name: file.name,
        status: (status || file.status) ?? "done",
        url: file.url,
        originFileObj: file.originFileObj,
      }
    : undefined;

export const toTemplateFile = (
  file: UploadFile,
  type: FileType,
): TemplateFile => ({
  uid: file.uid,
  name: file.name,
  url: file.url,
  originFileObj: file.originFileObj,
  status: file.status,
  type,
});
