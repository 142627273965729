import { isEmpty } from "lodash";
import { Issue } from "screens/adLibrary/shared/validationUtils";
import { POLOTNO_EDITOR } from "screens/designStudio/designStudioV2/constants";
import { TLayerItem } from "screens/designStudio/editor.hooks/useLayers";
import GenericError from "shared/errors/GenericError";
import { IDataTableError } from "shared/types/errors";
import { IAccount } from "./accountManagement";
import { IInstantExperience, IInstantExperienceValidations } from "./adLibrary";
import { OfferData } from "./assetBuilder";
import { IBrand } from "./brandManagement";

export type HeaderMenu = "LIBRARY" | "EDITOR" | "DELETED ASSETS";
export type TabMenu = "stamps" | "templates" | "artboards" | "everything-ads";

export type PropertyRowType =
  | "PROPERTIES"
  | "TEXT"
  | "MANAGE_LAYERS"
  | "LOGO_DROP_ZONES"
  | "MANAGE_ARTBOARD"
  | "PRIMARY_MESSAGE"
  | "SHAPE"
  | "IMAGE"
  | "VIDEO";

export interface IGeneralObjectType {
  [key: string]: string | number;
}

export interface IGetTemplatesResult {
  templates: ITemplate[];
  paginationKey: string;
}

export interface IGetTemplatesResponse {
  result: null | IGetTemplatesResult;
  error: null | IDataTableError;
  statusCode: number;
}

export interface GetArtboardsResult {
  artboards: IArtboard[];
  scannedCount: number;
}

export interface GetArtboardsResponse {
  result: null | GetArtboardsResult;
  error: null | IDataTableError;
}

export interface GetStampsResult {
  stamps: IStamp[];
  scannedCount: number;
}

export interface GetStampsResponse {
  result: null | GetStampsResult;
  error: null | IDataTableError;
}

export type LogoDropZoneType = "square" | "vertical" | "horizontal";
export type LogoEventType =
  | "SALES_EVENT_LOGO"
  | "ACCOUNT_LOGO"
  | "BRAND_LOGO"
  | "OEM_LOGO"
  | "STORE_LOGO";
export const isLogoEventType = (value: any): value is LogoEventType => {
  return (
    [
      "OEM_LOGO",
      "SALES_EVENT_LOGO",
      "ACCOUNT_LOGO",
      "BRAND_LOGO",
      "STORE_LOGO",
    ] as LogoEventType[]
  ).includes(value);
};
export const LogoEventTypes: Array<{ title: string; type: LogoEventType }> = [
  {
    title: "Sales Event Logo",
    type: "SALES_EVENT_LOGO",
  },
  {
    title: "Account Logo",
    type: "ACCOUNT_LOGO",
  },
  {
    title: "Brand Logo",
    type: "BRAND_LOGO",
  },
];

export interface IFilterStatesType {
  tagFilterTemplates: string[];
  oemFilterTemplates: string[];
  storeFilterTemplates: string[];
  offerCountFilterTemplates: string;
  oemFilterStamps: string[];
  stateFilterStamps: string[];
}

export interface IExtendedFabricObject extends fabric.Object {
  customType: ExtendedObjectType;
  customData: TCustomDataObject;
  src?: string;
  videoSrc?: string;
}

export interface IVideoObject extends IDimension, IScale {
  customData: ICustomVideoData;
  top: number;
  left: number;
}

export type TCustomDataObject =
  | ((
      | IStampObjectData
      | ILogoObjectData
      | ICustomShapeData
      | ICustomVideoData
    ) &
      ICustomObjectData)
  | ICustomObjectData;

export interface IStampObjectData {
  stampId: string;
  stampName?: string;
  originalStampId?: string;
}

export interface ILogoObjectData {
  logoEventType: LogoEventType;
  logoDropZoneType: LogoDropZoneType;
  centerPoint?: fabric.Point;
}

export interface ICustomObjectData {
  layerName: string;
  verticalAlign?: TVerticalAlign;
  textboxHeight?: number;
}

export interface ICustomShapeData {
  shape: TShape;
  fill: string; // color in hex
  radius: number;
}

export interface ICustomVideoData {
  videoSrc: string;
  duration: number;
  // TO DO: add and integrate mediaType for videos to handle gif, webm, mp4, etc
}

export const isTemplate = (
  potentialTemplate?: any,
): potentialTemplate is ITemplate => {
  return (
    !!potentialTemplate &&
    !!potentialTemplate.artboard &&
    !!potentialTemplate.assetType
  );
};

const isVideoObject = (obj: any): boolean => {
  if (!obj) return false;

  return (
    obj.customType === "selected_video" &&
    !isEmpty(obj.customData) &&
    !!obj.customData.videoSrc
  );
};

/**
 * Template default media type is "png". If template canvas has video in it, its media type will be "mp4".
 * @param json json string
 */
export const getTemplateMediaType = (json: any): TemplateMediaType => {
  if (!json || !json.objects || !Array.isArray(json.objects)) return "png";

  const isVideoObjectExist = !!(json.objects as fabric.Object[]).find(obj =>
    isVideoObject(obj),
  );

  return isVideoObjectExist ? "mp4" : "png";
};

// About canvasJsonUrl can be null!
// A template is first being created in DesignStudio.tsx
// At this point, there is no canvas data available.
// So it will be initially null and the filled out when it is ready.
export interface ITemplate {
  id?: string; // this is uuid generated from the server side.
  status: TemplateStatusType;
  type: TTemplateType;
  name: string;
  assetType: string;
  thumbnailUrl: string | null;
  canvasJsonUrl: string | null;
  artboard: IArtboard;
  oems: string[];
  stores: string[];
  tags: string[]; // TODO: This has to be changed to tag data type
  numOfStamps: number;
  createdBy: string;
  createdAt: number | null;
  lastUpdatedBy: string | null;
  lastUpdatedAt: number | null;
  isDeleted: boolean;
  state: string;
  mediaType?: TemplateMediaType;
  htmlFileUrl?: string;
}
export type TemplateStatusType = "PUBLISHED" | "UN-PUBLISHED";
export type TTemplateType =
  | "carcut"
  | "lifestyle"
  | "html"
  | typeof POLOTNO_EDITOR;

export type TemplateMediaType = "mp4" | "gif" | "jpg" | "png";
export type MediaFilterType = "video" | "image";

export interface IPreviewIframe {
  url: string;
  template: ITemplate;
}

export interface IArtboard {
  name: string;
  asset_type: string;
  width: number;
  height: number;
  new_name?: string;
  created_at: number | null;
  updated_at: number | null;
  theme_images?: string;
}

export interface ITemplateTag {
  name: string;
  createdAt: number;
  createdBy: string;
}

type StampStatusType = TemplateStatusType;

export interface INewStamp {
  stampJsonUrl?: string;
  status: StampStatusType;
  name: string;
  offerType: string | null; // this should be one of offerTypes defined in shared/constants/dataManagement.ts. By default, this is set to null and will be assigned.
  width: number;
  height: number;
  oem: string | IBrand;
  oems: string[];
  state: string;
  stores?: string[] | IAccount[];
  tags: string[];
  createdAt: number;
  updatedAt: number;
  createdBy: string;
  updatedBy: string;
  isDeleted: boolean;
  exceptions?: IStampException[];
}

interface IStampException {
  type: IStampExceptionType;
  value: string;
  stampJsonUrl: string;
  thumbnailUrl: string;
}

export type IStampExceptionType = "state";

export const isStampArray = (
  potentialStampArray: any,
): potentialStampArray is IStamp[] => {
  if (!Array.isArray(potentialStampArray)) return false;

  const firstElement = potentialStampArray[0];
  if (!firstElement) return false;

  const stampToCheck = firstElement;
  return stampToCheck && stampToCheck.id && stampToCheck.offerType; // for any stamp, offerType must exist
};

export const isStamp = (potentialStamp: any): potentialStamp is IStamp => {
  let stampToCheck = potentialStamp;
  if (Array.isArray(potentialStamp)) {
    const firstElement = potentialStamp[0];
    if (!firstElement) return false;

    stampToCheck = firstElement;
  }

  return stampToCheck && stampToCheck.id && stampToCheck.offerType; // for any stamp, offerType must exist
};

export interface IStamp extends INewStamp {
  id: string;
  thumbnailUrl: string | null;
}

export type GetTemplateOemsMap = {
  result: {
    templates: { id: string; name: string }[];
    paginationKey?: string;
  };
  error?: GenericError;
};

export type FetchingDataStatus =
  | null
  | "accounts"
  | "tags"
  | "artboards"
  | "stamps"; // null indicates that fetching data is not in progress
export type CreatingDataStatus =
  | null
  | "tag"
  | "template"
  | "complete_template"
  | "artboard"
  | "complete_artboard"
  | "stamp"
  | "complete_stamp";

export type UpdatingDataStatus =
  | null
  | "template"
  | "complete_template"
  | "archiving_template"
  | "stamp"
  | "complete_stamp"
  | "duplicating_stamp"
  | "artboard";

export type AllPublishedStatus = "ALL" | "PUBLISHED" | "UN-PUBLISHED";

export interface IDesignStudioState {
  templates: ITemplate[];
  fetchingData: FetchingDataStatus;
  updatingData: UpdatingDataStatus;
  savingDraft: CanvasProcessing;

  oems: IBrand[];
  stores: IAccount[];
  tags: ITemplateTag[]; // this type will be resolved once tag CRUD is added
  artboards: IArtboard[];
  stamps: IStamp[];
  templateToUpdate?: ITemplate | null;
  templateToEditor: ITemplate | null;
  stampToEditor?: IStamp | null;

  creatingData: CreatingDataStatus;
  deletingDataId: string | null; // the id of the data or null
  paginationKey: string | null;
  publishingTemplateId: string | null;

  imageInsertData: null | {
    type: imageType;
    data?: Partial<IStamp>;
  };

  canvasJson: string | null;

  error: GenericError | null;
  layerObjects: ILayerObject[];
  canvasObjectToAlterIndex: null | number;
  canvasArrangeValue: string;
  activeLayerObjects: ILayerObject[];
  activeTextSelection: ITextSelection | null;
  currentSelectionStyles: TSelectionStyle[];
  currentLayerObjectId: string;
  currentLayerOperation: TLayerOperation;
  currentLayerToggleOpProp: "" | TLayerToggleProperty;
  currentTextOperation: "" | TTextObjectProperty;

  publishCanvasStatus: IPublishCanvasStatus | null;

  stampToUpdate?: null | IStamp;
  artboardToUpdate?: null | IArtboard;
  fabricHistory: {
    past: IFabricHistoryObject[];
    currentHistoryIndex: null | number;
    actionType: null | FabricHistoryActionType;
  };

  fabricClipboard: {
    clipboardObject: null | fabric.Object;
    actionType: null | FabricClipboardActionType;
  };

  alignLayersActionType: AlignLayersActionType;

  originalFabricObjJson?: null | string;

  selectedStampOfferType: null | {
    id: string;
    offerType: string;
  };

  stampException?: {
    type: IStampExceptionType;
    value: string;
  };

  randomizingData: CanvasProcessing;
  previewOfferData: IPreviewOfferDataObj | null;
  selectedFilterOem: null | string[];
  selectedFilterStore: null | string[];
  selectedFilterTags: null | string[];
  selectedFilterOfferCounts: string;

  selectedFilterOemStamp: null | string[];
  selectedFilterStateStamp: null | string[];
  selectedFilterDescriptorStamp: null | string[];

  uniqueUUID: string;
  editingArtboardStatus?: "begin" | "completed" | "error";

  searchInput: string;
  templatesAllPublishedUnpublished: AllPublishedStatus;

  canvasEditId: string | null;

  duplicatedTemplate: ITemplate | null;
  selectedFilterDimensionsArtboard:
    | {
        width: number | null;
        height: number | null;
      }
    | undefined;

  selectedFilterDimensionsTemplate:
    | {
        width: number | null;
        height: number | null;
      }
    | undefined;

  instantExperienceSaved?: IInstantExperience | null;
  instantExperienceDraft?: IInstantExperience | null;
  instantExperienceDraftValidations?: IInstantExperienceValidations | null;
  selectedInstantExperienceElementId?: string;
  instantExperienceImport: {
    instantExperiencesToImport: IInstantExperience[];
    displayImportTable: boolean;
    cancelImport: boolean;
    selectedInstantExperienceIds: string[];
    processingInstantExperiences: boolean;
    instantExperienceImportIssues: Issue[];
  };
  disabledPublishButton: boolean;
}

export interface IPublishCanvasStatus {
  type: TabMenu;
  action?: "preview";
  status: "UPDATE_IN_PROGRESS" | "BEGIN" | "FAIL" | "SUCCESS" | "UN-PUBLISHING";
}

export interface IDesignStudioDataFetchResponse {
  result: {
    oems?: IBrand[];
    dealers?: IAccount[];
    tags?: ITemplateTag[];
    templates?: ITemplate[];
    artboards?: IArtboard[];
    stamps?: IStamp[];
    paginationKey: string | null;
    scannedCount: number;
  };
  error: GenericError | null;
}

export interface IResponse<TResult, TError = GenericError | null> {
  result: {
    [key: string]: TResult;
  };
  error: TError;
}

export interface IGenericResponse<TResult> {
  result: null | TResult;
  error: GenericError | null;
}

export interface ICreateTagResponse {
  result: {
    tag: ITemplateTag;
  };
  error: GenericError | null;
}

export interface ICreateArtboardResponse {
  result: {
    createdArtboard: IArtboard;
  };
  error: GenericError | null;
}

export interface ICreateStampResponse {
  result: {
    stamps: IStamp[];
  };
  error: GenericError | null;
}

export interface IFloatingSelectType {
  show: boolean;
  cursorPosition: { lineIndex: number; charIndex: number };
  rect: {
    width: number;
    height: number;
  };
  at: { left: number; top: number };
}

export type imageType =
  | "CAR_CUT"
  | "STAMP"
  | "LOGO"
  | "SHAPE"
  | "LIFESTYLE"
  | "THEME_BACKGROUND"
  | "SELECTED_IMAGE"
  | "SELECTED_VIDEO"
  | "DISCLOSURE"
  | "TEXT"
  | "BACKGROUND"
  | "GRID";

export type TShape = "square" | "circle" | "triangle" | "squircle";

export type TAssetType = "Website" | "Display" | "Email" | "Social" | "Misc";
export const templateAssetTypes: string[] = [
  "Website",
  "Display",
  "Email",
  "Social",
  "Misc",
];

export interface ILayerObject {
  id: string; // uuid from canvasObject.name
  type?: string;
  secondaryType?: string;
  index: number;
  selectable?: boolean;
  visible?: boolean;
  width?: number;
  height?: number;
  scaleY: number;

  // ITextObjectProperty
  name?: string;
  fontFamily?: string;
  fontWeight?: number | string;
  fontSize?: number;
  charSpacing?: number;
  lineHeight?: number;
  opacity?: number;
  textAlign?: string;
  fill?: string;
  deltaY?: number;
  verticalAlign: TVerticalAlign;
  original?: fabric.Object;
}

export type TTextAlign =
  | "left"
  | "center"
  | "right"
  | "justify"
  | "justify-left"
  | "justify-center"
  | "justify-right";

export type TVerticalAlign = "top" | "middle" | "bottom";

export type TLayerObjectProperty = "selectable" | "visible" | "name";

export type TTextObjectProperty =
  | "name"
  | "fontFamily"
  | "fontWeight"
  | "fontSize"
  | "charSpacing"
  | "lineHeight"
  | "opacity"
  | "textAlign"
  | "fill"
  | "deltaY"
  | "verticalAlign";

export type TTextSelectionProperty =
  | "fontFamily"
  | "fontWeight"
  | "fontSize"
  | "fill"
  | "deltaY";

export type TSelectionStyle = Record<TTextSelectionProperty, string>;

export type TLayerOperation = "remove" | "arrange" | "alter" | "";
export type TLayerToggleProperty = "visible" | "selectable";

export interface ITextSelection {
  start: number;
  end: number;
}

export type FabricActionType = "add" | "modify" | "remove";

export type FabricHistoryActionType = null | "redo" | "undo";

export type FabricClipboardActionType = null | "copy" | "paste";

export type AlignLayersActionType =
  | null
  | "left"
  | "center"
  | "right"
  | "top"
  | "middle"
  | "bottom";

interface IFabricHistoryObject {
  actionType: FabricActionType;
  objectJson: string; //fabric.Object;
}

export type CanvasProcessing =
  | "SAVE_FIRED"
  | "IN_PROGRESS"
  | "COMPLETE"
  | "ERROR"
  | null;

export interface IPreviewOfferDataObj {
  offerData: OfferData;
  dealerName: string;
}

export interface ITemplateAndStampData {
  template: ITemplate | null;
  stamp: IStamp | null;
}

export type CanvasType = "stamp" | "template";

export const isCanvasBackground = (obj: IExtendedFabricObject | TLayerItem) => {
  return (
    (obj as IExtendedFabricObject).customType === "canvas_bg" ||
    obj.type === "canvas_bg"
  );
};

export type ExtendedObjectType =
  | "stamp"
  | "logo"
  | "disclosure"
  | "car_cut"
  | "selected_image"
  | "lifestyle"
  | "canvas_bg"
  | "selected_video"
  | "shape" // refer to TShape and imageType
  | "theme_background"
  | "canvas_area"
  | "grid"
  | "text"
  | "textbox";

export interface IExtendedTextbox extends fabric.Textbox {
  originalText?: string;
  variableAlias?: IVariableAlias;
}

export interface IVariableAlias {
  [variable: string]: {
    customVariable: string;
    isMaskOn: boolean;
    customVariableCaps: string;
    isCapsOn: boolean;
  };
}

export type TExtendedTextbox = fabric.Textbox &
  IExtendedFabricObject & {
    originalText?: string;
    originalTextLines?: string[];
    text?: string;
    textLines?: string[];
    variableAlias?: TVariableAlias;
  };

export type TExtendedImage = fabric.Image &
  IExtendedFabricObject & {
    variableAlias?: TVariableAlias;
  };

export type TExtendedLogo = fabric.Group &
  IExtendedFabricObject & {
    customData: ILogoObjectData;
  };

export type TExtendedVideo = fabric.Image &
  IExtendedFabricObject & {
    customData: ICustomVideoData;
    videoTime: number;
  };

export type TVariableAlias = {
  [variable: string]: TVariableAliasInfo;
};

export type TVariableAliasInfo = {
  customVariable: string;
  isMaskOn: boolean;
  isCapsOn: boolean;
  startIndex: number;
};

export type TVariable = {
  value: string;
  startIndex: number;
};

export interface IDimension {
  width: number;
  height: number;
}

export interface IScale {
  scaleX: number;
  scaleY: number;
}

export const customFabricAttributes = [
  "name",
  "customType",
  "customData",
  "fill",
  "opacity",
  "textAlign",
  "fontFamily",
  "fontSize",
  "fontWeight",
  "charSpacing",
  "lineHeight",
  "deltaY",
  "lockScalingY",
  "styles",
  "hasControls",
  "padding",
  "variableAlias",
  "originalText",
  "originalStyles",
  "scaleY",
  "height",
  "width",
  "selectable",
  "src",
  "visible",
];

export type TemplateListCardToggleProp =
  | "settingsPopoverMenuId"
  | "publishingTemplateId"
  | "canvasEditId";

// Instant Experiences

export const isActiveSelection = (
  object: any,
): object is fabric.ActiveSelection => {
  if (!object) return false;

  return object.type === "activeSelection";
};

export const QUERY_KEY_PREVIEW_TEMPLATE = "previewTemplateUrl";
