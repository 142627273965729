/* eslint-disable react/display-name */
import { Col, Form, Input, Row } from "antd";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import Cell from "screens/assetExporter/feedConfiguration/editableCell/Cell";
import {
  DataListProvider,
  IDataListColumns,
  createDataListConfig,
} from "shared/components/dataList";
import { fetchFeedTblRow } from "shared/hooks/assetExporter/useFeedConfigFetch";
import { FeedTblCol, FeedTblRow } from "shared/types/assetExporter";
import { checkFilterMatch } from "utils/helpers";
import common from "../shared/components/CommonDrawer.module.scss";
import { useAssetBatchesContext } from "../shared/contexts/AssetBatchesContext";
import {
  getBackgroundMedia,
  getBackgroundMediaForComposition,
  getBackgroundValueMapping,
  isTemplateComposition,
  visibleColumnsFilter,
} from "../shared/contexts/AssetBatchesContext.utils";
import { useAssetBatchesValueMappingContext } from "../shared/contexts/AssetBatchesValueMappingContext";
import { useAssetBatchesRules } from "../shared/hooks/useAssetBatchesRules";
import { useFeedContext } from "../shared/contexts/FeedContext";
import styles from "./ApplyCondition.module.scss";
import Preview from "./applyCondition/Preview";
import Rules from "./applyCondition/Rules";
import { ArtboardSelect } from "screens/assetExporter/feedConfigurationV2/assetBatchDrawer/ArtboardSelect";
import { videoCompositionEnabled } from "shared/constants/assetExporter";

interface IApplyConditionProps {
  setFilteredRows: Dispatch<SetStateAction<FeedTblRow[]>>;
}

export const ApplyCondition = ({ setFilteredRows }: IApplyConditionProps) => {
  const [feedTableColumns, setFeedTableColumns] = useState<FeedTblCol[]>();
  const {
    batchName,
    feedId,
    ruleConditions,
    nameInputError,
    compositions,
    saveBatchName,
    setBackgroundColumns,
    setBackgroundMedias,
  } = useAssetBatchesContext();
  const { savePreviewCount } = useAssetBatchesRules();
  const { onSelectedRowChange } = useAssetBatchesValueMappingContext();
  const { editingAssetBatch } = useFeedContext();

  const getTableColumns = (orderedColumns: string[]) => {
    return orderedColumns.filter(visibleColumnsFilter).map(key => {
      const dataIndex = key;
      return {
        title: key,
        dataIndex,
        editable: true,
        key,
        sorter: true,
        width: 200,
        render: (value: string) => (
          <Cell
            oldValue={value}
            toggleClicked={() => {
              // fill
            }}
            editable={false}
          />
        ),
      };
    });
  };

  const setColumnsBaseOnFeed = useCallback(() => {
    if (!feedId) return;
    const getColumns = async () => {
      const { orders: newSortedColumns } = await fetchFeedTblRow({
        feedId,
        page: "1",
      });

      const tableColumns = getTableColumns(newSortedColumns);
      setFeedTableColumns(tableColumns);
    };
    getColumns();

    return () => {
      setFeedTableColumns(undefined);
    };
  }, [feedId]);

  useEffect(setColumnsBaseOnFeed, [setColumnsBaseOnFeed]);

  const columns: IDataListColumns<FeedTblCol> =
    feedTableColumns &&
    Object.assign(
      {},
      ...(feedTableColumns as FeedTblCol[]).map(feedTableColumn => ({
        [feedTableColumn.key]: {
          display: (value: string) => feedTableColumn.key + ": " + value,
          filterFn: (value: any, record: any) => {
            return checkFilterMatch(
              value?.toString(),
              record?.[feedTableColumn.key],
            );
          },
        },
      })),
    );

  const ruleCondKey = ruleConditions
    .map(ruleCond => {
      if (!ruleCond.columnName || !ruleCond.value) return;
      return `${ruleCond.index}-${ruleCond.operator}-${ruleCond.comparisonOperator}-${ruleCond.value}`;
    })
    .filter(Boolean);

  const dataListConfig = useMemo(() => {
    return createDataListConfig(columns, {
      queryKey: ["feedConfigDataWithRules", feedId, ruleCondKey, compositions],
      enabled: !!feedId,
      queryFn: async () => {
        const { rows, filteredCount } = await fetchFeedTblRow({
          feedId,
          page: "1",
          ruleArgs: ruleConditions,
        });
        const newPreviewCount = ruleCondKey.length > 0 ? filteredCount : 0;
        savePreviewCount(newPreviewCount ?? 0);
        setFilteredRows(rows);

        if (rows.length > 0) {
          onSelectedRowChange(rows[0]);
          compositions.forEach(composition => {
            if (
              editingAssetBatch &&
              isTemplateComposition(composition) &&
              getBackgroundValueMapping(composition.variables)?.value
            ) {
              const bgMedia = videoCompositionEnabled
                ? getBackgroundMediaForComposition(composition, rows[0])
                : getBackgroundMedia(editingAssetBatch.media, rows[0]);
              setBackgroundMedias(prevMedias => ({
                ...prevMedias,
                [composition.compositionId]: bgMedia,
              }));
              setBackgroundColumns(prevBackgrounds => ({
                ...prevBackgrounds,
                [composition.compositionId]: bgMedia?.column
                  ? bgMedia.column
                  : undefined,
              }));
            }
          });
        }

        return rows;
      },
      cacheTime: 0,
    });
  }, [
    columns,
    feedId,
    ruleCondKey,
    ruleConditions,
    compositions,
    editingAssetBatch,
    onSelectedRowChange,
    savePreviewCount,
    setFilteredRows,
    setBackgroundColumns,
    setBackgroundMedias,
  ]);

  const ableToDisplayFeeds = dataListConfig && feedTableColumns;

  if (!dataListConfig) return null;

  return (
    <Row className={styles.bodyRow}>
      <Col span={8} className={styles.bodyLeft}>
        <Form layout="vertical" className={styles.nameContainer}>
          <Form.Item
            label={
              <>
                Asset batch name <span className={common.required}>*</span>
              </>
            }
            validateStatus={nameInputError ? "error" : "success"}
            help={nameInputError}
          >
            <Input
              id="batchName"
              value={batchName}
              onChange={e => saveBatchName(e.target.value)}
            />
          </Form.Item>
          {videoCompositionEnabled && <ArtboardSelect />}
        </Form>
        <Rules />
      </Col>
      <Col span={16} className={styles.bodyRight}>
        <DataListProvider config={dataListConfig}>
          {ableToDisplayFeeds && (
            <Preview feedTableColumns={feedTableColumns} />
          )}
        </DataListProvider>
      </Col>
    </Row>
  );
};
