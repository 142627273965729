import styles from "./Cards.module.scss";
import { Template } from "shared/types/salesEnablement";
import Card, { Action } from "shared/components/CardV2";

type Props = {
  templates: Template[];
  actions?: Action[];
};
const Cards = (props: Props) => {
  const { templates, actions } = props;

  return (
    <div className={styles.container}>
      {templates.map(template => {
        return (
          <Card<Template>
            item={template}
            key={template.id}
            title={template.name}
            statusInfo={{
              status: template.status === "PUBLISHED" ? "success" : "error",
              title: template.status,
            }}
            tags={template.tags?.map(tag => tag.name) ?? []}
            infos={[template.type]}
            actions={actions}
          />
        );
      })}
    </div>
  );
};

export default Cards;
