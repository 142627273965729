import {
  Audience,
  Field,
  FieldKey,
  Language,
} from "shared/types/salesEnablement";

export const labels: Record<
  Extract<FieldKey, "audience">,
  Record<Audience, string>
> &
  Record<Extract<FieldKey, "language">, Record<Language, string>> = {
  audience: {
    benefitDecisionMaker: "Benefit Decision Maker",
    broker: "Broker",
    businessDecisionMaker: "Business Decision Maker",
    consumer: "Consumer",
    employee: "Employee",
    producer: "Producer",
    publicSector: "Public Sector",
  },
  language: {
    en: "English",
    es: "Spanish",
  },
};

export const fields: Field[] = [
  { label: "Template Name", key: "name", required: true, options: undefined },
  {
    label: "Delivery Method",
    key: "deliveryMethod",
    required: true,
    options: [
      {
        label: "Download",
        value: "download",
        key: "download",
      },
      {
        label: "Send by Email",
        value: "email",
        key: "email",
      },
      {
        label: "Order Prints",
        value: "order",
        key: "order",
      },
    ],
  },
  {
    label: "Audience",
    key: "audience",
    required: true,
    options: [
      {
        key: "benefitDecisionMaker",
        value: "benefitDecisionMaker",
        label: "Benefit Decision Maker",
      },
      { key: "broker", value: "broker", label: "Broker" },
      {
        key: "businessDecisionMaker",
        value: "businessDecisionMaker",
        label: "Business Decision Maker",
      },
      { key: "consumer", value: "consumer", label: "Consumer" },
      { key: "employee", value: "employee", label: "Employee" },
      { key: "producer", value: "producer", label: "Producer" },
      { key: "publicSector", value: "publicSector", label: "Public Sector" },
    ],
  },

  {
    label: "Language",
    key: "language",
    required: true,
    options: [
      { key: "en", value: "en", label: "English" },
      { key: "es", value: "es", label: "Spanish" },
    ],
  },
  { label: "Tags", key: "tags", required: false, options: undefined },
];
