import { IConfig } from "shared/types/configuration";
import {
  IGenFeatureSessionState,
  IImageResizeResponse,
  IImageStatusResponse,
  ISessionResponse,
} from "shared/types/genAI";
import { IApiResponse } from "shared/types/shared";
import HttpClient from "./httpClient";

export default ({ config }: { headers: any; config: IConfig }) => ({
  generateImages: async (
    prompt: string,
    nSamples: number,
    sessionId: string,
  ) => {
    const url = config.services.genAI.imageGen;
    const { result, error } = await HttpClient.post<
      IApiResponse<ISessionResponse<IGenFeatureSessionState>>
    >(url, {
      prompt: prompt,
      nSamples: nSamples,
      height: 512,
      width: 512,
      sessionId,
    });

    if (error) return error;

    return result;
  },

  resizeImage: async (baseImageUrl: string) => {
    const url = config.services.genAI.imageResize;
    const { result, error } = await HttpClient.post<
      IApiResponse<IImageResizeResponse>
    >(url, { url: baseImageUrl });

    if (!result || error) {
      return {
        url: "",
        publicId: "",
      };
    }

    return result;
  },

  getImageStatus: async (id: string) => {
    if (!id) {
      return null;
    }

    const url = config.services.genAI.imageStatus(id);
    const { result } = await HttpClient.get<IApiResponse<IImageStatusResponse>>(
      url,
    );

    return result;
  },

  getSession: async (id: string) => {
    if (!id) {
      return null;
    }

    const url = config.services.genAI.getSession(id);
    const { result, error } = await HttpClient.get<
      IApiResponse<ISessionResponse<IGenFeatureSessionState>>
    >(url);

    if (error) {
      throw new Error(error.message);
    }

    return result;
  },

  createSession: async (feature: "gen" | "resize", id?: string) => {
    if (!id) {
      return null;
    }

    const url = config.services.genAI.createSession;
    const { result } = await HttpClient.post<
      IApiResponse<ISessionResponse<IGenFeatureSessionState>>
    >(url, {
      uuid: id,
      feature,
    });

    return result;
  },
});
