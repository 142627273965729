import { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import {
  LayerElement,
  StoreType,
} from "screens/designStudio/designStudioV2/types";

export const useLayers = (store: StoreType) => {
  const [selectedLayerId, setSelectedLayerId] = useState<string | null>(null);
  const layers = store.activePage?.children ?? [];

  const onDoubleClick = (layerId: string) => {
    setSelectedLayerId(layerId);
  };

  const onClick = (layerId: string) => {
    store.selectElements([layerId]);
  };

  const onClickOutside = (layer: LayerElement) => {
    if (selectedLayerId !== layer.id) return;
    setSelectedLayerId(null);
  };

  const onKeyDown = (
    layer: LayerElement,
    cleanUp: () => void,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (selectedLayerId !== layer.id) return;
    if (e.key === "Enter") {
      layer.set({ name: e.currentTarget.value });
      setSelectedLayerId(null);
    }

    if (e.key === "Escape") {
      cleanUp();
      setSelectedLayerId(layer.id);
    }
  };

  const onDragEnd = (e: DropResult) => {
    const { source, destination } = e;
    if (!source || !destination) return;

    const toIndex = destination.index;
    const fromIndex = source.index;
    const layersCopy = [...layers];

    const [moved] = layersCopy.splice(fromIndex, 1);
    const layersCopyWithoutMovedLayer = layersCopy.filter(
      layer => layer.id !== moved.id,
    );

    store.activePage?.set({
      children: [
        ...layersCopyWithoutMovedLayer.slice(0, toIndex),
        moved,
        ...layersCopyWithoutMovedLayer.slice(toIndex),
      ],
    });
  };

  return {
    layers,
    selectedLayerId,
    onDoubleClick,
    onClick,
    onClickOutside,
    onKeyDown,
    onDragEnd,
  };
};
