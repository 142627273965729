import { StoreType } from "screens/designStudio/designStudioV2/types";
import { ImageList, ImageListItem, Stack, Typography } from "@mui/material";
import { useElements } from "./useElements";
import { POLOTNO_SHAPES } from "screens/designStudio/designStudioV2/constants";

type ShapeProps = {
  store: StoreType;
};

export const Shapes = ({ store }: ShapeProps) => {
  const { addSVG } = useElements(store);

  return (
    <Stack spacing={2}>
      <Typography>Shapes</Typography>
      <ImageList variant="masonry" cols={2} gap={8}>
        {POLOTNO_SHAPES?.map(src => (
          <ImageListItem key={src} onClick={() => addSVG({ src })}>
            <img srcSet={src} src={src} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>
    </Stack>
  );
};
