import {
  SmartDisplayRounded,
  TitleRounded,
  PhotoSizeSelectActualRounded,
  CropLandscapeRounded,
} from "@mui/icons-material";
import { LayerElement } from "screens/designStudio/designStudioV2/types";

type LayerIconProps = {
  layer: LayerElement;
};

const baseIconProps = { sx: { fontSize: "medium", color: "action.active" } };

export const LayerIcon = ({ layer }: LayerIconProps) => {
  switch (layer.type) {
    case "video":
      return <SmartDisplayRounded {...baseIconProps} />;
    case "image":
      return <PhotoSizeSelectActualRounded {...baseIconProps} />;
    case "svg":
      return <CropLandscapeRounded {...baseIconProps} />;
    case "text":
    default:
      return <TitleRounded {...baseIconProps} />;
  }
};
