import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import APIs from "services";

export default () => {
  const { data: templates = [] } = useQuery("templates", async () => {
    const { result } = await APIs.services.salesEnablement.getTemplates();

    return result;
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return {
    templates,
    loading,
  };
};
