import { DeleteOutlined, EditOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { isTemplateComposition } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext.utils";
import { useUndoDeleteComposition } from "screens/assetExporter/feedConfigurationV2/shared/hooks/useUndoDeleteComposition";
import { TComposition, TMediaComposition } from "shared/types/assetExporter";
import styles from "./VariableCollapseHeader.module.scss";
import {
  getCurrentTemplate,
  getDurationText,
  getVariableCollapseHeaderTitle,
  updateDurationWithCompositionId,
} from "./utils";

export type VariableCollapseHeaderProps = {
  composition: TComposition;
};

export const VariableCollapseHeader = ({
  composition,
}: VariableCollapseHeaderProps) => {
  const {
    templatesToUse: templates,
    replaceTemplateComposition,
    changeTemporalRemoved,
    setEditingTemplate,
    compositions,
    setCompositions,
  } = useAssetBatchesContext();

  const { undoMessage } = useUndoDeleteComposition(composition);

  const currentTemplate = getCurrentTemplate(composition, templates);

  const title = getVariableCollapseHeaderTitle(
    templates,
    composition.template,
    composition.type,
    (composition as TMediaComposition).subtype,
  );

  const handleEditTemplate = () => {
    if (!isTemplateComposition(composition)) return;

    setEditingTemplate(composition.template);
    const editorUrl = `/design-studio/editor/templates/${currentTemplate?.id}/editor-v2?from=ad-engine`;
    window.open(editorUrl, "_blank");
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rgx = /^[0-9]*\.?[0-9]*$/;
    if (!rgx.test(event.target.value)) return;

    const duration = event.target.value ? parseFloat(event.target.value) : 0;
    if (isNaN(duration)) return;
    const newCompositions = updateDurationWithCompositionId(
      compositions,
      composition.compositionId,
      duration,
    );

    const totalDuration = newCompositions
      .flatMap(comp => comp.duration)
      .reduce((acc, curr) => acc + curr, 0);
    const MAX_DURATION = 180;
    if (totalDuration <= MAX_DURATION) {
      setCompositions(newCompositions);
      return;
    }

    const adjustedDuration = MAX_DURATION - (totalDuration - duration);
    const adjustedCompositions = updateDurationWithCompositionId(
      compositions,
      composition.compositionId,
      adjustedDuration,
    );

    setCompositions(adjustedCompositions);

    const messageKey = `durationAlert-${composition.compositionId}`;
    message.success({
      className: styles.message,
      key: messageKey,
      content: `We adjusted your duration from ${duration}s to ${adjustedDuration}s since total video limit is ${MAX_DURATION}s`,
      duration: 5,
      size: "small",
    });
  };

  return (
    <div className={styles.headerContainer}>
      <div>{title}</div>
      <div className={styles.buttonContainer}>
        {isTemplateComposition(composition) && (
          <Button
            type="text"
            size="small"
            icon={
              <SyncOutlined
                onClick={() => replaceTemplateComposition(composition)}
              />
            }
          />
        )}
        {isTemplateComposition(composition) && (
          <Button
            type="text"
            size="small"
            icon={<EditOutlined onClick={() => handleEditTemplate()} />}
          />
        )}
        <Button
          type="text"
          size="small"
          icon={
            <DeleteOutlined
              onClick={() => {
                changeTemporalRemoved(composition, true);
                undoMessage();
              }}
            />
          }
        />
        {compositions.length > 1 ? (
          <Input
            size="small"
            suffix={<div style={{ color: "rgba(0,0,0,.45)" }}>s</div>}
            className={styles.mediaDurationInput}
            onClick={event => {
              event.stopPropagation();
            }}
            onChange={handleOnChange}
            maxLength={4}
            value={composition.duration}
          />
        ) : (
          <span className={styles.durationText}>
            {getDurationText(composition)}
            <span className={styles.durationTextSec}>s</span>
          </span>
        )}
      </div>
    </div>
  );
};
