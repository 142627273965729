import { IDataListURLFields } from "shared/components/dataListURL/types";
import { StringParam } from "use-query-params";
import moment from "moment";
import { compareStringBy, onFilterBy } from "utils/helpers.table";
import {
  Audience,
  Template,
  TemplateFilterKey,
} from "shared/types/salesEnablement";
import { labels } from "./templates/fileDrawer/Properties.fields";

export const fields: IDataListURLFields<TemplateFilterKey, Template> = {
  name: {
    filterFn: onFilterBy("name", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("name"),
    queryParamConfigFilter: StringParam,
  },
  audience: {
    filterFn: onFilterBy("audience", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("audience"),
    queryParamConfigFilter: StringParam,
    display: (val: string) => {
      // "val" is either audience value of multiple audience values concatenated with comma
      const values = val.split(",") as Audience[];
      return values.map(v => labels.audience[v]).join(", ");
    },
  },
  status: {
    filterFn: onFilterBy("status", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("status"),
    queryParamConfigFilter: StringParam,
  },
  tags: {
    filterFn: onFilterBy("tags", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("tags"),
    queryParamConfigFilter: StringParam,
  },
  createdAt: {
    sorterFn: (a: Template, b: Template) =>
      moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    defaultSortOrder: "descend",
  },
  lastUpdatedAt: {
    sorterFn: (a: Template, b: Template) =>
      moment(a.lastUpdatedAt).unix() - moment(b.lastUpdatedAt).unix(),
    defaultSortOrder: "descend",
  },
};

export const fieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
