import { createStore as createPolotnoStore } from "polotno/model/store";
import { DesignStudioTableTemplate } from "screens/designStudio/context/DesignStudioDataProvider";
import { PolotnoJSON, StoreType } from "../../types";
import { POLOTNO_API_KEY } from "../../constants";
import { getImageNameFromLink } from "./helpers";

const storeConfig = POLOTNO_API_KEY
  ? { key: POLOTNO_API_KEY, showCredit: false }
  : undefined;

export const createStore = (item: DesignStudioTableTemplate) => ({
  store: createPolotnoStore(storeConfig),
  assetId: item.asset.id ?? "",
});

export type ImageObjectParams = {
  src: string;
  width: number;
  height: number;
  name?: string;
};

export const createImageObject = (
  store: StoreType,
  params: ImageObjectParams,
) => {
  store.activePage?.addElement({
    type: "image",
    ...params,
  });
};

export type TextObjectParams = {
  text?: string;
  fontSize?: number;
  name?: string;
};

export const createTextObject = (
  store: StoreType,
  params: TextObjectParams,
) => {
  store.activePage?.addElement({
    type: "text",
    ...params,
  });
};

export type SVGObjectParams = { src: string; name?: string };

export const createSVGObject = (store: StoreType, params: SVGObjectParams) => {
  store.activePage?.addElement({
    type: "svg",
    name: getImageNameFromLink(params.src),
    ...params,
  });
};

export const getBase64Thumbnail = async (store: StoreType) => {
  const dataUrl = await store.toDataURL();
  return dataUrl.replace(/^data:image\/(jpg|jpeg|png);base64,/, "");
};

export const toJSON = (store: StoreType) => {
  // Polotno's types are not great yet, so we're casting to our own type for this MVP.
  return store.toJSON() as unknown as PolotnoJSON;
};
