import { Col, Row, Select } from "antd";
import {
  INPUT_TYPES,
  INPUT_TYPE_TITLE,
} from "screens/assetExporter/feedConfigurationV2/shared/constants";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { useAssetBatchesTemplateCompositionContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesTemplateCompositionContext";
import { TInputType } from "screens/assetExporter/feedConfigurationV2/shared/types";
import styles from "../PanelVariables.module.scss";

type SelectInputTypeProps = {
  inputType: TInputType;
  mappingKey: string;
};

export const SelectInputType = ({
  inputType,
  mappingKey,
}: SelectInputTypeProps) => {
  const { setShowTextAlert } = useAssetBatchesContext();
  const { onSelectInputType } = useAssetBatchesTemplateCompositionContext();

  return (
    <Row>
      <Col span={24}>
        <Select<TInputType>
          value={inputType}
          className={styles.input}
          onSelect={val => {
            setShowTextAlert(false);
            onSelectInputType(mappingKey, val);
          }}
        >
          {INPUT_TYPES.map(type => (
            <Select.Option key={type} value={type}>
              {INPUT_TYPE_TITLE[type]}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};
