import { EyeOutlined } from "@ant-design/icons";
import { Image } from "antd";

type Props = {
  thumbnail: string | undefined;
  legend: string;
};
export const ImagePreviewCell = ({ thumbnail, legend }: Props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Image
        src={thumbnail}
        width={40}
        height={40}
        preview={{
          mask: (
            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
              <EyeOutlined />
            </div>
          ),
        }}
      />
      <span style={{ marginLeft: "10px" }}>{legend}</span>
    </div>
  );
};
