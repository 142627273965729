import { Empty, Spin, message } from "antd";
import API from "services";
import { UndoMessage } from "shared/components/UndoMessage";
import { useWindowSize } from "shared/hooks/useWindowSize";
import {
  AssetBatchDrawerMode,
  STEP_CREATE_BATCH,
  TAssetBatch,
  TTemplateComposition,
} from "shared/types/assetExporter";
import styles from "./AssetBatchesPanel.module.scss";
import { ItemAssetBatch } from "./assetBatchesPanel/ItemAssetBatch";
import { useAssetBatchesContext } from "./shared/contexts/AssetBatchesContext";
import {
  getBackgroundResizeType,
  isTemplateComposition,
  parseConditions,
  parseValueMappings,
} from "./shared/contexts/AssetBatchesContext.utils";
import { useFeedContext } from "./shared/contexts/FeedContext";
import { useDeleteAssetBatch } from "./shared/hooks/useDeleteAssetBatch";
import { StringParam, useQueryParam } from "use-query-params";
import { ITemplate } from "shared/types/designStudio";

type AssetBatchesPanelProps = {
  feedId: string;
  onClick: (assetBatch: TAssetBatch) => void;
  onReset: () => void;
  setOpenAssetBatchDrawer: (open: boolean) => void;
  setAssetBatchDrawerMode: (mode: AssetBatchDrawerMode) => void;
};

export const AssetBatchesPanel = ({
  feedId,
  onClick,
  onReset,
  setOpenAssetBatchDrawer,
  setAssetBatchDrawerMode,
}: AssetBatchesPanelProps) => {
  const { assetBatches, isFetching, setEditingAssetBatch, setIsUpdated } =
    useFeedContext();
  const { windowInnerHeight } = useWindowSize();
  const handleClick = (assetBatch: TAssetBatch) => onClick(assetBatch);

  const [_, setAssetId] = useQueryParam("assetId", StringParam);
  const showUndoMessage = (assetBatch: TAssetBatch, allowUndo?: boolean) => {
    const { assetBatchId, name } = assetBatch;
    const messageKey = `deleteAssetBatch-${feedId}-${assetBatchId}`;

    if (allowUndo) {
      message.success({
        className: styles.undoMessage,
        key: messageKey,
        content: (
          <UndoMessage
            message={`Asset batch ${name} configuration was deleted.`}
            onClick={() => {
              deleteAssetBatch({ ...assetBatch, undo: true });
              message.destroy(messageKey);
            }}
            onClose={() => {
              message.destroy(messageKey);
            }}
          />
        ),
        duration: 10,
        size: "small",
      });
      return;
    }

    message.destroy(messageKey);
    message.success({
      className: styles.undoMessage,
      key: messageKey,
      content: "Asset Batch Restored.",
      duration: 2,
    });
  };

  const { mutate: deleteAssetBatch, isLoading } =
    useDeleteAssetBatch(showUndoMessage);

  const {
    saveBatchName,
    saveRuleConditions,
    setTemplates,
    setVariables,
    setCompositions,
    updateMaxStep,
    backgroundResizeTypes,
    setBackgroundResizeTypes,
  } = useAssetBatchesContext();

  const handleEdit = async (assetBatch: TAssetBatch) => {
    updateMaxStep(STEP_CREATE_BATCH.THREE_TEXT);
    setIsUpdated(false);
    setOpenAssetBatchDrawer(true);
    setAssetBatchDrawerMode("Edit");
    setEditingAssetBatch(assetBatch);
    setAssetId(assetBatch.assetBatchId?.toString() ?? "");

    const { name, conditions, compositions } = assetBatch;
    const templateCompositions = compositions.filter(
      isTemplateComposition,
    ) as TTemplateComposition[];
    saveBatchName(name);
    saveRuleConditions(parseConditions(conditions));
    const promises = templateCompositions.flatMap(composition =>
      API.services.designStudio.getTemplateById(composition.template),
    );
    const results = await Promise.all(promises);
    const templates = results
      .map(({ result }) => result?.template)
      .filter(Boolean);
    setTemplates(templates as ITemplate[]);

    setCompositions(compositions);
    setVariables(
      templateCompositions.map(composition => ({
        compositionId: composition.compositionId,
        variables: parseValueMappings(composition.variables),
      })),
    );

    templateCompositions.forEach(composition => {
      const bgResizeType = getBackgroundResizeType(composition.variables);
      setBackgroundResizeTypes({
        ...backgroundResizeTypes,
        [composition.compositionId]: bgResizeType,
      });
    });
  };

  return (
    <div
      className={styles.container}
      style={{ maxHeight: windowInnerHeight - 205 }}
    >
      <div className={styles.header}>Asset batches</div>
      <Spin tip="Loading..." spinning={isLoading || isFetching}>
        <div className={styles.panel}>
          {!assetBatches?.length && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {assetBatches?.map(assetBatch => {
            const { assetBatchId } = assetBatch;
            return (
              <ItemAssetBatch
                key={assetBatchId}
                assetBatch={assetBatch}
                onClick={handleClick}
                onEdit={handleEdit}
                onDelete={() => {
                  onReset();
                  deleteAssetBatch({ ...assetBatch, undo: false });
                }}
                isLoading={isLoading}
              />
            );
          })}
        </div>
      </Spin>
    </div>
  );
};
