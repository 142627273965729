import { Layer } from "shared/types/salesEnablement";
import { OnLayerChangeFunc } from "../../Layers";
import {
  CloseCircleOutlined,
  FontSizeOutlined,
  PictureOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { isGroupLayer, isImageLayer, isTextLayer } from "../../utils";

const LayerTypeToggle = (props: {
  layer: Layer;
  isChildLayer: boolean;
  onChange?: OnLayerChangeFunc;
}) => {
  const { layer, onChange, isChildLayer } = props;
  const [hovered, setHovered] = useState(false);
  const mouseEnterLeaveProps = {
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
  };

  return hovered ? (
    <>
      {isImageLayer(layer) && <PictureOutlined />}
      {isTextLayer(layer) && (
        <>
          {isChildLayer && (
            <CloseCircleOutlined
              onClick={() => {
                onChange?.({ ...layer }, { type: "group_remove" });
              }}
              {...mouseEnterLeaveProps}
            />
          )}

          {!isChildLayer && (
            <ThunderboltOutlined
              onClick={() =>
                onChange?.({ ...layer }, { type: "convert_to_group" })
              }
              {...mouseEnterLeaveProps}
            />
          )}
        </>
      )}

      {isGroupLayer(layer) && layer.subLayers.length === 0 && (
        <CloseCircleOutlined
          onClick={() => {
            onChange?.({ ...layer }, { type: "convert_to_text" });
          }}
          {...mouseEnterLeaveProps}
        />
      )}
    </>
  ) : (
    <>
      {isGroupLayer(layer) && layer.subLayers.length === 0 && (
        <ThunderboltOutlined {...mouseEnterLeaveProps} />
      )}
      {isTextLayer(layer) && <FontSizeOutlined {...mouseEnterLeaveProps} />}
    </>
  );
};
export default LayerTypeToggle;
