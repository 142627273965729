import {
  GroupLayer,
  ImageLayer,
  Layer,
  TextLayer,
  OptionGroupLayer,
  ConditionalGroupLayer,
} from "shared/types/salesEnablement";

export const isGroupLayer = (layer: Layer): layer is GroupLayer =>
  layer.type === "group";

export const isOptionGroupLayer = (layer: Layer): layer is OptionGroupLayer =>
  isGroupLayer(layer) && layer.smartVariable === "options";

export const isConditionalGroupLayer = (
  layer: Layer,
): layer is ConditionalGroupLayer =>
  isGroupLayer(layer) && layer.smartVariable === "conditional";

export const isImageLayer = (layer: Layer): layer is ImageLayer =>
  layer.type === "image";
export const isTextLayer = (layer: Layer): layer is TextLayer =>
  layer.type === "text";

export const hasChildren = (layer: Layer): layer is GroupLayer =>
  isGroupLayer(layer) &&
  layer.subLayers.length > 0 &&
  (layer.smartVariable === "agent" || layer.smartVariable === "product");
