import { message } from "antd";
import { useCallback } from "react";
import { useSaveTemplateDraft } from "screens/designStudio/designStudioV2/hooks/useSaveTemplateDraft";
import {
  getBase64Thumbnail,
  toJSON,
} from "../../../editorDrawerContext/helpers.store";
import { StoreType } from "polotno/model/store";
import { DesignStudioTableTemplate } from "screens/designStudio/context/DesignStudioDataProvider";
import { isTemplate } from "screens/designStudio/helpers";

export const useSaveStore = (
  store: StoreType | null,
  item: DesignStudioTableTemplate | null,
) => {
  const { mutate: saveDraftMutation, isLoading: isSaving } =
    useSaveTemplateDraft();

  const save = useCallback(async () => {
    if (!store || !item || !isTemplate(item)) return;

    const { asset: template } = item;
    const base64Thumbnail = await getBase64Thumbnail(store);
    saveDraftMutation(
      { template, json: toJSON(store), base64Thumbnail },
      {
        onSuccess: () => {
          message.success("Template draft saved successfully");
        },
        onError: () => {
          message.error("Failed to save template draft");
        },
      },
    );
  }, [saveDraftMutation, item, store]);

  return {
    save,
    isSaving,
  };
};
