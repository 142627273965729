import { ArrowBackRounded } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  Divider,
  Chip,
  Stack,
} from "@mui/material";
import { useDesignStudioEditorItems } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditorItems";
import { useDesignStudioEditor } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditor";

type EditorHeaderProps = {
  onRemove: (assetId: string) => void;
  onChange: (assetId: string) => void;
};

export const EditorHeader = ({ onRemove, onChange }: EditorHeaderProps) => {
  const { selectedId, setEditor } = useDesignStudioEditor();
  const { items } = useDesignStudioEditorItems();

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        borderBottom: 1,
        borderColor: "divider",
        backgroundColor: "background.paper",
        py: 1,
        px: 2,
        gap: 2,
        display: "flex",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          gap: 2,
        }}
      >
        <IconButton size="small" onClick={() => setEditor(null)}>
          <ArrowBackRounded />
        </IconButton>
        <Typography>Design Studio</Typography>
      </Box>
      <Divider orientation="vertical" variant="fullWidth" flexItem />
      <Stack direction="row" spacing={1}>
        {items.map(item => (
          <Chip
            onClick={() => item.asset.id && onChange(item.asset.id)}
            key={item.asset.id}
            label={item.asset.name}
            size="small"
            variant={selectedId === item.asset.id ? "filled" : "outlined"}
            onDelete={() => item.asset.id && onRemove(item.asset.id)}
          />
        ))}
      </Stack>
    </Box>
  );
};
