import { Layer } from "shared/types/salesEnablement";
import { OnLayerChangeFunc } from "../Layers";
import LayerTypeToggle from "./layerName/LayerTypeToggle";
import styles from "./LayerName.module.scss";
import { isGroupLayer } from "../utils";

type Props = {
  layer: Layer;
  isChildLayer?: boolean;
  onChange?: OnLayerChangeFunc;
};
const LayerName = (props: Props) => {
  const { layer, onChange, isChildLayer } = props;
  const { name } = layer;

  return (
    <div className={styles.container}>
      <LayerTypeToggle
        layer={layer}
        isChildLayer={isChildLayer ?? false}
        onChange={onChange}
      />

      <span
        className={
          isGroupLayer(layer) && layer.subLayers.length > 0
            ? styles.groupLayerTitle
            : ""
        }
      >
        {name}
      </span>
    </div>
  );
};

export default LayerName;
