import { PlayCircleOutlined, PlusSquareFilled } from "@ant-design/icons";
import { Button } from "antd";
import {
  BROADCAST_CHANNEL_NAME,
  videoCompositionEnabled,
} from "shared/constants/assetExporter";
import { useBroadCastChannel } from "shared/hooks/useBroadcastChannel";
import { MediaPreview } from "../../shared/components/MediaPreview";
import { NavTemplateItems } from "../../shared/components/Preview/NavTemplateItems";
import { TemplatePreviewSection } from "../../shared/components/TemplatePreviewSection";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { isTemplateComposition } from "../../shared/contexts/AssetBatchesContext.utils";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";
import styles from "./PanelTemplate.module.scss";
import { TemplateDrawer } from "./panelTemplate/TemplateDrawer";
import { TemplateHeader } from "./panelTemplate/TemplateHeader";

export const PanelTemplate = () => {
  const {
    compositions,
    setShowReload,
    openFileManager,
    setOpenFileManager,
    templatesToUse: templates,
    previewLoading,
  } = useAssetBatchesContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const { setIsVideoPreviewOpen } = useVideoStitchingContext();

  useBroadCastChannel(BROADCAST_CHANNEL_NAME, () => setShowReload(true));

  if (!videoCompositionEnabled) {
    const firstTemplateComposition = compositions[0];

    return (
      <div className={styles.main}>
        {!templates[0] && (
          <div
            className={styles.noSelection}
            onClick={() => {
              setOpenFileManager(true);
            }}
            data-cy="select-template"
          >
            <PlusSquareFilled className={styles.plusIcon} />
            Select Template
          </div>
        )}

        {!!templates[0] &&
          firstTemplateComposition &&
          isTemplateComposition(firstTemplateComposition) && (
            <>
              <TemplateHeader />
              <TemplatePreviewSection
                template={templates[0]}
                composition={firstTemplateComposition}
                selectedRow={selectedRow}
              />
            </>
          )}
        <TemplateDrawer
          open={openFileManager}
          onClose={() => setOpenFileManager(false)}
        />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.previewHeader}>
          <span>Preview</span>
          <NavTemplateItems />
        </div>
        {compositions.length > 1 && (
          <Button
            disabled={previewLoading}
            className={styles.playButton}
            onClick={() => setIsVideoPreviewOpen(true)}
            style={{
              backgroundColor: "#FFFFFF",
              padding: "4px 15px 4px 15px",
            }}
          >
            <PlayCircleOutlined style={{ marginRight: "10px" }} />
            Play full video
          </Button>
        )}
      </div>
      <div className={styles.main}>
        <TemplateDrawer
          open={openFileManager}
          onClose={() => setOpenFileManager(false)}
        />

        {!!compositions?.length &&
          compositions.map(composition => {
            if (!isTemplateComposition(composition)) {
              return (
                <div key={composition.compositionId}>
                  <MediaPreview
                    row={selectedRow}
                    editingComposition={composition}
                  />
                </div>
              );
            }

            const template = templates.find(
              compTemplate => compTemplate.id === composition.template,
            );

            return (
              <TemplatePreviewSection
                key={`template-preview-${composition.compositionId}`}
                template={template}
                composition={composition}
                selectedRow={selectedRow}
              />
            );
          })}
      </div>
    </div>
  );
};
