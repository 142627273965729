import { AutoComplete, Col, Row } from "antd";
import { TComposition, TMediaComposition } from "shared/types/assetExporter";
import { FieldWrapper } from "../../shared/components/FieldWrapper";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import { getVideoDurationForAdEngine } from "../utils";
import styles from "./VariableInput.module.scss";

type MediaFromFeedInputProps = {
  compositionId: string;
};

export const MediaFromFeedInput = ({
  compositionId,
}: MediaFromFeedInputProps) => {
  const { mediaColumns, compositions, setCompositions } =
    useAssetBatchesContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const composition = compositions.find(
    comp => comp.compositionId === compositionId,
  ) as TMediaComposition;
  const value = composition?.column ?? composition?.url;

  const editMediaInputValue = async (url: string, column?: string) => {
    const src = column ? selectedRow[column] : url;
    const isVideo = src?.includes(".mp4");
    const duration = isVideo
      ? Math.floor(await getVideoDurationForAdEngine(src))
      : 0;
    const newCompositions = compositions.map(comp => {
      if (comp.compositionId === compositionId) {
        return {
          ...comp,
          url,
          column,
          duration,
        };
      }
      return comp;
    }) as TComposition[];
    setCompositions(newCompositions);
  };
  return (
    <Row className={styles.row} justify="space-around" align="top">
      <Col span={24}>
        <FieldWrapper width={24}>
          <AutoComplete
            allowClear={true}
            options={mediaColumns?.map(col => {
              return {
                key: col.columnName,
                value: col.columnName,
              };
            })}
            filterOption
            className={styles.input}
            bordered={true}
            value={value}
            onChange={(val, option) => {
              if (val && "key" in option && typeof option.key === "string")
                editMediaInputValue("", option.key);
              else editMediaInputValue(val);
            }}
            onKeyDown={e => {
              if (e.key === "Enter" && value) editMediaInputValue("", value);
              if (e.key === "Esc" || e.key === "Escape")
                editMediaInputValue("");
            }}
          />
        </FieldWrapper>
      </Col>
    </Row>
  );
};
