import { Dispatch, SetStateAction } from "react";
import { isEqual } from "lodash";
import {
  FeedTblRow,
  STEP_CREATE_BATCH_V2,
  TAssetBatch,
  TMediaComposition,
  TTemplateComposition,
} from "shared/types/assetExporter";
import styles from "./AssetBatchDrawerV2.module.scss";
import { ApplyCondition } from "./assetBatchDrawer/ApplyCondition";
import { ManageVariables } from "./assetBatchDrawerV2/ManageVariables";
import { useAssetBatchesContext } from "./shared/contexts/AssetBatchesContext";
import {
  isTemplateComposition,
  parseConditions,
} from "./shared/contexts/AssetBatchesContext.utils";
import { useFeedContext } from "./shared/contexts/FeedContext";
import { AssetBatchDrawerBase } from "./AssetBatchDrawerBase";
import { VideoStitchingProvider } from "./shared/contexts/VideoStitchingContext";

interface AssetBatchDrawerProps {
  onSubmit: (assetBatch: TAssetBatch) => void;
  onClose: (showUndoMessage: boolean, batchName: string) => void;
  setFilteredRows: Dispatch<SetStateAction<FeedTblRow[]>>;
}

export const AssetBatchDrawerV2 = ({
  onSubmit,
  onClose,
  setFilteredRows,
}: AssetBatchDrawerProps) => {
  const {
    batchName,
    ruleConditions,
    compositions,
    isAllValid,
    isFirstStepValid,
    currentStep,
  } = useAssetBatchesContext();

  const { editingAssetBatch } = useFeedContext();

  const isLastStep = () => currentStep === STEP_CREATE_BATCH_V2.TWO_VARIABLES;

  const edited = () => {
    if (!editingAssetBatch) return false;

    const areSameNames = editingAssetBatch?.name === batchName;
    const areSameConditions = isEqual(
      parseConditions(editingAssetBatch.conditions),
      ruleConditions,
    );

    const areSameVariables = compositions.every(
      composition =>
        !isTemplateComposition(composition) ||
        isEqual(
          composition.variables,
          (
            editingAssetBatch?.compositions.find(
              com =>
                com.compositionId === composition.compositionId &&
                isTemplateComposition(com),
            ) as TTemplateComposition | undefined
          )?.variables,
        ),
    );

    const areSameDurations = compositions.every(composition =>
      isEqual(
        composition.duration,
        editingAssetBatch?.compositions.find(
          com => com.compositionId === composition.compositionId,
        )?.duration,
      ),
    );

    const areSameUrls = compositions.every(
      composition =>
        isTemplateComposition(composition) ||
        isEqual(
          composition.url,
          (
            editingAssetBatch?.compositions.find(
              com => com.compositionId === composition.compositionId,
            ) as TMediaComposition | undefined
          )?.url,
        ),
    );

    const areSameCompositionsLength =
      compositions.length === editingAssetBatch.compositions.length;

    if (
      areSameNames &&
      areSameConditions &&
      areSameVariables &&
      areSameDurations &&
      areSameUrls &&
      areSameCompositionsLength
    ) {
      return false;
    }

    return true;
  };

  const disabledSaveForFirstStep = !isFirstStepValid || !edited();
  const disabledSaveForLastStep = !isAllValid || !edited();

  const isFirstStep = currentStep === STEP_CREATE_BATCH_V2.ONE_CONDITION;

  return (
    <AssetBatchDrawerBase
      currentStepConst={STEP_CREATE_BATCH_V2.ONE_CONDITION}
      createDisabled={
        (isFirstStep && !isFirstStepValid) || (isLastStep() && !isAllValid)
      }
      editDisabled={isFirstStep && !isFirstStepValid}
      editDisabledLastStep={
        (isFirstStep && disabledSaveForFirstStep) ||
        (isLastStep() && disabledSaveForLastStep)
      }
      steps={
        <div className={styles.stepsContainer}>
          {currentStep === STEP_CREATE_BATCH_V2.ONE_CONDITION && (
            <ApplyCondition setFilteredRows={setFilteredRows} />
          )}
          {currentStep === STEP_CREATE_BATCH_V2.TWO_VARIABLES && (
            <VideoStitchingProvider>
              <ManageVariables />
            </VideoStitchingProvider>
          )}
        </div>
      }
      onSubmit={onSubmit}
      onClose={onClose}
      setFilteredRows={setFilteredRows}
      isLastStep={isLastStep}
    />
  );
};
