import { useMemo } from "react";
import { DataNode } from "antd/lib/tree";
import { IFlippedFacebookCampaignRes } from "shared/types/adReview";

export const useFlipStatus = (
  treeData: DataNode[],
  campaignRes: IFlippedFacebookCampaignRes[],
) => {
  const totalCnt = treeData.reduce((acc, cur) => {
    const adsetCnt = cur?.children?.length || 0;
    return acc + adsetCnt + 1;
  }, 0) as number;
  const loadingCnt =
    totalCnt -
    (campaignRes?.reduce((acc, cur) => {
      const adsetCnt =
        (cur?.value?.adsets?.length || 0) + (cur?.reason?.adsets?.length || 0);
      return acc + adsetCnt + 1;
    }, 0) || 0);
  const flippedCnt =
    campaignRes
      ?.filter(c => c.status === "fulfilled")
      ?.reduce((acc, cur) => {
        const adsetCnt = cur?.value?.adsets?.length || 0;
        return acc + adsetCnt + 1;
      }, 0) || 0;

  const failedCnt =
    campaignRes
      ?.filter(c => c.status === "rejected")
      ?.reduce((acc, cur) => {
        const adsetCnt = cur?.reason?.adsets?.length || 0;
        return acc + adsetCnt + 1;
      }, 0) || 0;

  const successTitles: string[] = useMemo(
    () =>
      campaignRes
        ?.filter(c => c.status === "fulfilled")
        .map(c => {
          const titles = [];
          c.value?.title && titles.push(c.value?.title);
          c.value?.adsets?.forEach(a => {
            a.title && titles.push(a.title);
          });
          return titles;
        })
        .flat() || [],
    [campaignRes],
  );

  const dataSource = useMemo(() => {
    if (!successTitles.length && !failedCnt) return treeData;

    return treeData.map(d => {
      const errMessage =
        campaignRes.find(c => c?.reason?.title === d?.title)?.reason?.message ||
        "Error occurred while flipping campaign";

      if (successTitles.includes(d?.title as string)) {
        return {
          ...d,
          status: "success",
          children: d?.children?.map(c => {
            if (successTitles.includes(c?.title as string)) {
              return {
                ...c,
                status: "success",
              };
            } else {
              return {
                ...c,
                status: "error",
                message: errMessage,
              };
            }
          }),
        };
      } else {
        return {
          ...d,
          status: "error",
          message: errMessage,
          children: d?.children?.map(c => {
            return {
              ...c,
              status: "error",
              message: errMessage,
            };
          }),
        };
      }
    });
  }, [treeData, successTitles, failedCnt, campaignRes]);

  return { totalCnt, loadingCnt, flippedCnt, failedCnt, dataSource };
};
