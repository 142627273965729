import { useCallback } from "react";
import {
  useQueryParams,
  StringParam,
  ArrayParam,
  UrlUpdateType,
} from "use-query-params";

export const useDesignStudioEditor = () => {
  const [editorQuery, setEditorQuery] = useQueryParams({
    assetIds: ArrayParam,
    selectedId: StringParam,
  });

  const setEditor = useCallback(
    (
      query: {
        assetIds?: string[];
        selectedId?: string;
      } | null,
      updateType?: UrlUpdateType,
    ) => {
      if (!query) {
        setEditorQuery(
          {
            assetIds: null,
            selectedId: null,
          },
          updateType,
        );
        return;
      }
      setEditorQuery({ ...editorQuery, ...query }, updateType);
    },
    [editorQuery, setEditorQuery],
  );

  return { ...editorQuery, setEditor };
};
