import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSyncedState } from "shared/hooks/useSyncedState";
import { Layer, TemplateFile } from "shared/types/salesEnablement";

type Props = {
  file?: TemplateFile;
};
export default (props: Props) => {
  const { file } = props;
  const [layers, setLayers] = useState<Layer[]>([]);

  const orgLayers = useMemo(() => {
    return (
      file?.pages?.reduce<Layer[]>(
        (acc, page) => [...acc, ...(page.layers?.flat() || [])],
        [],
      ) ?? []
    );
  }, [file]);

  useEffect(() => {
    if (!file) return;

    setLayers(
      file?.pages?.flatMap(page => page.layers?.flat() ?? []).flat() ?? [],
    );
  }, [file]);

  const [filteredLayers, setFilteredLayers] = useSyncedState(orgLayers);
  const [searchLayersBy, setSearchLayersBy] = useState<string>();
  const onSearchTimeoutRef = useRef<NodeJS.Timeout>();

  const onSearchLayersBy = useCallback(
    (value: string) => {
      if (onSearchTimeoutRef.current) clearTimeout(onSearchTimeoutRef.current);

      onSearchTimeoutRef.current = setTimeout(() => {
        setSearchLayersBy(value);
        setLayers(() =>
          value === ""
            ? orgLayers
            : orgLayers.filter(layer =>
                layer.name.toLowerCase().includes(value.toLocaleLowerCase()),
              ),
        );
      }, 500);
    },
    [orgLayers],
  );

  return {
    originalLayers: orgLayers,
    layers,
    filteredLayers,
    onSearchLayersBy,
    setLayers,
    searchLayersBy,
    setFilteredLayers,
  };
};
