import { Button } from "antd";
import { useCallback } from "react";
import { TMediaComposition } from "shared/types/assetExporter";
import { CAMOption } from "../../assetBatchDrawerV2/manageVariables/panelVariables/CAMOption";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { getVideoDurationForAdEngine } from "../utils";
import styles from "./VariableInput.module.scss";

type MediaFromFeedInputProps = {
  composition: TMediaComposition;
};

export const MediaFromCamInput = ({ composition }: MediaFromFeedInputProps) => {
  const { compositions, setCompositions } = useAssetBatchesContext();

  const onSelectAsset = useCallback(
    async (url: string, name?: string) => {
      const duration = url.includes(".mp4")
        ? Math.floor(await getVideoDurationForAdEngine(url))
        : 0;
      const newCompositions = compositions.map(comp => {
        if (comp.compositionId === composition.compositionId) {
          return {
            ...comp,
            name,
            url,
            duration,
          };
        }

        return comp;
      });

      setCompositions(newCompositions);
    },
    [compositions, composition.compositionId, setCompositions],
  );

  return (
    <div className={styles.camInput}>
      <CAMOption onSelectAsset={onSelectAsset}>
        <Button>Change</Button>
      </CAMOption>
      <span>{composition.name}</span>
    </div>
  );
};
