import React, { useEffect, useState } from "react";
import styles from "./IDMLPreview.module.scss";
import classNames from "classnames";
import { Page } from "shared/types/salesEnablement";
import { useSalesEnablementContext } from "screens/designStudio/hooks/SalesEnablementDataProvider";

type Props = {
  onPageSelect?: (page: Page) => void;
  pageToScroll?: Page;
};
const IDMLPreview = (props: Props) => {
  const { selectedFile } = useSalesEnablementContext();
  const { pages = [] } = selectedFile || {};

  const [selectedPage, setSelectedPage] = React.useState<Page>(pages[0]);
  const [, setSelectedCanvas] = useState<Page>(pages[0]);
  const onPageSelect = (page: Page) => {
    setSelectedPage(page);
    setSelectedCanvas(page);

    props.onPageSelect?.(page);
  };

  const { pageToScroll } = props;

  const scrollIntoView = (ele?: HTMLElement) =>
    ele?.scrollIntoView({ behavior: "smooth", block: "center" });
  useEffect(() => {
    const ele = document.getElementById(`${selectedPage?.id}`);
    scrollIntoView(ele ?? undefined);
  }, [selectedPage]);

  useEffect(() => {
    if (!pageToScroll) return;

    const ele = document.getElementById(`${pageToScroll?.id}`);
    scrollIntoView(ele ?? undefined);
  }, [pageToScroll]);

  return (
    <div className={styles.container}>
      <div className={styles.pages}>
        {pages.map(page => (
          <div
            key={page.id}
            className={classNames(
              styles.page,
              `${selectedPage?.id === page.id ? styles.selected : ""} }`,
            )}
            onClick={() => onPageSelect(page)}
          >
            <img className={styles.img} src={page.thumbnail} alt="" />
          </div>
        ))}
      </div>

      <div className={styles.canvasContainer}>
        {pages.map(page => (
          <div id={page.id} key={page.id}>
            <img className={styles.img} src={page.thumbnail} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default IDMLPreview;
