import {
  SmartVariable,
  SmartVariableOptions,
} from "shared/types/salesEnablement";

/**
 * Converts a title to a SmartVariable. Ex) "Disclosure" -> "disclosure", "Product" -> "product" etc...
 * @param title
 * @param options
 * @returns SmartVariable
 */
export const titleToSmartVar = (
  title: string,
  options: Record<SmartVariable, string> = SmartVariableOptions,
): SmartVariable | undefined => {
  return (Object.keys(options) as SmartVariable[]).find(
    smtVarKey => options[smtVarKey] === title,
  ); // find the key for this title which is type of SmartVariable
};

export const parseGroupTitle = (
  key: string,
  options: Record<string, string>,
) => {
  const title = options[key];
  const [groupTitle, groupIdx] = title.split(" ");
  const idx = parseInt(groupIdx);
  return { title: groupTitle, idx: !isNaN(idx) ? idx : 0 };
};
