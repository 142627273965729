import { Table } from "antd";
import styles from "./Layers.module.scss";
import { ColumnType } from "antd/lib/table";
import { ThunderboltOutlined } from "@ant-design/icons";
import { useState } from "react";
import {
  ConditionalSet,
  Layer,
  LayerValue,
} from "shared/types/salesEnablement";
import classNames from "classnames";
import SmartLayer from "./layers/SmartLayer";
import { isGroupLayer } from "./utils";
import { ReactComponent as NoEdit } from "statics/images/No_Edit.svg";
import { ReactComponent as Edit } from "statics/images/Edit_pen.svg";

type LayerAction =
  | { type: "convert_to_group"; data?: null }
  | { type: "convert_to_text"; data?: null }
  | {
      type: "group_add";
      data: { layerId: string; replace: boolean; value: LayerValue };
    }
  | {
      type: "group_remove";
      data?: null;
    }
  | {
      type: "group_add_option";
      data?: null;
    }
  | {
      type: "group_remove_option";
      data: {
        at: number;
      };
    }
  | {
      type: "group_change_option";
      data: {
        value: string;
        at: number;
      };
    }
  | {
      type: "add_conditional_set";
      data: ConditionalSet;
    };
export type OnLayerChangeFunc = (newLayer: Layer, action?: LayerAction) => void;
type Props = {
  layers: Layer[];
  value?: string;
  onChange?: OnLayerChangeFunc;
  onLayerLock?: (layer: Layer, lock: boolean) => void;
  onLayerHover?: (layer?: Layer) => void;
  onSearch?: (value: string) => void;
};

const Layers = (props: Props) => {
  const { layers, onChange } = props;

  const [hoveredRow, setHoveredRow] = useState<Layer>();

  const columns: ColumnType<Layer>[] = [
    {
      width: 10,
      title: <NoEdit />,
      dataIndex: "lock",
      key: "lock",
      render: (text: string, record: Layer) => {
        return hoveredRow?.id === record.id ? (
          record.isLocked ? (
            <NoEdit
              style={{ color: "red", cursor: "pointer" }}
              onClick={(evt: { stopPropagation: () => void }) => {
                evt.stopPropagation();
                props.onLayerLock?.(record, false);
              }}
            />
          ) : (
            <Edit
              style={{ cursor: "pointer" }}
              onClick={(evt: { stopPropagation: () => void }) => {
                evt.stopPropagation();
                props.onLayerLock?.(record, true);
              }}
            />
          )
        ) : null;
      },
    },
    {
      title: <div style={{ paddingLeft: "calc(51px + 1em)" }}>Name</div>,
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
        const groupLayers = layers.filter(isGroupLayer);
        return (
          <SmartLayer
            layer={record}
            onChange={onChange}
            groupLayers={groupLayers}
          />
        );
      },
    },
    {
      width: 20,
      title: "Page",
      dataIndex: "page",
      key: "page",
      align: "center",
    },
  ];

  return (
    <div className={styles.container}>
      <div>
        These are the components the agents will customize. Hover and:
        <ul className={styles.instruction}>
          <li>
            Click <ThunderboltOutlined /> to make it a smart component
          </li>
          <li>
            Click <NoEdit /> to make it not editable
          </li>
        </ul>
      </div>

      <Table
        rowKey={layer => layer.id}
        className={styles.table}
        rowClassName={layer =>
          classNames(
            layer.isLocked ? styles.locked : "",
            layer.type === "group" ? styles.smart : "",
          )
        }
        columns={columns}
        dataSource={layers}
        pagination={false}
        onRow={record => {
          return {
            onMouseEnter: () => {
              setHoveredRow(record);
              props.onLayerHover?.(record);
            },
            onMouseLeave: () => {
              setHoveredRow(undefined);
              props.onLayerHover?.(undefined);
            },
          };
        }}
      />
    </div>
  );
};

export default Layers;
