import {
  AbsoluteFill,
  OffthreadVideo,
  Series,
  useCurrentFrame,
} from "remotion";
import { CompositionType, TComposition } from "shared/types/assetExporter";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";
import { fadeIn } from "../../shared/utils";
import { VideoCanvas } from "./VideoCanvas";

interface Props {
  fps: number;
  playerWidth: number;
  playerHeight: number;
}

export const VideoComposition = ({ fps, playerWidth, playerHeight }: Props) => {
  const { canvasJsons } = useVideoStitchingContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const { compositions } = useAssetBatchesContext();
  const fadeDuration = Math.round(0.25 * fps);
  const frame = useCurrentFrame();

  const getSerieByCompositionType = (
    composition: TComposition,
    index: number,
    actualDuration: number,
  ) => {
    let fromDuration = 0;
    if (index !== 0) {
      fromDuration = -fadeDuration;
    }
    const opacityIn =
      index !== 0
        ? fadeIn(
            frame,
            actualDuration - composition.duration * fps - fadeDuration,
            actualDuration - composition.duration * fps,
          )
        : 1;

    switch (composition.type) {
      case CompositionType.Template:
        return (
          <Series.Sequence
            durationInFrames={composition.duration * fps}
            offset={fromDuration}
            layout="none"
          >
            <AbsoluteFill>
              <VideoCanvas
                compositionId={composition.compositionId}
                width={playerWidth}
                height={playerHeight}
                canvasJson={canvasJsons[index]}
                opacity={opacityIn}
                fps={fps}
              />
            </AbsoluteFill>
          </Series.Sequence>
        );
      case CompositionType.Media:
        const src = composition.column
          ? selectedRow[composition.column]
          : composition?.url;
        return (
          <Series.Sequence durationInFrames={composition.duration * fps}>
            <AbsoluteFill>
              {src?.includes(".mp4") ? (
                <OffthreadVideo src={src} style={{ opacity: opacityIn }} />
              ) : (
                <img
                  src={src}
                  style={{
                    opacity: opacityIn,
                    width: playerWidth,
                    height: playerHeight,
                  }}
                />
              )}
            </AbsoluteFill>
          </Series.Sequence>
        );
      default:
        return <></>;
    }
  };

  const videoCanvases = compositions.map((composition, index): JSX.Element => {
    if (composition.duration * fps <= 0) return <></>;
    const actualDuration = compositions
      .slice(0, index + 1)
      .reduce((acc, comp) => acc + (comp.duration ?? 5), 0);
    return getSerieByCompositionType(composition, index, actualDuration * fps);
  });

  return (
    <AbsoluteFill>
      <Series>{videoCanvases}</Series>
    </AbsoluteFill>
  );
};
