import { Button, Drawer, Space, message } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAssetBatchesValueMappingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesValueMappingContext";
import { FiltersType } from "shared/components/TableFiltersSection";
import { useFetchTemplates } from "shared/hooks/useFetchTemplates";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import styles from "./TemplateDrawer.module.scss";
import Header from "./templateDrawer/Header";
import TemplateList from "./templateDrawer/TemplateList";
import { useAssetBatchesContext } from "../../../shared/contexts/AssetBatchesContext";
import { getFilterOptions } from "./templateDrawer/TemplateList.utils";
import { isTemplateComposition } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext.utils";
import { formatDimensions } from "utils/helpers";

type TemplateDrawerProps = {
  open?: boolean;
  onClose?: () => void;
};

export const TemplateDrawer = ({ open, onClose }: TemplateDrawerProps) => {
  const { templates: orgTemplates, isFetching } = useFetchTemplates({});
  const templates = useMemo(
    () =>
      orgTemplates.filter(
        template => template.type !== "html" || !!template.htmlFileUrl,
      ),
    [orgTemplates],
  );

  const filterOptions = useMemo(() => getFilterOptions(templates), [templates]);
  const {
    selectedTemplate,
    compositions,
    setShowMediaAlert,
    setShowTextAlert,
    setCompositionToReplace,
    setSelectedTemplate,
    artboard,
    defaultTemplateFilters,
  } = useAssetBatchesContext();
  const { saveTemplate } = useAssetBatchesValueMappingContext();

  const templateCompositions = useMemo(
    () => compositions.filter(isTemplateComposition),
    [compositions],
  );

  const [filters, setFilters] = useState<Partial<FiltersType>>({
    ...defaultTemplateFilters,
    status: ["PUBLISHED"],
    stamps: [0],
  });

  useEffect(() => {
    message.config({ maxCount: 10 });
  }, []);

  const setTypeFilter = useCallback(() => {
    if (selectedTemplate && selectedTemplate.type !== "html") {
      setFilters({
        ...filters,
        ...(artboard && { dimension: [formatDimensions(artboard)] }),
        type: filterOptions?.type?.filter(option => option !== "html"),
      });
    }
  }, [filters, filterOptions, selectedTemplate, artboard]);

  const handleClose = useCallback(() => {
    if (videoCompositionEnabled) {
      setCompositionToReplace(undefined);
      setTypeFilter();
      setSelectedTemplate(undefined);
    }
    onClose?.();
  }, [onClose, setTypeFilter, setCompositionToReplace, setSelectedTemplate]);

  return (
    <Drawer
      closable={false}
      destroyOnClose
      className={styles.drawer}
      placement="right"
      visible={open}
      width="calc(100vw - 48px)"
      bodyStyle={{ backgroundColor: "#f5f5f5", padding: 0 }}
      title={<Header />}
      footer={
        <Space className={styles.drawerFooter}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => {
              if (!selectedTemplate) return;
              setShowMediaAlert(true);
              setShowTextAlert(true);
              saveTemplate(selectedTemplate);

              if (
                selectedTemplate.type !== "html" &&
                videoCompositionEnabled &&
                templateCompositions.length <= 1
              ) {
                setTypeFilter();
              }

              onClose?.();
            }}
            data-cy="feed-next-btn"
            disabled={!selectedTemplate}
          >
            {"Add selected file"}
          </Button>
        </Space>
      }
    >
      <div style={{ width: "100%", height: "100%" }}>
        <TemplateList
          filters={filters}
          setFilters={setFilters}
          filterOptions={filterOptions}
          isFetching={isFetching}
          templates={templates}
        />
      </div>
    </Drawer>
  );
};
