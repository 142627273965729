import { Button, Drawer, Input, Select } from "antd";
import styles from "./Conditional.module.scss";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import uuid from "uuid";
import { ReactComponent as Edit } from "statics/images/Edit_pen.svg";
import {
  Condition,
  ConditionalField,
  ConditionalGroupLayer,
  ConditionalSet,
  Op,
} from "shared/types/salesEnablement";

const ops: Op[] = ["is", "is not", "contains", "does not contain"];
const defaultConditionalSet = {
  fields: [
    {
      id: uuid.v1(),
      conditions: [
        {
          id: uuid.v1(),
        },
      ],
    },
  ],
};

type Props = {
  layer: ConditionalGroupLayer;
  onComplete: (conditionalSet: ConditionalSet) => void;
};
const Conditional = (props: Props) => {
  const { layer, onComplete } = props;
  const [conditionalSet, setConditionalSet] = useState<ConditionalSet>();

  const onAddField = () => {
    setConditionalSet(prevSet => {
      if (!prevSet) return;

      const newField = {
        id: uuid.v1(),
        conditions: [
          {
            id: uuid.v1(),
          },
        ],
      };
      return {
        ...prevSet,
        fields: [...prevSet.fields, newField],
      };
    });
  };

  const onAddCondition = (field: ConditionalField) => {
    setConditionalSet(prevSet => {
      if (!prevSet) return;

      const { fields } = prevSet;
      const updatedFields = fields.map(f => {
        if (f.id !== field.id) return f;

        const { conditions } = f;
        const updatedConditions = [
          ...conditions,
          {
            id: uuid.v1(),
          },
        ];

        return {
          ...f,
          conditions: updatedConditions,
        };
      });
      return {
        ...prevSet,
        fields: updatedFields,
      };
    });
  };
  const onDeleteCondition = (field: ConditionalField, condition: Condition) => {
    setConditionalSet(prevSet => {
      if (!prevSet) return;

      const { fields } = prevSet;
      return {
        ...prevSet,
        fields: fields.map(f => {
          if (f.id !== field.id) return f;
          return {
            ...f,
            conditions: f.conditions.filter(c => c.id !== condition.id),
          };
        }),
      };
    });
  };

  const [open, setOpen] = useState(false);

  const onSelect = (
    fieldId: string,
    conditionId: string,
    field: Extract<keyof Condition, "if" | "op" | "value">,
    value: string,
  ) => {
    setConditionalSet(prevSet => {
      if (!prevSet) return;

      const { fields } = prevSet;
      const updatedFields = fields.map(f => {
        if (f.id !== fieldId) return f;

        const { conditions } = f;
        const updatedConditions = conditions.map(c => {
          if (c.id !== conditionId) return c;
          return {
            ...c,
            [field]: value,
          };
        });

        return {
          ...f,
          conditions: updatedConditions,
        };
      });

      return {
        ...prevSet,
        fields: updatedFields,
      };
    });
  };

  const onChangeThen = (fieldId: string, value: string) => {
    setConditionalSet(prevSet => {
      if (!prevSet) return;

      const { fields } = prevSet;
      const updatedFields = fields.map(f => {
        if (f.id !== fieldId) return f;
        return {
          ...f,
          then: value,
        };
      });

      return {
        ...prevSet,
        fields: updatedFields,
      };
    });
  };
  const valid = (conditionalSet?: ConditionalSet) => !!conditionalSet;
  const onSave = () => {
    if (!valid(conditionalSet)) return;

    onComplete(conditionalSet!);

    setOpen(false);
    setConditionalSet(defaultConditionalSet);
  };

  return (
    <div>
      {(layer.conditionalSet?.fields.length ?? 0) > 0 && (
        <>
          <div className={styles.gray}>
            One of the options below will be automatically applied once the
            conditions are met:
          </div>
          <div>
            <ol>
              {layer.conditionalSet?.fields.map(field => {
                return (
                  <li
                    key={`field-list-${field.id}`}
                    className={field.then ? "" : styles.gray}
                  >
                    {field.then}
                  </li>
                );
              })}

              <li className={layer.conditionalSet.else ? "" : styles.gray}>
                {!layer.conditionalSet.else
                  ? "Blank"
                  : layer.conditionalSet.else}
              </li>
            </ol>
          </div>
        </>
      )}
      <Button
        className={styles.edit}
        type="link"
        icon={<Edit className={styles.editPen} />}
        onClick={() => {
          setConditionalSet(layer.conditionalSet ?? defaultConditionalSet);
          setOpen(true);
        }}
      >
        Edit
      </Button>

      <Drawer
        width={"80%"}
        title="Edit Conditions"
        visible={open}
        onClose={() => setOpen(false)}
        footer={
          <div className={styles.footer}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="primary" onClick={onSave}>
              Save
            </Button>
          </div>
        }
      >
        <div className={styles.layerName}>
          <ThunderboltOutlined />
          {layer.name}
        </div>

        <div className={styles.container}>
          {conditionalSet?.fields.map(field => (
            <div key={field.id} className={styles.field}>
              {field.conditions.map((cond, idx) => {
                const isFirstCondition = idx === 0;
                const isLastCondition = idx === field.conditions.length - 1;

                return (
                  <div key={cond.id} className={styles.condition}>
                    <div className={styles.label}>
                      {idx === 0 ? "If" : "And"}
                    </div>
                    <Select
                      placeholder="Field"
                      value={cond.if}
                      onSelect={val => onSelect(field.id, cond.id, "if", val)}
                    >
                      <Select.Option value="state">State</Select.Option>
                      <Select.Option value="product">Product</Select.Option>
                    </Select>
                    <Select
                      className={styles.op}
                      placeholder="Equality"
                      value={cond.op}
                      onSelect={val => onSelect(field.id, cond.id, "op", val)}
                    >
                      {ops.map(op => (
                        <Select.Option key={op} value={op}>
                          {op}
                        </Select.Option>
                      ))}
                    </Select>
                    <Select
                      placeholder="Value"
                      value={cond.value}
                      onSelect={val =>
                        onSelect(field.id, cond.id, "value", val)
                      }
                    >
                      <Select.Option value="state">State</Select.Option>
                      <Select.Option value="product">Product</Select.Option>
                    </Select>
                    <div className={styles.actions}>
                      {!isFirstCondition && (
                        <Button
                          icon={<DeleteOutlined />}
                          type="text"
                          onClick={() => onDeleteCondition(field, cond)}
                        />
                      )}

                      {isLastCondition && (
                        <Button
                          icon={<PlusCircleOutlined />}
                          type="text"
                          onClick={() => onAddCondition(field)}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              <div className={styles.show}>
                <div className={styles.label}>Show</div>
                <Input.TextArea
                  value={field.then}
                  onChange={evt => onChangeThen(field.id, evt.target.value)}
                />
              </div>
            </div>
          ))}
          <Button
            className={styles.addCondition}
            type="link"
            icon={<PlusOutlined />}
            onClick={onAddField}
          >
            Add Condition
          </Button>
          <div className={styles.else}>
            <div className={styles.label}>Else</div>
            <Input.TextArea
              onChange={evt => {
                setConditionalSet(prevSet => {
                  if (!prevSet) return;

                  return {
                    ...prevSet,
                    else: evt.target.value,
                  };
                });
              }}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Conditional;
