import { Upload, Space, Typography, Tooltip, Progress } from "antd";
import { memo, useState } from "react";
import { AdMedia, MultiUploadSizing } from "shared/types/uploadManagement";
import { uploadMediaV2 } from "utils/uploadMedia";
import { UploadFileStatus } from "antd/lib/upload/interface";
import VisualMedia from "screens/adLibrary/shared/VisualMedia";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getBase64DataUrl, generateGuidFilename } from "utils/helpers";
import styles from "./MultiFileUploadDragger.module.scss";
import { isFileSizeValid } from "screens/adLibrary/utils";
import produce from "immer";

interface IProps {
  multiFileUrls: AdMedia[];
  setMultiFileUrls(newFileUrls: AdMedia[]): void;
  placementSizes: MultiUploadSizing[];
}

const SizingInfo: Record<
  MultiUploadSizing,
  { label: string; tooltip: string }
> = {
  IN_FEED: { label: "In feed (1:1)", tooltip: "In-Feed media size: 1080x1080" },
  STORY: { label: "Story (9:16)", tooltip: "In-Feed media size: 1080x1920" },
};

type Props = {
  file: AdMedia | { size: MultiUploadSizing };
  setFile(adMedia: AdMedia): void;
  uploadStatus: UploadFileStatus | "default";
  setUploadStatus(uploadStatus: UploadFileStatus): void;
};

const FileUploadDragger = ({
  file,
  setFile,
  uploadStatus,
  setUploadStatus,
}: Props) => {
  const handleFileSizeValidation = (fileBlob: Blob) => {
    const shouldUploadFile = isFileSizeValid(fileBlob);

    if (!shouldUploadFile) {
      setUploadStatus("error");
    }

    return shouldUploadFile;
  };

  return (
    <>
      <Upload.Dragger
        className="ad-carousel-card-upload"
        data-cy="ad-media-upload"
        multiple={false}
        accept="image/*, video/*"
        showUploadList={false}
        beforeUpload={handleFileSizeValidation}
        customRequest={async ({ file: resultFile, onSuccess, onError }) => {
          setUploadStatus("uploading");
          const assetFile = resultFile as File;
          const imageDataUrl = await getBase64DataUrl(assetFile);

          const mediaToUpload = {
            file: imageDataUrl,
            filename: generateGuidFilename(assetFile.name),
            size: assetFile.size,
            type: assetFile.type,
            mediaSizing: file.size,
          };

          try {
            const assetData = await uploadMediaV2(mediaToUpload, "card-asset");
            onSuccess?.(assetData, new XMLHttpRequest());
            setFile(assetData);

            setUploadStatus("done");
          } catch (error) {
            onError?.(error as Error);
            setUploadStatus("error");
          }
        }}
      >
        <div className={styles.placeholder}>
          {uploadStatus == "done" && "filetype" in file && (
            <VisualMedia
              thumbnailUrl={file?.thumbnail}
              videoUrl={file.filetype == "video" ? file.videoUrl : ""}
              placeholder={file.filetype}
              imageMinHeight={120}
              style={{ maxWidth: 120, maxHeight: 120, minHeight: 120 }}
            />
          )}
          {uploadStatus == "uploading" && (
            <>
              <Typography.Text>Uploading</Typography.Text>
              <Progress
                style={{ width: "80%" }}
                percent={100}
                strokeColor="1890FF"
                showInfo={false}
                status="active"
              />
            </>
          )}
          {uploadStatus == "default" && (
            <>
              <PlusOutlined className={styles.plusIcon} color={"black"} />
              <Typography.Text>Upload</Typography.Text>
            </>
          )}
        </div>
      </Upload.Dragger>
    </>
  );
};

const MultiFileUploadDragger = ({
  multiFileUrls,
  setMultiFileUrls,
  placementSizes,
}: IProps) => {
  const [uploadStatus, setUploadStatus] = useState<
    (UploadFileStatus | "default")[]
  >(placementSizes.map((_, ix) => (multiFileUrls[ix] ? "done" : "default")));

  const getFile = (
    size: MultiUploadSizing,
  ): AdMedia | { size: MultiUploadSizing } => {
    return (
      multiFileUrls.find(x => x.size === size) || {
        size,
      }
    );
  };

  return (
    <Space align="end">
      {placementSizes.map((size, ix) => {
        const file = getFile(size);

        return (
          <div key={size} className={styles.placementSizesContainer}>
            <Typography.Text className={styles.label}>
              {SizingInfo[size].label}
              <Tooltip title={SizingInfo[size].tooltip}>
                <InfoCircleOutlined className={styles.infoIcon} />
              </Tooltip>
            </Typography.Text>
            <FileUploadDragger
              key={size}
              file={file}
              setFile={newFile => {
                const res = produce(multiFileUrls, old => {
                  old[ix] = newFile;
                });
                setMultiFileUrls(res);
              }}
              uploadStatus={uploadStatus[ix]}
              setUploadStatus={status => {
                setUploadStatus(
                  produce(old => {
                    old[ix] = status;
                  }),
                );
              }}
            />
          </div>
        );
      })}
    </Space>
  );
};

export default memo(MultiFileUploadDragger);
