import { useCallback, useMemo, useState } from "react";
import { Collapse, Spin } from "antd";
import { orderBy } from "lodash";
import { BeforeCapture, DropResult } from "react-beautiful-dnd";
import { DraggableList } from "shared/components/draggableList/DraggableList";
import { TComposition } from "shared/types/assetExporter";
import { HolderOutlined, RightOutlined } from "@ant-design/icons";
import { ImageVariableInput } from "../../assetBatchDrawer/panelVariables/ImageVariableInput";
import { LogoVariableInput } from "../../assetBatchDrawer/panelVariables/LogoVariableInput";
import { MediaFromFeedInput } from "../../assetBatchDrawer/panelVariables/MediaFromFeedInput";
import { TextVariableInput } from "../../assetBatchDrawer/panelVariables/TextVariableInput";
import { getValueMappingKey } from "../../assetBatchDrawer/utils";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import {
  isMediaCompositionFromCAM,
  isMediaCompositionFromFeed,
  isTemplateComposition,
} from "../../shared/contexts/AssetBatchesContext.utils";
import { AssetBatchesTemplateCompositionProvider } from "../../shared/contexts/AssetBatchesTemplateCompositionContext";
import { mediaTypes } from "../../shared/types";
import { MediaFromCamInput } from "../../assetBatchDrawer/panelVariables/MediaFromCamInput";
import styles from "./PanelVariables.module.scss";
import { AddMediaDropdown } from "./panelVariables/AddMediaDropdown";
import { VariableCollapseHeader } from "./panelVariables/VariableCollapseHeader";
import { TotalDuration } from "./panelVariables/TotalDuration";

export const PanelVariables = () => {
  const [hoveredPanel, setHoveredPanel] = useState<string | undefined>();

  const {
    variables: allVariables,
    compositions,
    isFetchingMediaCols,
    currentCompositionId,
    setCurrentCompositionId,
    setCompositions,
  } = useAssetBatchesContext();

  const totalDuration = useMemo(() => {
    return compositions.reduce(
      (total, composition) => total + composition.duration,
      0,
    );
  }, [compositions]);

  const getSortedVariables = useCallback(
    (composition: TComposition) => {
      const variables = allVariables.find(
        vars => vars.compositionId === composition.compositionId,
      )?.variables;
      if (!variables) return [];
      return [
        ...variables.filter(
          variable => variable.variable === "Theme Background",
        ),
        ...orderBy(
          variables,
          variable =>
            `${variable.type}-${variable.variable}-${variable.logoData?.logoType}`,
          ["asc"],
        ).filter(
          variable =>
            mediaTypes.includes(variable.type) &&
            variable.variable !== "Theme Background",
        ),
      ];
    },
    [allVariables],
  );

  const getSortedTextVariables = useCallback(
    (composition: TComposition) => {
      const variables = allVariables.find(
        vars => vars.compositionId === composition.compositionId,
      )?.variables;
      return orderBy(variables, variable => variable.variable, ["asc"]).filter(
        variable => variable.type === "text",
      );
    },
    [allVariables],
  );

  const customHeader = (composition: TComposition, isActive: boolean) => (
    <div
      className={styles.customPanelHeader}
      onMouseEnter={() => setHoveredPanel(composition.compositionId)}
      onMouseLeave={() => setHoveredPanel(undefined)}
    >
      {hoveredPanel === composition.compositionId && (
        <HolderOutlined className={styles.holderIcon} />
      )}
      <RightOutlined
        rotate={isActive ? 90 : 0}
        className={styles.rightArrow}
        width="10px"
        height="10px"
      />
      <VariableCollapseHeader composition={composition} />
    </div>
  );

  const handleOnChange = (compositionId: string) => {
    if (currentCompositionId === compositionId)
      setCurrentCompositionId(undefined);
    else setCurrentCompositionId(compositionId);
  };

  const activeKey = [currentCompositionId ?? ""];

  const createDisplayVariables = (compositions: TComposition[]) => {
    return compositions.map(composition => {
      if (isTemplateComposition(composition))
        return (
          <AssetBatchesTemplateCompositionProvider
            key={composition.compositionId}
            editingComposition={composition}
          >
            <Collapse
              activeKey={activeKey}
              onChange={() => handleOnChange(composition.compositionId)}
              bordered={false}
              className={styles.collapseContainer}
            >
              <Collapse.Panel
                key={composition.compositionId}
                header={customHeader(
                  composition,
                  currentCompositionId === composition.compositionId,
                )}
                showArrow={false}
              >
                {getSortedVariables(composition).map(variable =>
                  variable.type === "logo" ? (
                    <LogoVariableInput
                      key={`${composition.compositionId}-${variable.id}-${variable.variable}`}
                      variable={variable}
                      valueMapping={
                        composition.variables[getValueMappingKey(variable)]
                      }
                      mappingKey={getValueMappingKey(variable)}
                    />
                  ) : (
                    <ImageVariableInput
                      key={`${composition.compositionId}-${variable.id}-${variable.variable}`}
                      compositionId={composition.compositionId}
                      variable={variable}
                      valueMapping={
                        composition.variables[getValueMappingKey(variable)]
                      }
                      mappingKey={getValueMappingKey(variable)}
                    />
                  ),
                )}

                {getSortedTextVariables(composition).map((variable, idx) => {
                  const mappingKey = getValueMappingKey(variable);

                  return (
                    <TextVariableInput
                      key={`${composition.compositionId}-${variable.id}-${variable.variable}-${idx}`}
                      variable={variable}
                      valueMapping={composition.variables[mappingKey]}
                      mappingKey={mappingKey}
                    />
                  );
                })}
              </Collapse.Panel>
            </Collapse>
          </AssetBatchesTemplateCompositionProvider>
        );

      return (
        <Collapse
          key={composition.compositionId}
          activeKey={activeKey}
          onChange={() => handleOnChange(composition.compositionId)}
          bordered={false}
          className={styles.collapseContainer}
        >
          <Collapse.Panel
            key={composition.compositionId}
            header={customHeader(
              composition,
              currentCompositionId === composition.compositionId,
            )}
            showArrow={false}
          >
            {isMediaCompositionFromFeed(composition) && (
              <MediaFromFeedInput
                key={`${composition.compositionId}-feed`}
                compositionId={composition.compositionId}
              />
            )}
            {isMediaCompositionFromCAM(composition) && (
              <MediaFromCamInput composition={composition} />
            )}
          </Collapse.Panel>
        </Collapse>
      );
    });
  };

  const handleOnBeforeCapture = (item: BeforeCapture) => {
    const { draggableId } = item;
    if (currentCompositionId !== draggableId) {
      setCurrentCompositionId(undefined);
    }
  };
  const handleDragEnd = (droppedItem: DropResult) => {
    setCurrentCompositionId(
      compositions[droppedItem?.destination?.index ?? 0].compositionId,
    );
  };

  return (
    <div className={styles.compVariablesContainer}>
      <div className={styles.dropdownContainer}>
        <AddMediaDropdown />
      </div>
      <div>
        {isFetchingMediaCols && <Spin />}
        {!isFetchingMediaCols && (
          <DraggableList
            listItems={createDisplayVariables(compositions)}
            originalListItems={compositions}
            updateOriginalListItems={setCompositions}
            handleOnBeforeCapture={handleOnBeforeCapture}
            handleDragEnd={handleDragEnd}
          />
        )}
      </div>
      {compositions.length > 0 && <TotalDuration duration={totalDuration} />}
    </div>
  );
};
