import { StoreType } from "screens/designStudio/designStudioV2/types";
import { useLayers } from "./layers/useLayers";
import { LayerItem } from "./layers/LayerItem";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { observer } from "mobx-react-lite";
import { Search } from "@mui/icons-material";

type LayerProps = {
  store: StoreType;
};

export const Layers = observer(({ store }: LayerProps) => {
  const {
    layers,
    selectedLayerId,
    onDoubleClick,
    onClick,
    onKeyDown,
    onClickOutside,
    onDragEnd,
  } = useLayers(store);

  return (
    <Grid container gap={3} direction="column" flex={1}>
      <TextField
        label="Search"
        size="small"
        sx={{ width: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {!!layers.length && (
        <Typography>Elements on your active page:</Typography>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => {
            return (
              <Grid
                container
                gap={1}
                direction="column"
                flex={1}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {layers.map((layer, idx) => (
                  <Draggable key={layer.id} draggableId={layer.id} index={idx}>
                    {draggableProvided => {
                      return (
                        <LayerItem
                          draggableProvided={draggableProvided}
                          key={layer.id}
                          layer={layer}
                          isSelected={layer.id === selectedLayerId}
                          onDoubleClick={onDoubleClick}
                          onClick={onClick}
                          onClickOutside={onClickOutside}
                          onKeyDown={onKeyDown}
                        />
                      );
                    }}
                  </Draggable>
                ))}
              </Grid>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
});
