import { useCallback, useRef } from "react";
import { getVideoData } from "../components/Preview/utils";

interface Props {
  playing: boolean;
  canvas: fabric.Canvas | undefined;
  currentFrame: number;
  fps: number;
}

export const useVideoPlayer = ({
  playing,
  canvas,
  currentFrame,
  fps,
}: Props) => {
  const animFrameRequest = useRef<number>(0);
  const playStatus = useRef(false);

  const updateVideoElements = useCallback(
    (isPlaying: boolean) => {
      if (!canvas) return;
      const videos = getVideoData(canvas);
      videos.forEach(element => {
        const video = element.getElement() as HTMLVideoElement;
        if (isPlaying) {
          video.play();
        } else {
          video.pause();
          video.currentTime = currentFrame / fps;
        }
      });
    },
    [canvas, currentFrame, fps],
  );

  const playFrames = useCallback(() => {
    if (!playStatus.current || !canvas) return;
    const videos = getVideoData(canvas);
    if (!videos) {
      return;
    }
    const rendering = videos.map(video => {
      const videoEle = video.getElement() as HTMLVideoElement;
      const maxTime = videoEle.duration;
      if (videoEle.currentTime >= maxTime - 0.1 || !playing) {
        return false;
      }
      canvas.requestRenderAll();
      video.videoTime = videoEle.currentTime;
      return true;
    });
    if (rendering.filter(Boolean).length !== 0) {
      requestAnimationFrame(() => {
        playFrames();
      });
      return;
    }
    cancelAnimationFrame(animFrameRequest.current);
  }, [canvas, playing]);

  const togglePlaying = useCallback(
    (isPlaying: boolean) => {
      updateVideoElements(isPlaying);
      playStatus.current = isPlaying;

      if (isPlaying) {
        animFrameRequest.current = requestAnimationFrame(() => {
          playFrames();
        });
        return;
      }
      cancelAnimationFrame(animFrameRequest.current);
    },
    [playFrames, updateVideoElements],
  );

  return { togglePlaying };
};
