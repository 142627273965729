import { Input, Form, Select } from "antd";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { callToActionValues, dynamicAdList } from "screens/adLibrary/constants";
import EditableAdToLoadUrlText from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/adsToLoadTable/EditableAdToLoadUrlText";
import { areFieldsRequired as required } from "screens/adLibrary/adWizard.utils";
import { useStoreLabels } from "shared/hooks/useStoreLabels";
import { formatOption } from "utils/helpers";
import { useDestinationURLValidator } from "screens/adLibrary/shared/hooks/useDestinationURLValidator";
import { Rule } from "antd/lib/form";
import MultiFileUploadDragger from "../shared/MultiFileUploadDragger";
import { AdMedia } from "shared/types/uploadManagement";

interface IProps {
  adType: AdType;
  multiFileUrls: AdMedia[];
  setMultiFileUrls(newFileUrls: AdMedia[]): void;
  shouldDisplayErrors: boolean;
}

export const AdStillOrVideoForm = ({
  adType,
  multiFileUrls,
  setMultiFileUrls,
  shouldDisplayErrors,
}: IProps) => {
  const { allStoreLabels } = useStoreLabels();
  const hasThumbnailError =
    shouldDisplayErrors && !multiFileUrls.every(file => file.thumbnail);
  const { isValidDestinationURL } = useDestinationURLValidator();

  const validateDestinationURL = (_: Rule, value: string | undefined) => {
    if (
      isValidDestinationURL(value, {
        optionalProtocol: true,
      })
    ) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Please format url correctly"));
  };

  return (
    <>
      {!dynamicAdList.includes(adType) && (
        <>
          <Form.Item
            validateStatus={hasThumbnailError ? "error" : "success"}
            help={hasThumbnailError && "Please add an asset"}
            label="Still or Video"
            style={{ width: 200 }}
            required={required}
          >
            <MultiFileUploadDragger
              multiFileUrls={multiFileUrls}
              setMultiFileUrls={setMultiFileUrls}
              placementSizes={["IN_FEED", "STORY"]}
            />
          </Form.Item>
          <Form.Item
            name={["visuals", "headline"]}
            label="Headline"
            rules={[
              {
                required:
                  required && ![AdType.Video, AdType.Still].includes(adType),
                message: "Please add a headline",
                whitespace: true,
              },
            ]}
          >
            <Input data-cy="headline-input" />
          </Form.Item>
          <Form.Item
            name={["visuals", "headlineDescription"]}
            data-cy="headline-description-input"
            label="Description"
            rules={[
              {
                required: false,
                message: "Please add a description",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      )}
      {[
        AdType.Still,
        AdType.Video,
        AdType.AIA,
        AdType.DPA,
        AdType.FTA,
      ].includes(adType) && (
        <Form.Item
          name={["visuals", "ctaButtonText"]}
          required={required}
          label="CTA Button"
          rules={[
            {
              required,
              message: "Please select a CTA button text",
              whitespace: true,
            },
          ]}
        >
          <Select data-cy="cta-button-select">
            {callToActionValues.map(({ key, text, value }) => (
              <Select.Option
                key={key}
                value={value}
                data-cy={`option-${formatOption(text)}`}
              >
                {text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {(adType === AdType.Video || adType === AdType.Still) && (
        <Form.Item
          name={["visuals", "displayUrl"]}
          label="Display Url"
          valuePropName="linkValue"
          tooltip={{
            title: "Display URLs should not include / or http(s)",
            placement: "right",
          }}
          data-cy="display-url-item"
          rules={[
            {
              validator: validateDestinationURL,
            },
          ]}
        >
          <EditableAdToLoadUrlText
            showInputOnly
            urlLabels={allStoreLabels}
            disableLabelPopulation
            willCheckFullUrl={false}
          />
        </Form.Item>
      )}
    </>
  );
};
