import { useEffect, useRef } from "react";
import { Grid, Typography, Link } from "@mui/material";
import { StoreType } from "screens/designStudio/designStudioV2/types";
import { CAMSelect } from "shared/components/media";
import { useElements } from "./useElements";

type MediaProps = {
  store: StoreType;
};

export const Media = ({ store }: MediaProps) => {
  const { addImage } = useElements(store);
  const linkRef = useRef<HTMLButtonElement>(null);

  // Temporarily simulating a user click on component mount to
  // auto-trigger CAMSelect modal opening.
  // NOTE: This is a temp fix and should be replaced with
  // a more direct approach for opening modal in the future
  useEffect(() => {
    linkRef.current?.click();
  }, []);

  return (
    <Grid container gap={3} direction="column" flex={1}>
      <Typography>
        See more on{" "}
        <CAMSelect
          maxSelected={1}
          title="Select or upload"
          fileTypes={["png", "jpeg", "jpg"]}
          layout="grid"
          onClose={async (assets: WDAsset[]) => {
            const asset = assets[0];
            addImage({
              src: asset.hiResURLRaw,
              width: Number(asset.width),
              height: Number(asset.height),
              name: asset.name,
            });
          }}
        >
          <Link component="button" ref={linkRef}>
            Media Gallery
          </Link>
        </CAMSelect>
      </Typography>
    </Grid>
  );
};
