import {
  GroupLayer,
  SmartVariable,
  SmartVariableOptions,
} from "shared/types/salesEnablement";
import styles from "./GroupSelect.module.scss";
import { OnLayerChangeFunc } from "../../Layers";
import { Select } from "antd";
import { useMemo } from "react";
import { parseGroupTitle, titleToSmartVar } from "../SmartLayer.utils";

type Props = {
  layer: GroupLayer;
  onChange?: OnLayerChangeFunc;
  groupLayers?: GroupLayer[];
};
const GroupSelect = (props: Props) => {
  const { layer, onChange, groupLayers } = props;
  const groupLayerOptions = useMemo(
    () =>
      groupLayers?.reduce((acc, l) => {
        if (l.id === layer.id) return acc;

        acc[l.id] = `${SmartVariableOptions[l.smartVariable!]} (group with ${
          l.name
        })`;
        return acc;
      }, {} as Record<string, string>) ?? {},
    [groupLayers, layer],
  );
  const options: Record<string, string> = useMemo(
    () => ({
      ...SmartVariableOptions,
      ...groupLayerOptions,
    }),
    [groupLayerOptions],
  );
  return (
    <div className={styles.selection}>
      <Select<string>
        value={layer.smartVariable}
        onSelect={value => {
          const groupLayer = groupLayers?.find(l => l.id === value);
          if (groupLayer) {
            // user selecting other non-group row and trying to add to a group
            onChange?.(
              {
                ...groupLayer,
              },
              {
                type: "group_add",
                data: { layerId: layer.id, replace: true, value },
              },
            );
          } else {
            // find name index
            //  ex) Product 1, Prodct 2, etc...
            const idxArray = Object.keys(options)
              .filter(key => key in groupLayerOptions)
              .map(groupLayerKey => {
                const { title, idx } = parseGroupTitle(
                  groupLayerKey,
                  groupLayerOptions,
                );
                const groupSmtVar = titleToSmartVar(title);
                if (groupSmtVar !== value) return 0;

                return idx;
              });
            const idx = Math.max(...idxArray, 0) + 1;

            onChange?.(
              {
                ...layer,
                name: `${options[value]} ${idx}`,
                smartVariable: value as SmartVariable, // this "value" must be one of the SmartVariable
              },
              {
                type: "group_add",
                data: { layerId: layer.id, replace: false, value },
              },
            );
          }
        }}
      >
        {Object.keys(options).map(key => (
          <Select.Option key={key} value={key}>
            {options[key]}
          </Select.Option>
        ))}
      </Select>

      {(layer.smartVariable === "product" || layer.smartVariable === "cta") && (
        <Select<string>>
          <Select.Option key={"opt"} value={"opt"}>
            opt
          </Select.Option>
        </Select>
      )}
    </div>
  );
};

export default GroupSelect;
