import {
  Row,
  Col,
  Tree,
  Divider,
  Typography,
  Radio,
  Space,
  DatePicker,
} from "antd";
import type { DatePickerProps, RadioGroupProps } from "antd";
import { TreeProps } from "antd/lib/tree";
import styles from "../FlipperDrawer.module.scss";
import RequiredMark from "shared/components/RequiredMark";
import Placeholder from "shared/components/Placeholder";
import { useState } from "react";
import { DataNode } from "antd/lib/tree";

const Select = ({
  storeName,
  treeData,
  flipWithAds,
  setFlipWithAds,
  checkedKeys,
  setCheckedKeys,
  setTargetDate,
}: {
  storeName: string;
  treeData: DataNode[];
  flipWithAds: boolean;
  setFlipWithAds: (flipWithAds: boolean) => void;
  checkedKeys: {
    checked: React.Key[];
    halfChecked: React.Key[];
  };
  setCheckedKeys: (checkedKeys: {
    checked: React.Key[];
    halfChecked: React.Key[];
  }) => void;
  setTargetDate: (targetDate: string) => void;
}) => {
  const [picker, setPicker] = useState<"month" | "quarter">("month");
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>();
  const onDatePick: DatePickerProps["onChange"] = (date, dateString) => {
    setSelectedDate(date);
    setTargetDate(dateString);
  };
  const onPickerChange: RadioGroupProps["onChange"] = e => {
    setPicker(e.target.value);
    setSelectedDate(null);
    setTargetDate("");
  };

  return (
    <Row className={styles.container}>
      <Col className={styles.column} span={16}>
        <Typography.Title level={5}>Source Campaigns</Typography.Title>
        <Typography.Text strong>{storeName}</Typography.Text>
        <Tree
          treeData={treeData}
          onCheck={setCheckedKeys as TreeProps["onCheck"]}
          checkStrictly
          checkedKeys={checkedKeys}
          checkable
          defaultExpandAll
          height={800}
        />
      </Col>
      <Divider className={styles.divider} type="vertical" />
      <Col className={styles.column} span={6}>
        <Typography.Title level={5}>Destination Campaigns</Typography.Title>
        <Typography.Text>Date range</Typography.Text>
        <Radio.Group onChange={onPickerChange} value={picker}>
          <Space direction="vertical">
            <Radio value={"month"}>Monthly</Radio>
            <Radio value={"quarter"}>Quarterly</Radio>
          </Space>
        </Radio.Group>
        <Placeholder />
        <Typography.Text>
          Change to <RequiredMark />
        </Typography.Text>
        <DatePicker
          value={selectedDate}
          onChange={onDatePick}
          picker={picker}
          style={{ width: "60%" }}
        />
        <Placeholder />
        <Typography.Text>Duplicate</Typography.Text>
        <Radio.Group
          onChange={e => setFlipWithAds(e.target.value)}
          value={flipWithAds}
        >
          <Space direction="vertical">
            <Radio value={true}>Campaigns, Ad Sets & Ads</Radio>
            <Radio value={false}>Campaigns & Ad Sets only</Radio>
          </Space>
        </Radio.Group>
      </Col>
    </Row>
  );
};

export default Select;
