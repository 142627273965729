import { useCallback, useRef, memo, ReactElement, Dispatch } from "react";
import { Select, Typography, Col, Form, Input } from "antd";
import { AdType } from "../facebookUtils/types";
import AdCarouselForm, {
  SLIDES_TO_SHOW,
} from "../adWizard/AdViewEdit/AdCarouselForm";
import AdDesign from "../adWizard/AdViewEdit/AdDesign";
import AdDesignAIA from "../adWizard/AdViewEdit/AdDesignAIA";
import { AdStillOrVideoForm } from "../adWizard/AdViewEdit/AdStillOrVideoForm";
import { AdAIAForm } from "../adWizard/AdViewEdit/AdAIAForm";
import { CarouselRef } from "antd/lib/carousel";
import { IndustryType } from "shared/types/shared";
import { ClientType, ICard, IAd } from "shared/types/adLibrary";
import { industryType, isEnvVarEquals } from "utils/helpers";
import { getAdFormatTypes } from "../utils";
import { formatOption } from "utils/helpers";
import { dynamicAdList } from "../constants";
import { areFieldsRequired as required, defaultCard } from "../adWizard.utils";
import { AdMedia } from "shared/types/uploadManagement";

interface IProps {
  adEdit?: IAd;
  adType: AdType;
  multiFileUrls: AdMedia[];
  shouldDisplayErrors: boolean;
  setMultiFileUrls: (fileUrls: AdMedia[]) => void;
  setClientType: (clientType: IndustryType) => void;
  cards: ICard[];
  setCards: Dispatch<React.SetStateAction<ICard[]>>;
  setAdType: (adType: AdType) => void;
  setPreviewDisabled: (previewDisabled: boolean) => void;
}

function AdCreate({
  adEdit,
  multiFileUrls,
  setMultiFileUrls,
  shouldDisplayErrors,
  setClientType,
  cards,
  setCards,
  adType,
  setAdType,
  setPreviewDisabled,
}: IProps): ReactElement {
  const carouselRef = useRef<CarouselRef>(null);
  const isCarouselAd = adType === AdType.Carousel;
  const isDynamicAd = dynamicAdList.includes(adType);
  const adFormatTypes = getAdFormatTypes();

  const addCard = useCallback(() => {
    const newCards = cards.concat(defaultCard);
    const slide =
      newCards.length > SLIDES_TO_SHOW
        ? newCards.length - SLIDES_TO_SHOW
        : newCards.length - SLIDES_TO_SHOW - 1;
    carouselRef.current?.goTo(slide);
    setCards(newCards);
  }, [cards, setCards]);

  const patchCard = useCallback(
    (index: number, newValues: Partial<ICard>, updatedCards?: ICard[]) => {
      if (updatedCards?.length) {
        setCards(updatedCards);
      } else if (cards?.[index]) {
        setCards(prevCards => {
          return prevCards.map((card, cardIndex) => {
            if (cardIndex === index) {
              return { ...card, ...newValues };
            }
            return card;
          });
        });
      }
      setPreviewDisabled(false);
    },
    [cards, setCards, setPreviewDisabled],
  );

  const removeCard = useCallback(
    (cardIndex: number) => {
      const newCards = cards.filter((_, index) => index !== cardIndex);
      setCards(newCards);
      setPreviewDisabled(false);
    },
    [cards, setCards, setPreviewDisabled],
  );

  const moveCard = useCallback(
    (index: number, to: "left" | "right") => {
      const indexToSwap = to === "left" ? index - 1 : index + 1;
      const cardsCopy = [...cards];

      cardsCopy[index] = cardsCopy[indexToSwap];
      cardsCopy[indexToSwap] = cards[index];
      setCards(cardsCopy);
      setPreviewDisabled(false);
    },
    [cards, setCards, setPreviewDisabled],
  );

  return (
    <div className="form-column">
      <div className="column-title">
        <Typography.Text>{"Create"}</Typography.Text>
      </div>
      <Form.Item
        name={["inputParameters", "name"]}
        label="Ad Name"
        rules={[{ required, message: "Please add a name" }]}
      >
        <Input data-cy="ad-name-input" />
      </Form.Item>
      <Form.Item
        name="type"
        required
        label="Ad Format"
        rules={[{ required: true, message: "Please select the ad format" }]}
      >
        <Select
          placeholder="Ad Format"
          onChange={setAdType}
          value={adType}
          data-cy="ad-format-select"
        >
          {adFormatTypes?.map(({ key, text, value }) => (
            <Select.Option
              key={key}
              value={value}
              data-cy={`option-${formatOption(text)}`}
            >
              {text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {!isDynamicAd &&
        industryType("auto") &&
        isEnvVarEquals("CLIENT", "internal") && (
          <Col>
            <Form.Item name="industry" label="Industry">
              <Select
                placeholder="Select Industry Type"
                onChange={(value: IndustryType) => setClientType(value)}
                data-cy="industry-select"
              >
                {Object.entries(ClientType).map(([key, value]) => {
                  return (
                    <Select.Option
                      key={key}
                      value={key}
                      data-cy={`option-${value}`}
                    >
                      {value}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        )}
      {isDynamicAd ? (
        <>
          <AdDesignAIA adEdit={adEdit} adType={adType} />
          <AdAIAForm adEdit={adEdit} adType={adType} />
        </>
      ) : (
        <AdDesign
          adEdit={adEdit}
          adType={adType}
          shouldDisplayErrors={shouldDisplayErrors}
        />
      )}
      {isCarouselAd ? (
        <AdCarouselForm
          cards={cards}
          addCard={addCard}
          removeCard={removeCard}
          moveCard={moveCard}
          carouselRef={carouselRef}
          patchCard={patchCard}
          shouldDisplayErrors={shouldDisplayErrors}
        />
      ) : (
        <AdStillOrVideoForm
          adType={adType}
          multiFileUrls={multiFileUrls}
          setMultiFileUrls={setMultiFileUrls}
          shouldDisplayErrors={shouldDisplayErrors}
        />
      )}
    </div>
  );
}

export default memo(AdCreate);
