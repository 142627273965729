import {
  createContext,
  memo,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";

interface ContextProps {
  canvasJsons: any[];
  isVideoPreviewOpen: boolean;
  isPlaying: boolean;
  currentFrame: number;
  setCanvasJsons: React.Dispatch<React.SetStateAction<any[]>>;
  setIsVideoPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentFrame: React.Dispatch<React.SetStateAction<number>>;
}

type ContextProviderProps = {
  children: ReactNode;
};

const Context = createContext<ContextProps>({} as ContextProps);

const ContextProvider = ({ children }: ContextProviderProps) => {
  const [canvasJsons, setCanvasJsons] = useState<any[]>([]);
  const [isVideoPreviewOpen, setIsVideoPreviewOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(0);

  const contextProviderValue = useMemo(
    () => ({
      canvasJsons,
      isVideoPreviewOpen,
      setCanvasJsons,
      setIsVideoPreviewOpen,
      isPlaying,
      setIsPlaying,
      currentFrame,
      setCurrentFrame,
    }),
    [canvasJsons, isVideoPreviewOpen, isPlaying, currentFrame],
  );

  return (
    <Context.Provider value={contextProviderValue}>{children}</Context.Provider>
  );
};

export const VideoStitchingProvider = memo(ContextProvider);

export const useVideoStitchingContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Context must be used within a VideoStitchingProvider");
  }

  return context;
};
