import { Player, PlayerRef } from "@remotion/player";
import { Button } from "antd";
import { useEffect, useRef } from "react";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { useVideoStitchingContext } from "../../shared/contexts/VideoStitchingContext";
import { VideoComposition } from "./VideoComposition";
import styles from "./VideoStitching.module.scss";

interface Props {
  fps?: number;
}

export const VideoStitching = ({ fps = 30 }: Props) => {
  const playerRef = useRef<PlayerRef>(null);
  const { setIsVideoPreviewOpen, setIsPlaying, setCurrentFrame, currentFrame } =
    useVideoStitchingContext();
  const { compositions, artboard } = useAssetBatchesContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const height = containerRef.current?.clientHeight ?? 720;
  const width = Math.round(
    ((artboard?.width ?? 1) / (artboard?.height ?? 1)) * height,
  );

  const duration = compositions
    .map(comp => comp.duration ?? 5)
    .reduce((a, b) => a + b, 0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current) {
        const newFrame = playerRef.current.getCurrentFrame();
        setIsPlaying(newFrame !== currentFrame);
        setCurrentFrame(newFrame);
      }
    }, 1000 / fps);

    return () => clearInterval(interval);
  }, [currentFrame, fps, setCurrentFrame, setIsPlaying]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>
          dimensions: {artboard?.width}x{artboard?.height}
        </span>
        <Button
          className={styles.closeButton}
          onClick={() => {
            setIsPlaying(false);
            setIsVideoPreviewOpen(false);
          }}
        >
          Close Preview
        </Button>
      </div>
      <div ref={containerRef} className={styles.player}>
        <Player
          component={VideoComposition}
          durationInFrames={duration * fps}
          compositionWidth={width}
          compositionHeight={height}
          fps={fps}
          controls
          ref={playerRef}
          inputProps={{
            fps: fps,
            playerWidth: width,
            playerHeight: height,
          }}
        />
      </div>
    </div>
  );
};
