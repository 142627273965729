import { Tabs, Box, Tab, Typography, Grid } from "@mui/material";
import { useEditorTabs, EditorTab } from "./editorTabs/useEditorTabs";
import { observer } from "mobx-react-lite";
import { TabPanel } from "./editorTabs/TabPanel";
import { useEditorDrawerContext } from "../../editorDrawerContext/useEditorDrawerContext";

const a11yProps = (index: EditorTab) => ({
  id: `vertical-tab-${index}`,
  "aria-controls": `vertical-tabpanel-${index}`,
});

export const EditorTabs = observer(() => {
  const { tabs, currentTab, setTab } = useEditorTabs();
  const { activeStore } = useEditorDrawerContext();
  const handleChange = (_event: React.SyntheticEvent, newValue: EditorTab) => {
    setTab(newValue);
  };

  return (
    <Grid container wrap="nowrap" sx={{ height: 1 }}>
      <Grid
        item
        sx={{
          height: 1,
          display: "flex",
          borderRight: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          orientation="vertical"
          value={currentTab}
          scrollButtons={true}
          onChange={handleChange}
          TabIndicatorProps={{ sx: { display: "none" } }}
          aria-label="Editor tabs"
          sx={{ pt: 1, position: "sticky" }}
        >
          {tabs.map(tab => {
            const Icon = tab.Icon;
            return (
              <Tab
                key={tab.label}
                sx={{ px: 0, py: 1 }}
                label={
                  <Box
                    sx={{
                      width: 1,
                      height: 1,
                    }}
                  >
                    <Icon
                      sx={{
                        backgroundColor:
                          currentTab === tab.value ? "divider" : "transparent",
                        transition: "background-color 0.2s ease-in-out",
                        p: 0.5,
                        fontSize: 32,
                        borderRadius: 1,
                      }}
                    />
                    <Typography variant="body2" sx={{ fontSize: 12 }}>
                      {tab.label}
                    </Typography>
                  </Box>
                }
                {...a11yProps(tab.value)}
                value={tab.value}
              />
            );
          })}
        </Tabs>
      </Grid>
      <Grid
        item
        sx={{
          px: 2,
          py: 2,
          borderRight: 1,
          borderColor: "divider",
          flex: 1,
          // The drawer header is 51px tall
          maxHeight: "calc(100vh - 51px)",
          overflowY: "auto",
        }}
      >
        <TabPanel tab={currentTab} store={activeStore} />
      </Grid>
    </Grid>
  );
});
