import { fabric } from "fabric";
import {
  createContext,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { IDimension, ITemplate } from "shared/types/designStudio";
import { TTemplateComposition } from "shared/types/assetExporter";
import { scaleCanvas } from "../../assetBatchDrawer/dynamicText/utils.fabric";
import { mediaTypes, TValueMappings } from "../types";
import { getMappedMediaVars } from "./AssetBatchesContext.utils";
import { isMappedValue } from "../validators";
import { useAssetBatchesContext } from "./AssetBatchesContext";

type MaskSwitchType = "media" | "text";

interface ContextProps {
  template?: ITemplate;
  canvas?: fabric.Canvas;
  canvasContainerDim?: IDimension;
  valueMappings: TValueMappings;
  isMediaMaskOn: boolean;
  isTextMaskOn: boolean;
  currentSwitchType?: MaskSwitchType;
  templateHasFixedVideo: boolean;
  editingComposition: TTemplateComposition;
}

interface ContextHandlers {
  setCanvas: Dispatch<SetStateAction<fabric.Canvas | undefined>>;
  setCanvasContainerDim: Dispatch<SetStateAction<IDimension | undefined>>;
  onResize: (dim: IDimension) => void;
  setIsMediaMaskOn: Dispatch<SetStateAction<boolean>>;
  setIsTextMaskOn: Dispatch<SetStateAction<boolean>>;
  setCurrentSwitchType: Dispatch<SetStateAction<MaskSwitchType | undefined>>;
  setTemplateHasFixedVideo: Dispatch<SetStateAction<boolean>>;
  disabledTextMask: boolean;
  disabledMediaMask: boolean;
}

type ContextProviderProps = {
  children: ReactNode;
  template?: ITemplate;
  row: any;
  valueMappings: TValueMappings;
  editingComposition: TTemplateComposition;
};

const Context = createContext<ContextProps & ContextHandlers>(
  {} as ContextProps & ContextHandlers,
);

const ContextProvider = ({
  children,
  template,
  valueMappings,
  editingComposition,
}: ContextProviderProps) => {
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const [canvasContainerDim, setCanvasContainerDim] = useState<IDimension>();
  const [isMediaMaskOn, setIsMediaMaskOn] = useState(true);
  const [isTextMaskOn, setIsTextMaskOn] = useState(true);
  const [currentSwitchType, setCurrentSwitchType] = useState<MaskSwitchType>();
  const [templateHasFixedVideo, setTemplateHasFixedVideo] = useState(false);
  const { previewCount, mediaColumns } = useAssetBatchesContext();

  const onResize = useCallback(
    (dim: IDimension) => {
      if (!canvas) return;

      const { artboard } = template || {};

      if (!artboard) return;

      const { width, height } = dim;
      scaleCanvas(
        canvas,
        { width: artboard.width, height: artboard.height },
        { width, height },
      );
    },
    [canvas, template],
  );

  const disabledTextMask = useMemo(() => {
    const mappedTextValues = Object.keys(editingComposition.variables).filter(
      key => {
        const valueMapping = editingComposition.variables[key];
        return (
          valueMapping.variable.type === "text" && isMappedValue(valueMapping)
        );
      },
    );
    return mappedTextValues.length === 0;
  }, [editingComposition]);

  const disabledMediaMask = useMemo(() => {
    const allMediaVars = Object.keys(editingComposition.variables).filter(
      key => {
        const valueMapping = editingComposition.variables[key];
        return mediaTypes.includes(valueMapping.variable.type);
      },
    );
    return (
      getMappedMediaVars(
        previewCount,
        allMediaVars,
        editingComposition.variables,
        mediaColumns,
      ).length === 0
    );
  }, [mediaColumns, previewCount, editingComposition]);

  return (
    <Context.Provider
      value={{
        template,
        canvas,
        canvasContainerDim,
        valueMappings,
        isMediaMaskOn,
        isTextMaskOn,
        currentSwitchType,
        templateHasFixedVideo,
        editingComposition,
        disabledMediaMask,
        disabledTextMask,
        setCanvas,
        setCanvasContainerDim,
        onResize,
        setIsMediaMaskOn,
        setIsTextMaskOn,
        setCurrentSwitchType,
        setTemplateHasFixedVideo,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const AssetBatchesRenderProvider = memo(ContextProvider);

export const useAssetBatchesRenderContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Context must be used within a ContextProvider");
  }

  return context;
};
