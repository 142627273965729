import { createContext } from "react";
import { useManageStores } from "./editorDrawerContext/useManageStores";

type EditorDrawerContext = ReturnType<typeof useManageStores>;

export const editorDrawerContext = createContext<EditorDrawerContext | null>(
  null,
);

type EditorDrawerContextProviderProps = {
  children: React.ReactNode;
};

export const EditorDrawerContextProvider = ({
  children,
}: EditorDrawerContextProviderProps) => {
  const { Provider } = editorDrawerContext;
  const storeManagement = useManageStores();

  return <Provider value={storeManagement}>{children}</Provider>;
};
