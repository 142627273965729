import { StoreType } from "screens/designStudio/designStudioV2/types";
import { Layers } from "./tabPanel/Layers";
import { Media } from "./tabPanel/Media";
import { Text } from "./tabPanel/Text";
import { Shapes } from "./tabPanel/Shapes";
import { EditorTab } from "./useEditorTabs";

type TabPanelProps = {
  tab: EditorTab | null;
  store: StoreType | null;
};

export const TabPanel = ({ tab, store }: TabPanelProps) => {
  if (!tab || !store) return null;

  switch (tab) {
    case "layers":
      return <Layers store={store} />;
    case "media":
      return <Media store={store} />;
    case "text":
      return <Text store={store} />;
    case "shapes":
      return <Shapes store={store} />;
    default:
      return null;
  }
};
