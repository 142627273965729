import { useCurrentSession } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import {
  AdLoadReviewTableEntry,
  AdLoadReviewTableEntryProductSet,
} from "../AdLoadReviewTable";
import { findAdProtoDraft } from "screens/adLibrary/facebookUtils/adProto";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";
import { raise } from "utils/errorMessage";
import { AdProtoDraft } from "screens/adLibrary/facebookUtils/adProto.types";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { isTruthy } from "utils/helpers.array";
import { AdDest } from "screens/adLibrary/adLoadV2/campaignData.utils";

export const useDataSource = () => {
  const { originalData: ads } = useAds();
  const { adDests, session } = useCurrentSession();
  const { adProtoDrafts } = session.data;

  return adDests.map<AdLoadReviewTableEntry>(adDest => {
    const adProtoDraft = findAdProtoDraft({
      adProtoDrafts,
      adId: adDest.adId,
      storeName: adDest.storeName,
    });
    const ad =
      ads.find(item => item.id === adDest.adId) ??
      raise(`Ad not found for id ${adDest.adId}`);

    return {
      adId: adProtoDraft.id,
      name: ad.inputParameters.name ?? "",
      campaignId: adDest.campaignId,
      adsetId: adDest.adsetId,
      storeName: adDest.storeName,
      type: adProtoDraft.type,
      thumbnail: ad.visuals.thumbnail25 ?? ad.visuals.thumbnail ?? "",
      storyThumbnail: ad.visuals.additionalPlacements?.[0].thumbnail ?? "",
      selected: adDest.loadToFb,
      ctaButton:
        "callToAction" in adProtoDraft ? adProtoDraft.callToAction : undefined,
      displayUrl: "displayUrl" in adProtoDraft ? adProtoDraft.displayUrl : "",
      ...getAdDestinations(adProtoDraft, adDest),
    };
  });
};

const getAdDestinations = (
  adProtoDraft: AdProtoDraft,
  adDest: AdDest,
): Pick<AdLoadReviewTableEntry, "destinationUrls" | "productSets"> => {
  const adProtoType = adProtoDraft.type;
  switch (adProtoType) {
    case AdType.Still:
    case AdType.Video: {
      return {
        destinationUrls: adProtoDraft.destinationUrl
          ? [adProtoDraft.destinationUrl]
          : [],
        productSets: [],
      };
    }
    case AdType.Carousel: {
      return {
        destinationUrls: adProtoDraft.cards
          .map(card => card.destinationUrl)
          .filter(isTruthy),
        productSets: [],
      };
    }
    case AdType.Collection:
    case AdType.InstantExperience: {
      if (adProtoDraft.convertToVideo) {
        return {
          destinationUrls: [adProtoDraft.destinationUrl],
          productSets: [],
        };
      }
      const elementDests = adProtoDraft.elementDests;

      return {
        destinationUrls: elementDests.flatMap(dest =>
          dest.destType === "url" && dest.destinationUrl
            ? [dest.destinationUrl]
            : [],
        ),
        productSets: elementDests.flatMap(dest => {
          if (
            dest.destType === "productSet" &&
            dest.productSetId &&
            dest.productCatalogId
          ) {
            return {
              productSetId: dest.productSetId,
              productCatalogId: dest.productCatalogId,
            };
          }
          return [];
        }),
      };
    }
    case AdType.AIA: {
      return {
        destinationUrls: adProtoDraft.destinationUrl
          ? [adProtoDraft.destinationUrl]
          : [],
        productSets: getAIAProductSets(adProtoDraft, adDest),
      };
    }
    default:
      return {
        destinationUrls: [],
        productSets: [],
      };
  }
};

function getAIAProductSets(
  adProtoDraft: AdProtoDraft,
  adDest: AdDest,
): AdLoadReviewTableEntryProductSet[] {
  const adSetData = adProtoDraft.dests.find(
    dest => dest.adsetId == adDest.adsetId,
  );

  return adSetData?.productInfo?.productSetId &&
    adSetData?.productInfo?.productCatalogId
    ? [adSetData.productInfo as AdLoadReviewTableEntryProductSet]
    : [];
}
