import { AssetsDelete } from "./AssetsDelete";
import { AssetsDownload } from "./AssetsDownload";
import { AssetsEdit } from "./AssetsEdit";
import { AssetsExpire } from "./AssetsExpire";
import { AssetsMove } from "./AssetsMove";
import { AssetsPublish } from "./AssetsPublish";
import { AssetsUnpublish } from "./AssetsUnpublish";
import { FileExpire } from "./FileExpire";
import { FileMove } from "./FileMove";
import { FileReplace } from "./FileReplace";
import { FileUpload } from "./FileUpload";
import { FolderCreate } from "./FolderCreate";
import { LayoutSwitcher } from "./LayoutSwitcher";
import { MediaSearch } from "./MediaSearch";

import styles from "./MediaToolbar.module.scss";

export const MediaToolbar = () => {
  return (
    <div className={styles.host}>
      <div>
        <FileUpload />
        <FolderCreate />
        <AssetsEdit />
        <AssetsExpire />
        <AssetsMove />
        <AssetsDelete />
        <AssetsPublish />
        <AssetsUnpublish />
        <AssetsDownload />
      </div>
      <div>
        <MediaSearch />
        <LayoutSwitcher />
      </div>
      {/* Drawers & Modals */}
      <FileReplace />
      <FileMove />
      <FileExpire />
    </div>
  );
};
