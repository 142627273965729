import { Workspace } from "polotno/canvas/workspace";
import { Grid, Box } from "@mui/material";
import { EditorTabs } from "./polotnoEditor/EditorTabs";
import { EditorHeader } from "./polotnoEditor/EditorHeader";
import CircularProgress from "@mui/material/CircularProgress";
import { useEditorDrawerContext } from "../editorDrawerContext/useEditorDrawerContext";
import { observer } from "mobx-react-lite";
import { EditorTopBar } from "./polotnoEditor/EditorTopbar";

const Tooltip = () => null;

export const PolotnoEditor = observer(() => {
  const { activeStore, removeStore, changeActiveStore } =
    useEditorDrawerContext();

  return (
    <Box sx={{ width: 1, height: 1, display: "flex", flexDirection: "column" }}>
      <EditorHeader onRemove={removeStore} onChange={changeActiveStore} />
      <Grid container sx={{ width: 1, flex: 1 }}>
        <Grid item xs={3}>
          <EditorTabs />
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              width: 1,
              height: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <EditorTopBar />
            {activeStore?.pages.length ? (
              <Workspace store={activeStore} components={{ Tooltip }} />
            ) : (
              <Box
                sx={{
                  backgroundColor: "#E7E7E7",
                  width: 1,
                  height: 1,
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});
