import {
  IExtendedFabricObject,
  TExtendedVideo,
} from "shared/types/designStudio";
import { TValueMapping } from "../../types";
import { isTextbox, isVideo } from "../../validators";
import {
  replaceStylesWithVariables,
  replaceVariablesWithinTextLines,
} from "screens/assetExporter/feedConfigurationV2/assetBatchDrawer/dynamicText/utils.variable";

export const getVideoData = (canvas?: fabric.StaticCanvas | fabric.Canvas) => {
  const layers = canvas?.getObjects().filter(isVideo);
  const background = [canvas?.backgroundImage].filter(isVideo);
  return [...(background ?? []), ...(layers ?? [])].filter(
    Boolean,
  ) as TExtendedVideo[];
};

export const setTargetOpacity = (
  target: IExtendedFabricObject,
  newOpacity: number,
) => target.set({ opacity: newOpacity });

export const renderTextVars = (
  target: IExtendedFabricObject,
  groupedMappings: Record<number, TValueMapping[]>,
  selectedRow: any,
) => {
  if (isTextbox(target)) {
    const {
      originalText = undefined,
      originalTextLines = undefined,
      styles: originalStyles,
    } = target;
    if (!originalText || !originalTextLines) return;
    const { updatedTextLines, groupedMappings: newGroupedMappings } =
      replaceVariablesWithinTextLines(
        originalText.split("\n"),
        groupedMappings,
        selectedRow,
      );
    const updatedStyles = replaceStylesWithVariables(
      originalText.split("\n"),
      newGroupedMappings,
      selectedRow,
      originalStyles,
    );
    target.set({
      text: updatedTextLines.join("\n"),
      styles: updatedStyles,
    });
  }
};
