import DataListURLTable, {
  ITableColumnURL,
} from "shared/components/dataListURL/DataListURLTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { Tag, Template } from "shared/types/salesEnablement";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { formatDistanceToNow } from "date-fns";
import Tags from "../shared/Tags";
import { uniq } from "lodash";
import { fieldKeys } from "../fields";
import { labels } from "./fileDrawer/Properties.fields";
import { Badge } from "antd";

type Props = {
  templates: Template[];
  loading: boolean;
};

const Table = (props: Props) => {
  const { templates, loading } = props;
  const { windowInnerHeight } = useWindowSize();
  const columns: ITableColumnURL<keyof Template, Template>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
    },
    {
      title: "Audience",
      dataIndex: "audience",
      key: "audience",
      filters: uniq(templates.flatMap(t => t.audience)).map(audience => ({
        text: labels["audience"][audience],
        value: audience,
      })),
      render: (audience: Template["audience"]) => (
        <Tags
          tags={audience.map(item => ({
            id: item,
            name: labels["audience"][item],
          }))}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: uniq(templates.map(t => t.status)).map(status => ({
        text: status,
        value: status,
      })),
      render: (status: Template["status"]) => {
        return (
          <Badge
            status={status === "PUBLISHED" ? "success" : "default"}
            text={status}
          />
        );
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      filters: uniq(
        templates
          .map(t => t.tags ?? [])
          .flat()
          .filter(tag => tag)
          .map(tag => tag!.name),
      ).map(name => ({
        text: name,
        value: name,
      })),
      render: (tags: Tag[]) => <Tags tags={tags} />,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt?: number) =>
        createdAt
          ? formatDistanceToNow(createdAt, { includeSeconds: true })
          : "-",
    },
    {
      title: "Last Updated At",
      dataIndex: "lastUpdatedAt",
      key: "lastUpdatedAt",
      render: (lastUpdatedAt?: number) =>
        lastUpdatedAt
          ? formatDistanceToNow(lastUpdatedAt, { includeSeconds: true })
          : "-",
    },
  ];

  const generateToolbarContents: (
    record?: Template,
  ) => ToolbarButton = record => {
    if (!record) return {};

    return {
      Edit: {
        icon: <EditOutlined />,
        onClick: () => {
          // fill
        },
        showInContextMenu: true,
      },
      Delete: {
        icon: <DeleteOutlined />,
        onClick: () => {
          // fill
        },
        showInContextMenu: true,
      },
      Duplicate: {
        icon: <CopyOutlined />,
        onClick: () => {
          // fill
        },
        showInContextMenu: true,
      },
    };
  };

  return (
    <TableActionsContextProvider<Template>
      renderContextActions={record => (
        <ContextTableActions
          getItemsToRender={generateToolbarContents}
          record={record}
        />
      )}
      renderHoverActions={record => (
        <InlineTableActions
          getItemsToRender={generateToolbarContents}
          record={record}
        />
      )}
    >
      <DataListURLTable<keyof Template, Template>
        loading={loading}
        dataSource={templates}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ y: windowInnerHeight - 245 }}
        type="hoverActionsVirtualTable"
        fieldKeys={fieldKeys}
      />
    </TableActionsContextProvider>
  );
};

export default Table;
