import { InboxOutlined } from "@ant-design/icons";
import { Upload, UploadProps, message } from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { TemplateFile } from "shared/types/salesEnablement";
import { processFileUpload } from "./UploadDragger.utils";
import { toTemplateFile } from "screens/designStudio/utils";

const getUploadFileAttrs = (file: UploadFile) => ({
  uid: file.uid,
  name: file.name,
  fileName: file.fileName,
  size: file.size,
});
type Props = {
  isEditing: boolean;
  fileList?: UploadFile<any>[];
  selectedFile?: TemplateFile;
  setSelectedFile?: (file?: TemplateFile) => void;
};
const UploadDragger = (props: Props) => {
  const { selectedFile, setSelectedFile, isEditing, fileList } = props;

  const uploadProps: UploadProps = {
    accept: ".zip,.indd,.idml",
    maxCount: 1,
    beforeUpload: () => Promise.reject(false), // block upload. Upload will be done in onChange.
    onChange: async info => {
      // When deleting, fileList becomes an empty array
      const { file, fileList } = info;
      const isDeleting = fileList.length === 0;

      if (file && !isDeleting) {
        const { name } = file || {};
        if (!name) return;
        // TODO: commented but will be used later to detect file type
        // const extension = name.split(".").pop();
        // const type = extension === "zip" ? "html" : "indesign";

        const templateFile = toTemplateFile(
          { ...getUploadFileAttrs(file) },
          "indesign",
        );

        setSelectedFile?.({
          ...templateFile,
          status: "uploading",
        });

        try {
          const pages = await processFileUpload(file as RcFile);

          const fileUrl = `https://av2-dev-aflac-templates.s3.amazonaws.com/${name}`;

          setSelectedFile?.({
            ...templateFile,
            url: fileUrl,
            status: "done",
            pages,
          });
        } catch (err) {
          setSelectedFile?.({
            ...templateFile,
            status: "error",
          });
        }
      }
    },
    onRemove: async () => {
      if (isEditing) {
        message.warning("You cannot delete file for an existing template.");
        return;
      }
      if (!selectedFile) return;

      setSelectedFile?.(undefined);
    },
  };

  return isEditing ? (
    <Upload {...uploadProps} fileList={fileList} />
  ) : (
    <Upload.Dragger {...uploadProps} fileList={fileList}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>

      <div>Click or drag file to this area to upload</div>
      <div>Accepted file types: InDesign and HTML (.zip folder)</div>
    </Upload.Dragger>
  );
};
export default UploadDragger;
