import {
  useContext,
  createContext,
  ReactNode,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { TableCardLayout } from "shared/types/toolbar";
import useFetchTemplates from "./useFetchTemplates";
import {
  FileDrawerMode,
  Layer,
  Template,
  TemplateFile,
} from "shared/types/salesEnablement";
import { keys } from "utils/helpers";
import { useForm } from "antd/lib/form/Form";
import { FormInstance } from "antd/es/form/Form";
import useLayers from "./useLayers";

type RenderingPreviewStatus = "loading" | "done" | "error" | "idle";
type TemplateForm = Record<keyof Template, any>;
type OnLayoutChangeFunc = (layout: TableCardLayout) => void;
type ContextType = {
  onLayoutChange: OnLayoutChangeFunc;
  layout: TableCardLayout;
  templates: Template[];
  loading: boolean;
  sortableKeys: (keyof Template)[];
  uploadForm?: FormInstance<any>;
  selectedFile?: TemplateFile;
  setSelectedFile?: Dispatch<SetStateAction<TemplateFile | undefined>>;
  renderingPreviewStatus: RenderingPreviewStatus;
  searchLayersBy?: string;
  onSearchLayersBy?: (value: string) => void;
  layers: Layer[];
  setLayers: Dispatch<SetStateAction<Layer[]>>;
  selectedTemplate?: Template;
  setSelectedTemplate: Dispatch<SetStateAction<Template | undefined>>;
  fileDrawerMode?: "new" | "edit";
  setFileDrawerMode: Dispatch<SetStateAction<"new" | "edit" | undefined>>;
  filteredLayers?: Layer[];
  setFilteredLayers?: Dispatch<SetStateAction<Layer[]>>;
};

const Context = createContext<ContextType | null>(null);

export const useSalesEnablementContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error(
      "useSalesEnablementContext must be used within SalesEnablementDataProvider.",
    );
  }
  return ctx;
};

const DataContext = ({ children }: { children: ReactNode }) => {
  const [layout, setLayout] = useState<TableCardLayout>("table");
  const { templates, loading } = useFetchTemplates();
  const sortableKeys = useMemo(() => {
    const [template] = templates || [];
    return keys<keyof Template>(template || {});
  }, [templates]);
  const [fileDrawerMode, setFileDrawerMode] = useState<
    FileDrawerMode | undefined
  >();
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();

  const [uploadForm] = useForm<TemplateForm>();
  const [selectedFile, setSelectedFile] = useState<TemplateFile>();
  const [renderingPreviewStatus, setRenderingPreviewStatus] =
    useState<RenderingPreviewStatus>("idle");
  useEffect(() => {
    if (!selectedFile) {
      setRenderingPreviewStatus("idle");
      return;
    }

    if (selectedFile.status === "uploading") {
      setRenderingPreviewStatus("loading");
    } else if (selectedFile.status === "done") {
      setRenderingPreviewStatus("done");
    }
  }, [selectedFile]);

  const {
    onSearchLayersBy,
    layers,
    setLayers,
    searchLayersBy,
    filteredLayers,
    setFilteredLayers,
  } = useLayers({
    file: selectedFile,
  });

  const memoizedContextValue: ContextType = useMemo(
    () => ({
      onLayoutChange: setLayout,
      layout,
      templates,
      loading,
      sortableKeys,
      uploadForm,
      selectedFile,
      setSelectedFile,
      renderingPreviewStatus,
      onSearchLayersBy,
      layers,
      setLayers,
      searchLayersBy,
      selectedTemplate,
      setSelectedTemplate,
      fileDrawerMode,
      setFileDrawerMode,
      filteredLayers,
      setFilteredLayers,
    }),
    [
      layout,
      templates,
      loading,
      sortableKeys,
      uploadForm,
      selectedFile,
      setSelectedFile,
      renderingPreviewStatus,
      layers,
      setLayers,
      onSearchLayersBy,
      searchLayersBy,
      selectedTemplate,
      setSelectedTemplate,
      fileDrawerMode,
      setFileDrawerMode,
      filteredLayers,
      setFilteredLayers,
    ],
  );

  return (
    <Context.Provider value={memoizedContextValue}>{children}</Context.Provider>
  );
};

export const DataProvider = ({ children }: { children: ReactNode }) => {
  return <DataContext>{children}</DataContext>;
};
