import { LayerElement } from "screens/designStudio/designStudioV2/types";

export const toggleLayerVisibility = (layer: LayerElement) =>
  layer.set({ visible: !layer.visible });

export const toggleLayerLock = (layer: LayerElement) =>
  layer.set({
    selectable: !layer.selectable,
    draggable: !layer.draggable,
    contentEditable: !layer.contentEditable,
    styleEditable: !layer.styleEditable,
    resizable: !layer.resizable,
  });

export const deleteLayer = (layer: LayerElement) =>
  layer.store.deleteElements([layer.id]);

export const isLocked = (layer: LayerElement) => !layer.selectable;
