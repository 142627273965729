import { isFeatureEnabled } from "utils/helpers";

export const FABRIC_EDITOR = "v2";
export const POLOTNO_EDITOR = "v3";
export const DESIGN_STUDIO_V2_EDITOR_ENABLED = isFeatureEnabled(
  "ENABLE_DESIGN_STUDIO_V2_EDITOR",
);
export const POLOTNO_API_KEY = process.env.REACT_APP_POLOTNO_EDITOR_APIKEY;
export const POLOTNO_SHAPES = [
  "https://api.polotno.dev/basic-shapes/1-circle-1.svg",
  "https://api.polotno.dev/basic-shapes/2-rect-1.svg",
  "https://api.polotno.dev/basic-shapes/3-triangle-1.svg",
  "https://api.polotno.dev/basic-shapes/4-triangle2-1.svg",
  "https://api.polotno.dev/basic-shapes/5-poly-1.svg",
  "https://api.polotno.dev/basic-shapes/6-poly-1.svg",
  "https://api.polotno.dev/basic-shapes/7-arrow-1.svg",
  "https://api.polotno.dev/basic-shapes/bookmark-shapes.svg",
  "https://api.polotno.dev/basic-shapes/cube.svg",
  "https://api.polotno.dev/basic-shapes/heart.svg",
  "https://api.polotno.dev/basic-shapes/plus.svg",
  "https://api.polotno.dev/basic-shapes/1-circle-2.svg",
  "https://api.polotno.dev/basic-shapes/2-rect-2.svg",
  "https://api.polotno.dev/basic-shapes/3-triangle-2.svg",
  "https://api.polotno.dev/basic-shapes/4-triangle2-2.svg",
  "https://api.polotno.dev/basic-shapes/5-poly-2.svg",
  "https://api.polotno.dev/basic-shapes/6-poly-2.svg",
  "https://api.polotno.dev/basic-shapes/7-arrow-2.svg",
  "https://api.polotno.dev/basic-shapes/circle.svg",
  "https://api.polotno.dev/basic-shapes/cylinder.svg",
  "https://api.polotno.dev/basic-shapes/lightning.svg",
  "https://api.polotno.dev/basic-shapes/square.svg",
  "https://api.polotno.dev/basic-shapes/1-circle-3.svg",
  "https://api.polotno.dev/basic-shapes/2-rect-3.svg",
  "https://api.polotno.dev/basic-shapes/3-triangle-3.svg",
  "https://api.polotno.dev/basic-shapes/4-triangle2-3.svg",
  "https://api.polotno.dev/basic-shapes/5-poly-3.svg",
  "https://api.polotno.dev/basic-shapes/6-poly-3.svg",
  "https://api.polotno.dev/basic-shapes/7-arrow-3.svg",
  "https://api.polotno.dev/basic-shapes/cloud.svg",
  "https://api.polotno.dev/basic-shapes/edit-edit-tools.svg",
  "https://api.polotno.dev/basic-shapes/moon.svg",
  "https://api.polotno.dev/basic-shapes/star.svg",
];
