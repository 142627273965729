import { message } from "antd";
import { uniq } from "lodash";
import { interpolate } from "remotion";
import { loadFontFromBrand } from "screens/designStudio/editor/propertySectionV2/propertyRow/ManageText.utils";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import { customFonts } from "shared/constants/canvas";
import { Font } from "shared/types/brandsAccounts";
import { IDimension, TExtendedTextbox } from "shared/types/designStudio";
import { loadFont } from "utils/helpers";
import { isTextbox } from "./validators";

export const getColumnTitle = (key: string) => {
  if (key === "order_number") return "";
  if (key === COLUMN_ASSET_BATCHES) return "Asset batches applied";

  return key;
};

export const loadFontsFromTemplate = async (json: any, brandFonts: Font[]) => {
  if (!json) return;
  const { objects } = json;
  if (!objects || !Array.isArray(objects)) return;

  const textboxes = objects.filter(isTextbox);
  const fontFamilies = getFontFamiliesForCanvas(textboxes);

  const brandResults = await Promise.all(
    brandFonts.map(font => loadFontFromBrand(font.name, font.url)),
  );
  const results = await Promise.all(
    fontFamilies.map(fontFamily => loadFont(fontFamily)),
  );
  if ([...brandResults, ...results].some(result => !result)) {
    message.error("Failed to load all required fonts.");
  }
};

const getFontFamiliesForCanvas = (textboxes: TExtendedTextbox[]) =>
  textboxes
    .reduce<string[]>((acc, textbox) => {
      const { styles, fontFamily } = textbox;
      const fontFamilies = Object.values<any>(styles).reduce<string[]>(
        (styleAcc, charStyleObj) => {
          const fontFamiliesFromcharStyle = Object.values<any>(
            charStyleObj,
          ).map(style => style.fontFamily) as string[];
          return [...styleAcc, ...uniq(fontFamiliesFromcharStyle)];
        },
        [],
      );

      return [
        ...acc,
        ...(uniq(
          [...fontFamilies, fontFamily].filter(fontFam => !!fontFam),
        ) as string[]),
      ];
    }, [])
    .filter(fontFamily => customFonts.includes(fontFamily));

export const fadeOut = (
  frame: number,
  from: number,
  durationInFrame: number,
): number => {
  return interpolate(frame, [from, durationInFrame], [0.5, 0], {
    extrapolateRight: "clamp",
  });
};

export const fadeIn = (
  frame: number,
  from: number,
  durationInFrame: number,
): number => {
  return interpolate(frame, [from, durationInFrame], [0.3, 1], {
    extrapolateRight: "clamp",
  });
};

export const scaleHTMLCanvas = (
  canvas: HTMLCanvasElement | null,
  original: IDimension,
  target: IDimension,
) => {
  if (canvas) {
    // Set the canvas dimensions to the target dimensions
    canvas.width = target.width;
    canvas.height = target.height;

    // Get the context and scale it
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.scale(target.width / original.width, target.height / original.height);
    }
  }
};
