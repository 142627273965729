import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Drawer, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { useState } from "react";
import { useUpdateAssetMetaMutation, util } from "redux/media/media.api";
import { TooltipPerms } from "./TooltipPerms";
import { useAppDispatch } from "./redux";
import { usePermissions } from "./usePermissions";
import { useQPBreadcrumb } from "./useQPBreadcrumb";
import { finishUpload, getPresignedUrl, uploadToS3 } from "./utils.upload";

export const FileUpload = () => {
  const { current } = useQPBreadcrumb();
  const { assets } = usePermissions();
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const allowed = assets.includes("upload");

  const onClose = () => {
    setVisible(false);
  };

  const onOpen = () => {
    setVisible(true);
  };

  const handleChange = (info: UploadChangeParam) => {
    const allDone = info.fileList.every(file => file.percent === 100);
    if (allDone) {
      dispatch(util.invalidateTags(["assets", "searchResults"]));
    }
  };

  return (
    <div
      onDragOver={event => {
        event.dataTransfer.dropEffect = "none";
        event.preventDefault();
      }}
      onDrop={event => event.preventDefault()}
    >
      <TooltipPerms action="Upload Assets" enabled allowed={allowed}>
        <Button
          type="primary"
          shape="round"
          icon={<UploadOutlined />}
          onClick={onOpen}
          disabled={!allowed}
        >
          Upload
        </Button>
      </TooltipPerms>
      <Drawer
        title="Upload files"
        destroyOnClose
        maskClosable
        width={500}
        onClose={onClose}
        visible={visible}
        footer={
          <Button type="primary" onClick={onClose}>
            Close
          </Button>
        }
        footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      >
        <div>
          <Upload.Dragger
            multiple
            accept="image/png,image/jpeg,video/mp4,video/quicktime,.zip"
            showUploadList={{ showRemoveIcon: false }}
            customRequest={useCustomRequest(current)}
            onChange={handleChange}
          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </div>
      </Drawer>
    </div>
  );
};

const useCustomRequest = (folderId: string) => {
  const [updateMeta] = useUpdateAssetMetaMutation();

  return async ({ file, onSuccess, onError }: UploadRequestOption) => {
    try {
      // Get pre-signed url from AWS.
      const { presignedUrl, processId } = await getPresignedUrl(
        folderId,
        file as File,
      );
      // Upload filt to S3.
      await uploadToS3(presignedUrl, file as File);
      // Notify to webdam.
      const { id } = await finishUpload(processId);
      // Update asset metadata.
      await updateMeta({ payload: [{ id }] }).unwrap();
      // Notify uploader.
      onSuccess?.(null, new XMLHttpRequest());
    } catch (e) {
      onError?.(e as Error);
    }
  };
};
