import { Upload, Button, Space, Typography } from "antd";
import { memo, useState } from "react";
import { IUploadImageFormInput } from "shared/types/uploadManagement";
import {
  IUploadMediaUrls,
  uploadMedia,
  uploadVideoToFB,
} from "utils/uploadMedia";
import { UploadFileStatus } from "antd/lib/upload/interface";
import VisualMedia from "screens/adLibrary/shared/VisualMedia";
import {
  CheckOutlined,
  LoadingOutlined,
  RedoOutlined,
  UploadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { getBase64DataUrl, generateGuidFilename } from "utils/helpers";
import styles from "./FileUploadDragger.module.scss";
import { isFileSizeValid } from "screens/adLibrary/utils";
import { CAMSelect, CAMConfig } from "shared/components/media";

interface IProps {
  fileUrls: IUploadMediaUrls;
  handleChangeFile(newFile: IUploadMediaUrls): void;
  placeholder?: string;
}

const getUploadStatusIcon = (uploadStatus?: UploadFileStatus) => {
  switch (uploadStatus) {
    case "uploading":
      return LoadingOutlined;
    case "error":
      return RedoOutlined;
    case "success":
      return CheckOutlined;
    case "done":
      return SyncOutlined;
    default:
      return UploadOutlined;
  }
};

const FileUploadDragger = ({
  fileUrls,
  handleChangeFile,
  placeholder,
}: IProps) => {
  const [uploadStatus, setUploadStatus] = useState<
    UploadFileStatus | undefined
  >(fileUrls?.thumbnail ? "done" : undefined);
  const [fileName, setFileName] = useState<string>("");

  const isUploading = uploadStatus === "uploading";
  const UploadStatusIcon = getUploadStatusIcon(uploadStatus);

  const UploadDragger = () => {
    const handleFileSizeValidation = (file: Blob) => {
      const shouldUploadFile = isFileSizeValid(file);

      if (!shouldUploadFile) {
        setUploadStatus("error");
      }

      return shouldUploadFile;
    };

    return (
      <Upload.Dragger
        className="ad-carousel-card-upload"
        data-cy="ad-media-upload"
        multiple={false}
        accept="image/*, video/*"
        showUploadList={false}
        style={{ width: uploadStatus ? "50%" : "100%" }}
        beforeUpload={handleFileSizeValidation}
        customRequest={async ({ file, onSuccess, onError }) => {
          setUploadStatus("uploading");
          const assetFile = file as File;
          const imageDataUrl = await getBase64DataUrl(assetFile);

          const mediaToUpload: IUploadImageFormInput = {
            file: imageDataUrl,
            filename: generateGuidFilename(assetFile.name),
            size: assetFile.size,
            type: assetFile.type,
            mediaSizing: "IN_FEED",
          };
          try {
            const assetData = await uploadMedia(
              mediaToUpload,
              "card-asset",
              true,
            );
            onSuccess?.(
              assetData,
              new XMLHttpRequest() /* TODO: review this */,
            );
            handleChangeFile(assetData);
            setUploadStatus("done");
            setFileName(assetFile.name);
          } catch (error) {
            onError?.(error as Error);
            setUploadStatus("error");
          }
        }}
      >
        {uploadStatus ? (
          <Button type="link" disabled={isUploading}>
            <UploadStatusIcon />
            Replace
          </Button>
        ) : (
          <div className={styles.placeholder}>
            <Typography.Text>
              {`Drop your files here, or `}
              <span className={styles.spanText}>browse</span>
            </Typography.Text>
          </div>
        )}
      </Upload.Dragger>
    );
  };
  const convertWDtoUploadable = async (rows: WDAsset[]) => {
    const asset = rows[0];
    if (["mov", "mp4"].includes(asset.filetype)) {
      return await uploadVideoToFB(asset.hiResURLRaw);
    }
    return { assetId: undefined, thumbnail: asset.hiResURLRaw, videoUrl: "" };
  };

  return (
    <Space align="end">
      {uploadStatus && (
        <VisualMedia
          thumbnailUrl={fileUrls?.thumbnail}
          videoUrl={fileUrls?.videoUrl}
          placeholder={placeholder}
          style={{ width: 200 }}
        />
      )}
      <div>
        <Typography.Text
          style={{ width: 200 }}
          ellipsis={{ tooltip: fileName }}
        >
          {fileName}
        </Typography.Text>
        <UploadDragger />
        {CAMConfig.active && (
          <CAMSelect
            maxSelected={1}
            fileTypes={["png", "jpeg", "jpg", "mp4", "mov"]}
            onClose={async (assets: WDAsset[]) => {
              setUploadStatus("uploading");
              const uploadableAssets = await convertWDtoUploadable(assets);
              handleChangeFile(uploadableAssets);
              setUploadStatus("done");
            }}
          >
            <Button disabled={isUploading} icon={<UploadStatusIcon />}>
              Select From CAM
            </Button>
          </CAMSelect>
        )}
      </div>
    </Space>
  );
};

export default memo(FileUploadDragger);
