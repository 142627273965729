import { TTemplateComposition } from "shared/types/assetExporter";
import { ITemplate } from "shared/types/designStudio";
import { AssetBatchesHtmlRenderProvider } from "../contexts/AssetBatchesHtmlRenderContext";
import { AssetBatchesRenderProvider } from "../contexts/AssetBatchesRenderContext";
import { HtmlPreview } from "./HtmlPreview";
import Preview from "./Preview";

interface Props {
  template: ITemplate | undefined;
  composition: TTemplateComposition;
  selectedRow: any;
}

export const TemplatePreviewSection = ({
  template,
  composition,
  selectedRow,
}: Props) => {
  if (!template) return null;

  if (template.type === "html")
    return (
      <AssetBatchesHtmlRenderProvider
        key={composition.compositionId}
        template={template}
        row={selectedRow}
        editingComposition={composition}
      >
        <HtmlPreview />
      </AssetBatchesHtmlRenderProvider>
    );
  return (
    <AssetBatchesRenderProvider
      key={composition.compositionId}
      template={template}
      row={selectedRow}
      valueMappings={composition.variables}
      editingComposition={composition}
    >
      <Preview />
    </AssetBatchesRenderProvider>
  );
};
