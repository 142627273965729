import {
  PhotoSizeSelectLargeRounded,
  AutoAwesomeRounded,
  CropLandscapeRounded,
  DashboardCustomizeRounded,
  LayersRounded,
  PhotoSizeSelectActualRounded,
  TitleRounded,
  UploadRounded,
  DownloadRounded,
  FolderSpecialRounded,
  RssFeedRounded,
  CommentRounded,
} from "@mui/icons-material";
import { useState } from "react";

export type EditorTab =
  | "templates"
  | "layers"
  | "text"
  | "media"
  | "shapes"
  | "upload"
  | "resize"
  | "ai"
  | "import"
  | "brandKit"
  | "feed"
  | "review";

const tabs = [
  {
    value: "templates",
    label: "Templates",
    enabled: true,
    Icon: DashboardCustomizeRounded,
  },
  { value: "layers", label: "Layers", enabled: true, Icon: LayersRounded },
  { value: "text", label: "Text", enabled: true, Icon: TitleRounded },
  {
    value: "media",
    label: "Media",
    enabled: true,
    Icon: PhotoSizeSelectActualRounded,
  },
  {
    value: "shapes",
    label: "Shapes",
    enabled: true,
    Icon: CropLandscapeRounded,
  },
  { value: "upload", label: "Upload", enabled: true, Icon: UploadRounded },
  {
    value: "resize",
    label: "Resize",
    enabled: true,
    Icon: PhotoSizeSelectLargeRounded,
  },
  { value: "ai", label: "AI", enabled: true, Icon: AutoAwesomeRounded },
  { value: "import", label: "Import", enabled: true, Icon: DownloadRounded },
  {
    value: "brandKit",
    label: "Brand Kit",
    enabled: true,
    Icon: FolderSpecialRounded,
  },
  { value: "feed", label: "Feed", enabled: true, Icon: RssFeedRounded },
  { value: "review", label: "Review", enabled: true, Icon: CommentRounded },
] as const;

export const useEditorTabs = () => {
  const [currentTab, setTab] = useState<EditorTab | null>("layers");

  return { tabs, currentTab: currentTab ?? null, setTab };
};
