import { Input, Select, Tooltip } from "antd";
import { keys, sortBy } from "lodash";
import { filterOutColumns } from "screens/assetExporter/feedConfigurationV2/shared/constants";
import { useMemo, useState } from "react";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesValueMappingContext";
import { useAssetBatchesTemplateCompositionContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesTemplateCompositionContext";
import {
  TInputType,
  TValue,
} from "screens/assetExporter/feedConfigurationV2/shared/types";
import { isColumnValue } from "screens/assetExporter/feedConfigurationV2/shared/validators";
import styles from "./InputValue.module.scss";

type InputValueProps = {
  inputFieldType: "input" | "select"; // needed to add this to distinguish component for regex type
  inputType: TInputType;
  value?: TValue;
  mappingKey: string;
  onFocus: (mappingKey: string) => void;
  onBlur: (mappingKey: string) => void;
  onValueChange: (mKey: string, hasValue: boolean) => void;
  placeholder?: string;
};

const InputValue = ({
  inputFieldType,
  mappingKey,
  inputType,
  value,
  onFocus,
  onBlur,
  onValueChange,
  placeholder,
}: InputValueProps) => {
  const { setShowTextAlert } = useAssetBatchesContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();
  const { onSelectInputValue, onSelectInputValueSelect } =
    useAssetBatchesTemplateCompositionContext();

  const hasError = !value;
  const showErrorMessage = hasError && inputType === "match_to_column";

  const columns = useMemo(() => sortBy(keys(selectedRow || {})), [selectedRow]);
  const [showRegexTooltip, toggleRegexTooltip] = useState(false);
  const focusEvents = {
    onFocus: () => {
      toggleRegexTooltip(true);
      onFocus(mappingKey);
    },
    onBlur: () => {
      toggleRegexTooltip(false);
      onBlur(mappingKey);
    },
  };
  const shouldRegexTooltipVisible =
    inputType === "regex" && isColumnValue(value) && selectedRow[value.column];

  const inputValue = useMemo(() => {
    if (isColumnValue(value)) {
      return value.regexPattern;
    }

    return typeof value === "string" ? value : undefined;
  }, [value]);

  return (
    <div className={styles.inputContainer} data-cy="template-input-value">
      {inputFieldType === "input" && (
        <Tooltip
          title={isColumnValue(value) ? selectedRow[value.column] : ""}
          visible={shouldRegexTooltipVisible ? showRegexTooltip : false}
        >
          <Input
            {...focusEvents}
            value={inputValue}
            className={`${styles.input} ${
              showErrorMessage ? styles.error : ""
            } `}
            onChange={e => {
              setShowTextAlert(false);
              onValueChange(mappingKey, !e.target.value);
              onSelectInputValue(mappingKey, e.target.value);
            }}
            placeholder={placeholder}
          />
        </Tooltip>
      )}

      {inputFieldType === "select" && (
        <Select
          data-cy={mappingKey}
          {...focusEvents}
          className={`${styles.select} ${showErrorMessage ? styles.error : ""}`}
          showSearch
          value={isColumnValue(value) ? value.column : undefined}
          options={columns
            .filter(col => !filterOutColumns.includes(col))
            .map(col => ({ label: col, value: col }))}
          onSelect={col => {
            setShowTextAlert(false);
            if (inputType === "text" || inputType === "logo") return;
            onValueChange(mappingKey, !col);
            onSelectInputValueSelect(mappingKey, {
              column: col,
              type: inputType,
            });
          }}
          placeholder={placeholder}
        />
      )}

      {showErrorMessage && (
        <div className={styles.errorMessage}>
          match column or use another input type
        </div>
      )}
    </div>
  );
};

export default InputValue;
