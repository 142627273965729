import { UndoRounded, RedoRounded, SaveRounded } from "@mui/icons-material";
import { IconButton, Stack, Button, Typography } from "@mui/material";
import { useEditorDrawerContext } from "../../editorDrawerContext/useEditorDrawerContext";
import { useStoreHistory } from "./editorTopbar/useStoreHistory";
import { observer } from "mobx-react-lite";
import { useSaveStore } from "./editorTopbar/useSaveStore";
import { useDesignStudioEditorItems } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditorItems";

export const EditorTopBar = observer(() => {
  const { activeStore } = useEditorDrawerContext();
  const { activeItem } = useDesignStudioEditorItems();
  const { undo, redo, canUndo, canRedo } = useStoreHistory(activeStore);
  const { save } = useSaveStore(activeStore, activeItem);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        p: 1,
        borderBottom: 1,
        borderColor: "divider",
        alignItems: "center",
      }}
    >
      <Stack direction="row">
        <IconButton size="small" disabled={!canUndo} onClick={undo}>
          <UndoRounded />
        </IconButton>
        <IconButton size="small" disabled={!canRedo} onClick={redo}>
          <RedoRounded />
        </IconButton>
      </Stack>
      <Button
        size="small"
        onClick={save}
        sx={{ color: "action.active" }}
        startIcon={<SaveRounded />}
      >
        <Typography
          variant="button"
          sx={{
            fontSize: 13,
            color: "text.primary",
            textTransform: "uppercase",
          }}
        >
          Save
        </Typography>
      </Button>
    </Stack>
  );
});
