import { IConfig } from "shared/types/configuration";
import HttpClient from "./httpClient";
import { RcFile } from "antd/lib/upload";
import { Page } from "shared/types/salesEnablement";

type Response<T> = {
  result: T;
  error?: Error;
};

export default ({ config }: { headers: any; config: IConfig }) => ({
  getTemplates: () =>
    HttpClient.get<any>(config.services.salesEnablement.templatesUrl),
  getPresignedUrl: (file: RcFile) =>
    HttpClient.post<Response<{ bucket: string; key: string; url: string }>>(
      config.services.salesEnablement.presignedUrl,
      {
        key: file.name,
      },
    ),
  upload: (file: RcFile, url: string) =>
    fetch(url, { method: "PUT", body: file }),
  parseIdml: (bucket: string, key: string) => {
    const url = `${config.services.salesEnablement.parseIdmlUrl}?bucket=${bucket}&key=${key}`;
    return HttpClient.get<Response<{ pages: Page[] }>>(url);
  },
  saveTemplate: (form: any) =>
    HttpClient.post<Response<{ id: string }>>(
      config.services.salesEnablement.templatesUrl,
      { form },
    ),
});
