import { PictureOutlined } from "@ant-design/icons";
import styles from "./Preview.module.scss";
import IDMLPreview from "./preview/IDMLPreview";
import { useSalesEnablementContext } from "../hooks/SalesEnablementDataProvider";

const Preview = () => {
  const { renderingPreviewStatus } = useSalesEnablementContext();
  const previewType: "idml" | "html" = "idml";

  return (
    <div className={styles.container}>
      {renderingPreviewStatus === "idle" && <div>Upload file to preview</div>}
      {renderingPreviewStatus === "loading" && (
        <>
          <PictureOutlined className={styles.icon} />
          <div className={styles.title}>Loading preview...</div>
        </>
      )}

      {renderingPreviewStatus == "done" && (
        <div className={styles.preview}>
          {previewType === "idml" && <IDMLPreview />}
        </div>
      )}
    </div>
  );
};

export default Preview;
