import { GroupLayer, Layer } from "shared/types/salesEnablement";
import { OnLayerChangeFunc } from "../Layers";
import styles from "./SmartLayer.module.scss";
import LayerName from "../shared/LayerName";
import {
  hasChildren,
  isConditionalGroupLayer,
  isGroupLayer,
  isOptionGroupLayer,
} from "../utils";
import GroupSelect from "./smartLayer/GroupSelect";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Input, Tooltip } from "antd";
import Conditional from "./smartLayer/Conditional";

type Props = {
  layer: Layer;
  onChange?: OnLayerChangeFunc;
  groupLayers?: GroupLayer[];
};
const SmartLayer = (props: Props) => {
  const { layer, onChange, groupLayers } = props;

  const onOptionChange =
    (at: number) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      onChange?.(layer, {
        type: "group_change_option",
        data: { value, at },
      });
    };
  return (
    <div className={styles.container}>
      <LayerName layer={layer} onChange={onChange} />

      {isGroupLayer(layer) && (
        <GroupSelect
          layer={layer}
          groupLayers={groupLayers}
          onChange={onChange}
        />
      )}

      {hasChildren(layer) && (
        <div className={styles.children}>
          {layer.subLayers?.map((subLayer: Layer) => (
            <LayerName
              key={`sublayer-${subLayer.name}`}
              isChildLayer={true}
              layer={subLayer}
              onChange={onChange}
            />
          ))}
        </div>
      )}

      {isOptionGroupLayer(layer) && (
        <div className={styles.options}>
          <div className={styles.title}>
            <span>Options to show the agent</span>
            <Tooltip
              mouseEnterDelay={0.5}
              title="Text added between {{}} will be editable by the agent"
            >
              <ExclamationCircleOutlined />
            </Tooltip>
          </div>
          <div className={styles.optionList}>
            {layer.options?.map((opt, idx) => (
              <div key={`option-${opt.id}`} className={styles.option}>
                <Input
                  value={opt.value}
                  autoFocus
                  onChange={onOptionChange(idx)}
                />
                <div>
                  {idx > 0 && (
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() =>
                        onChange?.(layer, {
                          type: "group_remove_option",
                          data: { at: idx },
                        })
                      }
                    />
                  )}
                </div>
              </div>
            ))}
          </div>

          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => onChange?.(layer, { type: "group_add_option" })}
          >
            Add option
          </Button>
        </div>
      )}

      {isConditionalGroupLayer(layer) && (
        <Conditional
          layer={layer}
          onComplete={conditionalSet => {
            onChange?.(layer, {
              type: "add_conditional_set",
              data: conditionalSet,
            });
          }}
        />
      )}
    </div>
  );
};

export default SmartLayer;
