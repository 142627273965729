import { keys } from "lodash";
import { MAX_VIDEO_DURATION } from "shared/constants/assetExporter";
import { getVideoDuration } from "utils/media/utils.input";
import { TValueMapping, TValueMappings, TVariable } from "../shared/types";
import { getValue } from "./dynamicText/utils.fabric";

export const getValueMappingKey = (variable: TVariable) => {
  const { startIdx, lineIdx } = variable;

  return `${variable.id}_${variable.variable}_${lineIdx}_${startIdx}`;
};

export const parseMappingKey = (mappingKey: string) => {
  const [variableId, variable, lineIdx, startIdx] = mappingKey.split("_");

  return {
    variableId,
    variable,
    lineIdx,
    startIdx,
  };
};
export const getMappingsById = (id: string, valueMappings: TValueMappings) => {
  return keys(valueMappings)
    .filter(key => {
      const { variableId } = parseMappingKey(key);
      return variableId === id;
    })
    .map(key => valueMappings[key]);
};

export const getStyleOffset = (prevMappings: TValueMapping[], row: any) => {
  return prevMappings.reduce((acc, mapping) => {
    const value = getValue(mapping, row);
    const { variable } = mapping;
    const { raw } = variable;

    return acc + (value ? value.length - raw.length : 0);
  }, 0);
};

export const getVideoDurationForAdEngine = async (url: string) => {
  const videoDuration = (await getVideoDuration(url, "mp4")) ?? 0;
  return Math.min(MAX_VIDEO_DURATION, videoDuration);
};
