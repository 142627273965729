import {
  ImageObjectParams,
  TextObjectParams,
  createImageObject,
  createTextObject,
  SVGObjectParams,
  createSVGObject,
} from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContext/helpers.store";
import { StoreType } from "screens/designStudio/designStudioV2/types";

export const useElements = (store: StoreType) => {
  return {
    addImage: (params: ImageObjectParams) => createImageObject(store, params),
    addText: (params: TextObjectParams) => createTextObject(store, params),
    addSVG: (params: SVGObjectParams) => createSVGObject(store, params),
  };
};
