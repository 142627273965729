import { useQuery } from "react-query";
import { POLOTNO_API_KEY } from "screens/designStudio/designStudioV2/constants";
import {
  PolotnoJSON,
  StoreType,
} from "screens/designStudio/designStudioV2/types";

const templatesUrl = `https://api.polotno.dev/api/get-text-templates?${POLOTNO_API_KEY}`;

type TextTemplatesResponse = {
  hits: number;
  items: TextTemplateResource[];
};

type TextTemplateResource = {
  json: string;
  preview: string;
};

const getTextTemplates = async () => {
  const repsonse = await fetch(templatesUrl);
  return repsonse.json();
};

const getTextTemplateJson = async (url: string): Promise<PolotnoJSON> => {
  const response = await fetch(url);
  return response.json();
};

export const useTextTemplates = (store: StoreType) => {
  const query = useQuery<TextTemplatesResponse>(
    ["design-studio", "text-templates"],
    getTextTemplates,
  );

  const insertTemplate = async (url: string) => {
    const json = await getTextTemplateJson(url);
    json?.pages?.[0]?.children.forEach(layer => {
      store?.activePage.addElement(layer);
    });
  };

  return { ...query, insertTemplate };
};
