import { RcFile } from "antd/lib/upload";
import API from "services";

export const processFileUpload = async (file: RcFile) => {
  const {
    result: { url, bucket, key },
  } = await API.services.salesEnablement.getPresignedUrl(file);
  const res = await API.services.salesEnablement.upload(file, url);
  if (!res.ok) throw new Error("Failed to upload file");

  const { result } = await API.services.salesEnablement.parseIdml(bucket, key);
  const { pages } = result;
  return pages;
};
