import styles from "./Properties.module.scss";
import { Checkbox, Form, Input, Radio, Select } from "antd";
import { fields } from "./Properties.fields";
import UploadDragger from "./properties/UploadDragger";
import { useSalesEnablementContext } from "screens/designStudio/hooks/SalesEnablementDataProvider";
import { toUploadFile } from "screens/designStudio/utils";

const Properties = () => {
  const { uploadForm, selectedFile, setSelectedFile, selectedTemplate } =
    useSalesEnablementContext();

  return (
    <div className={styles.container}>
      <div className={styles.uploadDraggerContainer}>
        <UploadDragger
          fileList={selectedFile ? [toUploadFile(selectedFile)!] : []}
          isEditing={!!selectedTemplate}
          selectedFile={selectedFile}
          setSelectedFile={file => {
            if (!file) return;

            setSelectedFile?.(file);
          }}
        />
      </div>

      <div className={styles.formContainer}>
        <Form form={uploadForm} layout="vertical">
          {fields.map(field => (
            <div key={field.key}>
              {field.key === "name" && (
                <Form.Item
                  labelAlign="left"
                  label={field.label}
                  name={field.key}
                  rules={[
                    {
                      required: field.required,
                      message: `${field.label} is required.`,
                    },
                  ]}
                >
                  <Input
                    onChange={e =>
                      uploadForm?.setFieldsValue({ name: e.target.value })
                    }
                  />
                </Form.Item>
              )}
              {field.key === "deliveryMethod" && (
                <Form.Item
                  labelAlign="left"
                  label={field.label}
                  name={field.key}
                  rules={[
                    {
                      required: field.required,
                      message: `${field.label} is required.`,
                    },
                  ]}
                >
                  <Checkbox.Group
                    onChange={value => {
                      uploadForm?.setFieldsValue({ deliveryMethod: value });
                    }}
                    options={field.options}
                  />
                </Form.Item>
              )}
              {field.key === "language" && (
                <Form.Item
                  label={field.label}
                  name={field.key}
                  rules={[
                    {
                      required: field.required,
                      message: `${field.label} is required.`,
                    },
                  ]}
                >
                  <Radio.Group
                    defaultValue={"en"}
                    onChange={e =>
                      uploadForm?.setFieldsValue({ language: e.target.value })
                    }
                    options={field.options}
                  ></Radio.Group>
                </Form.Item>
              )}
              {!["name", "language", "deliveryMethod"].includes(field.key) && (
                <Form.Item
                  requiredMark={field.required ? true : "optional"}
                  label={field.label}
                  name={field.key}
                  rules={[
                    {
                      required: field.required,
                      message: `${field.label} is required.`,
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    onChange={value => {
                      uploadForm?.setFieldsValue({ [field.key]: value });
                    }}
                    options={field.options}
                  />
                </Form.Item>
              )}
            </div>
          ))}
        </Form>
      </div>
    </div>
  );
};

export default Properties;
